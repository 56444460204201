.downloads-history {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 20px;

    @media (--from-tablet-landscape) {
      padding-top: 64px;
    }
  }

  &__search-mobile {
    display: block;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__search-desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
      margin-right: 32px;
      width: 340px;
    }
  }
}
