.paging {
  &__container {
    display: flex;
    width: 100%;

    &--left {
      align-self: flex-start;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    &--right {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      @media (--from-tablet-landscape) {
        margin-left: unset;
      }
    }
  }

  &__dropdown {
    --dropdown-text-color: var(--color-gin) !important;
    --dropdown-text-color-hover: var(--color-gin) !important;
    --dropdown-text-font: 1.2rem/1.6rem var(--font-gerstner);

    &--container {
      width: 100%;
    }

    &--button {
      color: var(--color-gin);
    }

    &--limit {
      --dropdown-text-color: var(--color-gray-200) !important;
      margin-left: 8px;
    }

    &-option {
      min-width: unset !important;
      padding-right: 16px;
      height: 24px;
      min-height: 24px;

      * {
        font-size: 12px;
      }
    }
  }

  &__prev-btn {
    margin-right: 1rem;
  }

  &__next-btn {
    margin-left: 1rem;
  }

  &__arrow-btn {
    font-size: 0;

    > svg {
      height: 24px;
      transition: fill .2s;
      width: 24px;

      > path {
        transition: stroke .2s;
        stroke: var(--color-main-dynamic);
      }
    }

    &[disabled] {
      opacity: .25;
    }

    &:not([disabled]) {
      cursor: pointer;

      @media (hover: hover) {
        &:hover > svg > path {
          stroke: var(--color-hover-dynamic);
        }
      }
    }
  }
}
