.card {
  /* NOTE: This is intended to be used in the <Card/> description to have the artists in one line */
  &__artist-description--single-line {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__second-line-description-markup {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__third-line-description-markup {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__darker-padded-description {
    color: var(--color-gray-200);

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }
}
