.artist-tooltip-reset-padding {
  padding: 0 !important;

  &--hidden {
    visibility: hidden !important;
  }
}

.artist-tooltip-container {
  padding: 16px !important;
  width: 174px;
  min-height: 260px;
}

.artist-tooltip {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  &-ellipsis {
    display: flex;
    justify-content: space-around;

    &-dot {
      width: 3px;
      height: 3px;
      margin: 0 1px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
      transition: background-color .5s;

      &:nth-child(1) {
        animation: movedot 1.5s infinite;
      }

      &:nth-child(2) {
        animation: movedot 1.5s infinite .5s;
      }

      &:nth-child(3) {
        animation: movedot 1.5s infinite 1s;
      }
    }
  }

  &__image-container {
    height: 120px;
    width: 120px;
    display: flex;
    align-self: center;
  }

  &__image-initials {
    align-items: center;
    background-color: var(--color-tundora);
    color: var(--color-gray-200);
    display: flex;
    height: 100%;
    justify-content: center;
    font: 500 4.4rem/4.4rem var(--font-gerstner);
  }

  &__image {
    border-radius: 100%;
    height: 120px;
    width: 120px;
    overflow: hidden;

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__text-container {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  &__button-container {
    width: 100%;
  }

  &__name {
    font: 500 1.6rem/2.4px var(--font-gerstner);
    color: var(--color-gin);
    cursor: pointer;
    text-align: center;
    height: 24px;
    width: 125px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &__tracks-count {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gray-200);
    text-align: center;
    cursor: default;
  }
}

@keyframes movedot {
  0%,
  100% { background-color: var(--color-gray-200); }
  50% { background-color: white; }
}
