.showing-filter {
  &__switches-column-container {
    display: flex;
    flex-direction: row;
    width: 492px;
    gap: 48px;
    max-width: 100%;
  }

  &__switches-row-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__switch-column {
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  &__switch {
    display: flex;
  }
}
