.modal-slider {
  &__content {
    background: var(--color-cod-gray-100);
    max-width: 100%;
    width: calc(100% - 32px);

    @media (--from-tablet) {
      max-width: 448px;
      min-height: 475px;
    }
  }

  &__form {
    margin: 5px 0;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__close-icon {
    display: flex;

    > svg > path {
      stroke: var(--color-gin);
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__pagination {
    height: 44px;
  }

  &__pagination-item {
    height: 100%;
    margin: 0 4px;
    position: relative;
    width: 24px;

    &::after {
      background: var(--color-dove-gray);
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: background .2s;
      width: 100%;
    }

    &:global(.swiper-pagination-bullet-active)::after {
      background: var(--color-main-dynamic);
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__body-title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .15px;
    margin: 10px 0 16px;
  }

  &__body-text {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gray-200);
    letter-spacing: .25px;
    margin-bottom: 20px;
  }

  &__dimiss {
    margin-right: 16px;
    cursor: pointer;
  }

  &__learn-more {
    height: 40px;
    margin-bottom: 14px;
    cursor: pointer;

    &--inactive {
      opacity: .6;
    }
  }

  &__arrow-btn > svg {
    stroke: var(--color-gin);
  }
}
