.versions-download {
  display: flex;
  align-items: center;
  gap: 8px;

  &__upgrade-to-unlock {
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1rem/1.6rem var(--font-gerstner);
    height: 24px;
    justify-content: center;
    letter-spacing: .75px;
    transition: all .2s;
    padding: 10px;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-gin);
        color: var(--color-black);
        border: 1px solid var(--color-gin);
      }
    }
  }
}
