.ghost-component {
  background-color: var(--color-mine-shaft);
  display: flex;

  &__custom {
    height: 20px;
    background-color: var(--color-cod-gray-500);
    width: 100%;
  }

  &__primary-title {
    height: 42px;
    background-color: var(--color-cod-gray-500);
    width: 200px;
    margin-bottom: -16px;
  }

  &__secondary-title {
    height: 32px;
    background-color: var(--color-cod-gray-500);
    width: 200px;
    margin-bottom: -16px;
  }

  &__banner {
    min-width: 325px;
    height: 141px;
    background-color: var(--color-cod-gray-500);

    @media (--from-tablet) {
      height: 275px;
      min-width: 642px;
    }
  }

  &__artist-detail {
    display: flex;
    margin: var(--window-padding);

    &--image-container {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 192px;
        height: 192px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      height: 96px;
      padding-left: 32px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (--from-tablet-landscape) {
        height: 192px;
      }
    }

    &--title {
      height: 60px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 0;

      @media (--from-tablet-landscape) {
        margin-bottom: 32px;
      }
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
      }
    }
  }

  &__playlist-detail {
    display: flex;
    margin: var(--window-padding) var(--window-padding) var(--left-padding);

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 136px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 32px;
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__my-drive-detail {
    display: flex;
    margin: var(--window-padding) var(--window-padding) var(--left-padding);

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 136px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 32px;
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__curated-set-detail {
    display: flex;
    margin: var(--window-padding) var(--window-padding) var(--left-padding);

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 136px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 32px;
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__album-detail {
    display: flex;
    margin: var(--window-padding) var(--window-padding) var(--top-padding);

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 136px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--mobile-text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 100px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__for-you-detail {
    display: flex;
    margin: 1.6rem;

    &--image-container {
      width: 136px;
      height: 85px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 185px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 85px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 185px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--mobile-text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 85px;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 185px;
        margin-left: 32px;
        padding-top: 8px;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 var(--left-padding);

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__pack-detail {
    display: flex;
    margin: 0;
    margin-bottom: 10px;

    @media (--from-tablet-landscape) {
      margin-bottom: 55px;
    }

    &--image-container {
      width: 136px;
      height: 136px;
      min-width: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        min-width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-top: var(--top-padding);
      flex: 1;

      @media (--from-tablet-landscape) {
        height: 296px;
        margin-left: 32px;
        margin-top: 0;
      }
    }

    &--info-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (--from-tablet-landscape) {
        width: 592px;
        max-width: 592px;
      }
    }

    &--title {
      height: 38px;
      width: 50%;
      min-width: 320px;
      background-color: var(--color-cod-gray-500);

      @media (--from-tablet-landscape) {
        width: 400px;
        margin-top: 4px;
        height: 40px;
      }
    }

    &--info {
      height: 24px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin: 8px 0;

      @media (--from-tablet-landscape) {
        height: 32px;
      }
    }

    &--artists {
      height: 18px;
      width: 250px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 16px;
    }

    &--details {
      height: 50px;
      width: 100%;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 44px;

      @media (--from-tablet-landscape) {
        max-width: 592px;
      }
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0;

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__curated-pack-detail {
    display: flex;
    margin-bottom: 32px;

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 136px;
      height: 136px;
      margin-top: var(--top-padding);

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
        margin-left: 32px;
        margin-top: 0;
      }
    }

    &--title {
      height: 40px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 8px;
    }

    &--info {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 9px;
    }

    &--artists {
      height: 20px;
      width: 300px;
      background-color: var(--color-cod-gray-500);
      margin-bottom: 32px;
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0;

      @media (--from-tablet-landscape) {
        margin: 0;
        width: 300px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__label-detail {
    display: flex;
    margin: var(--window-padding);

    &--image-container {
      width: 136px;
      height: 136px;
      background-color: var(--color-mine-shaft);
      border-radius: 50%;

      @media (--from-tablet-landscape) {
        width: 296px;
        height: 296px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--color-cod-gray-500);
    }

    &--text-container {
      display: flex;
      padding-top: 0;
      align-items: flex-start;
      flex-direction: column;
      margin-top: var(--top-padding);

      @media (--from-tablet-landscape) {
        height: 296px;
        margin-left: 32px;
        margin-top: 0;
      }
    }

    &--label-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }

    &--packs {
      height: 20px;
      width: 100px;
      background-color: var(--color-cod-gray-500);
      margin-top: 4px;
    }

    &--title {
      height: 25px;
      width: 300px;
      background-color: var(--color-cod-gray-500);

      @media (--from-tablet-landscape) {
        height: 30px;
      }
    }

    &--info {
      height: 43px;
      width: 100%;
      background-color: var(--color-cod-gray-500);

      @media (--from-tablet-landscape) {
        max-width: 592px;
      }
    }

    &--description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 16px;

      @media (--from-tablet-landscape) {
        margin: 0;
        margin-top: 16px;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }

  &__title {
    margin: 32px 0;
  }

  &__track-list {
    &--top {
      margin-top: 5px;
    }

    &--tabs {
      height: 49px;
      background-color: var(--color-cod-gray-400);
      width: 100%;
      margin-bottom: 16px;

      @media (--from-tablet-landscape) {
        margin-bottom: 0;
      }
    }
  }

  &__list-ghost-item-wrapper {
    display: inline-block;
    margin: 16px 16px 0 0 !important;
    width: 100px;
    height: 32px;
    background-color: var(--color-mine-shaft);

    @media (--from-tablet-landscape) {
      width: 114.6px;
    }
  }

  &__list-ghost-item {
    background-color: var(--color-cod-gray-500);
    display: flex;
    width: 100%;
  }
}
