.horizontal-tabs {
  &__tabs-container {
    max-width: 100%;
    position: relative;

    &--divider {
      border-bottom: 1px solid var(--color-cod-gray-400);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  &__tab-wrapper {
    display: inline-block;
    width: auto;
  }

  &__tab {
    border-bottom: 1px solid transparent;
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    margin-right: 25px;
    padding: 15px 0 17px;
    transition: border .25s, color .25s;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }

    >span {
      font: 500 .8rem/1rem var(--font-gerstner);
    }
  }
}
