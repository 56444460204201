.player-queue {
  &__edit-queue {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #e6efe90a;
    padding-right: 80px;

    @media (--from-tablet) {
      padding: 12px 24px;
    }

    @media (--from-tablet-landscape) {
      padding: 12px 32px;
    }
  }

  &__selected-label {
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .025rem;
    color: var(--color-gray-200);
    white-space: nowrap;
  }

  &__edit-actions {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;

    @media (--from-tablet) {
      flex-direction: row;
      gap: 32px;
      align-items: center;
    }
  }

  &__action-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__toggle-edit-button {
    display: flex;
    align-items: center;
    gap: 4px;
    font: 500 1rem/1.6rem var(--font-gerstner);
    text-transform: uppercase;
    letter-spacing: .075rem;
    color: var(--color-gray-200);

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: var(--color-gin);

      path {
        fill: var(--color-gin);
      }
    }
  }

  &__button {
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .025rem;
    color: var(--color-gin);
    white-space: nowrap;

    &:disabled {
      color: var(--color-tundora);
    }

    &:not([disabled]):hover {
      text-decoration: underline;
    }
  }

  &__cancel-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    @media (--from-tablet) {
      position: static;
      top: unset;
      right: unset;
      transform: translateY(0);
    }

    &:hover {
      path {
        stroke: var(--color-gin);
      }
    }
  }
}
