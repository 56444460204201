.mini-player {
  position: fixed;
  bottom: 56px;
  left: 0;
  z-index: var(--mini-player-z-index);
  padding: 14px 16px;
  height: var(--mini-player-height);
  width: 100%;
  display: flex;
  justify-content: center;
  touch-action: none;
  background-color: var(--color-cod-gray-500);

  @media (--from-tablet) {
    padding: 8px;
  }

  @media (--from-tablet-landscape) {
    bottom: 0;
  }

  @media (--from-desktop) {
    border-top: 1px solid var(--color-cod-gray-400);
  }

  @media (--from-large-desktop) {
    padding: 12px;
  }

  &__container {
    width: 100%;
    align-items: center;
    display: grid;
    column-gap: 20px;
    grid-template-columns: [track] minmax(200px, auto)  [controls] 120px;
    justify-content: space-between;

    @media (--from-tablet) {
      justify-content: space-between;
      column-gap: 40px;
      grid-template-columns: [track] minmax(auto, 384px) [controls] minmax(120px, 1fr) [actions] minmax(64px, 1fr);
    }

    @media (--from-tablet-landscape) {
      justify-content: start;
      column-gap: 80px;
      grid-template-columns: [track] minmax(auto, 442px) [controls] 1fr [actions] minmax(120px, 1fr);
    }

    @media (--from-desktop) {
      grid-template-columns: [track] minmax(auto, 546px) [controls] minmax(600px, auto) [actions] minmax(120px, 1fr);
    }

    @media (--from-large-desktop) {
      grid-template-columns: [track] minmax(auto, 646px) [controls] minmax(944px, auto) [actions] minmax(120px, 1fr);
    }
  }

  &__right-section {
    align-items: center;
    display: none;

    @media (--from-tablet) {
      display: flex;
    }
  }

  &__track-details {
    align-items: center;
    display: flex;
    width: 200px;

    @media (--from-tablet) {
      width: 384px;
    }

    @media (--from-tablet-landscape) {
      width: 442px;
    }

    @media (--from-desktop) {
      width: auto;
      max-width: 546px;
    }

    @media (--from-large-desktop) {
      width: 646px;
    }
  }

  &__details-cover {
    align-items: center;
    display: none;
    justify-content: center;
    position: relative;
    object-fit: cover;

    @media (--from-tablet) {
      display: flex;
      height: 56px;
      width: 56px;
      margin-right: 12px;
    }

    @media (--from-desktop) {
      height: 64px;
      width: 64px;
      margin-right: 16px;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__details-title-wrapper {
    font-family: var(--font-gerstner);
    overflow: hidden;

    @media (--from-tablet) {
      max-width: 230px;
    }

    @media (--from-tablet-landscape) {
      max-width: 384px;
    }

    @media (--from-desktop) {
      max-width: 280px;
    }

    @media (--from-large-desktop) {
      max-width: 484px;
    }
  }

  &__details-artist {
    display: block;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-gray-200);

    @media (--tablet) {
      pointer-events: none;
    }

    a,
    span {
      color: var(--color-gray-200) !important;
    }
  }

  &__version-dropdown {
    display: none;

    @media (--from-tablet) {
      display: block;
      padding-left: 20px;
    }
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    align-items: center;
    gap: 12px;

    @media (--from-tablet) {
      justify-content: center;
    }
  }

  &__controls-buttons {
    display: flex;
    align-items: center;
  }

  &__control {
    width: 40px;
    height: 40px;

    path {
      transition: fill .25s;
      fill: var(--color-gray-200);
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &--next,
    &--prev {
      padding: 4px;

      svg {
        height: 24px;
        width: 24px;

        @media (--from-tablet) {
          height: 32px;
          width: 32px;
        }
      }
    }

    &--next-artist,
    &--next,
    &--prev {
      &:disabled {
        opacity: .3;
      }
    }

    &--play,
    &--pause {
      svg {
        height: 32px;
        width: 32px;

        @media (--from-tablet) {
          height: 40px;
          width: 40px;
        }
      }

      path {
        fill: var(--color-gin);
      }
    }
  }

  &__seek-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media (--from-desktop) {
      display: none;
    }
  }

  &__actions {
    display: none;
    padding-right: 16px;

    @media (--from-tablet) {
      align-items: center;
      display: flex;
      justify-self: end;
      gap: 16px;
    }
  }

  &__action {
    align-items: center;
    display: flex;

    svg {
      width: 24px;
      height: 24px;
    }

    path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    &--maxi-player {
      @media (hover: hover) {
        &:not([disabled]):hover path {
          fill: var(--color-gin);
        }
      }
    }
  }

  &__menu-wrapper {
    position: relative;
    overflow: hidden;
    display: none;

    & > div {
      width: 24px;
      height: 24px;

      svg {
        scale: 1.35;
      }
    }

    @media (--from-tablet-landscape) {
      display: block;
    }
  }
}
