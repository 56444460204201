.nav-my-playlists-category-item {
  align-items: center;
  color: var(--color-boulder);
  display: flex;
  font: 1.4rem var(--font-gerstner);
  letter-spacing: .0094em;
  padding: 12px 12px 12px 20px;
  text-decoration: none;
  transition: background .25s, color .25s;
  width: 100%;

  &__contents {
    background-color: var(--color-cod-gray-500);
  }

  svg {
    * {
      transition: fill .25s;
    }
  }

  &--is-dragging {
    @media (hover: hover) {
      &:hover {
        background-color: transparent !important;
        color: var(--color-gray) !important;
      }
    }
  }

  &--is-dragging--over {
    background-color: var(--color-cod-gray-500);
    color: var(--color-gin);

    svg {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      &:not(.nav-my-playlists-category-item--active):not(.nav-my-playlists-category-item--is-dragging):not(:active) {
        background-color: var(--color-cod-gray-500);
        color: var(--color-boulder);

        .nav-my-playlists-category-item--three-dots {
          opacity: 1 !important;
        }

        .nav-my-playlists-category-item--chevron {
          opacity: 1 !important;
        }
      }
    }
  }

  &:active:not(&--active) {
    background-color: var(--color-cod-gray-300);
    color: var(--color-gin);

    svg:not(.nav-my-playlists-category-item--chevron) {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }

    svg.nav-my-playlists-category-item--chevron {
      stroke: var(--color-gin);
    }
  }

  &--active-open {
    background-color: var(--color-cod-gray-500) !important;
    color: var(--color-gin) !important;
  }

  &--active {
    background: var(--color-cod-gray-500);
    color: var(--color-gin);

    svg:not(.nav-my-playlists-category-item--chevron) {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }
  }

  &--chevron {
    opacity: 1;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
    margin-top: 4px;

    &--mobile {
      transform: rotate(0);
    }

    @media (--from-tablet-landscape) {
      opacity: 0;
    }

    &--open {
      transform: rotate(90deg);
      opacity: 1;
      stroke: var(--color-gin);
    }

    @media (hover: hover) {
      &:hover {
        stroke: var(--color-gin);
        opacity: 1;
      }
    }
  }

  &--three-dots {
    opacity: 1;
    transition: all .2s ease-in-out;

    &--open {
      opacity: 1;
    }

    @media (--from-tablet-landscape) {
      opacity: 0;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }

  @media (--from-tablet-landscape) {
    height: var(--menu-item-height);
    padding: 12px 18px 12px 0;
  }

  > span {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__button {
    height: 24px;
    margin-inline-end: var(--half-padding);
    text-align: center;
    transition: background .25s;
    justify-content: center;
    display: flex;

    @media (--from-tablet-landscape) {
      flex: 0;
      margin: 0 8px 0 40px;
    }
  }
}

.drop-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 0;
  margin-inline: 42px;
  overflow: hidden;
  transition: .2s all ease-out;
  opacity: 0;

  &--is-visible {
    height: 24px;
    opacity: .1;

    &.drop-line--is-over {
      opacity: 1;
    }
  }

  &--thin {
    height: 0;

    &.drop-line--is-visible {
      height: 2px;
      opacity: 1;
    }
  }

  &--indented {
    margin-inline-start: 62px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid var(--color-main-dynamic);
  }
}
