.application-inputfield {
  --border-color: var(--color-tundora);
  border: .1rem solid var(--border-color);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  transition: border .2s;

  &--has-errors {
    --border-color: var(--color-burnt-sienna);
    transition: border 1s;
    margin-bottom: 0;
  }

  :focus:not(.focus-visible) {
    outline: none;
  }

  @media (hover: hover) {
    &:hover:not([aria-disabled='true']) {
      --border-color: var(--color-gin);
    }
  }

  &--left-icon {
    align-self: center;
    margin-left: 1rem;
  }

  &--right-icon {
    align-self: center;
    margin-right: 1rem;
  }

  &--error-text {
    font: 300 1.2rem var(--font-gerstner);
    color: var(--color-burnt-sienna);
    margin-bottom: 1rem;
  }

  &--text {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    background-color: transparent;
    border: none;
    padding: .8rem;
    height: 100%;
    min-height: 4.2rem;
    width: 100%;
    outline-width: 0;
  }

  &--dropdown-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    min-height: 4.2rem;
  }

  &--dropdown {
    justify-content: space-between;
    flex: 1;

    >button {
      color: var(--color-gin);
      width: 100%;
      text-align: left;
    }

    &--placeholder {
      >button {
        font: 500 1.4rem var(--font-gerstner);
        color: var(--color-boulder);
      }
    }
  }

  &--dropdown-options {
    width: 100%;
    box-shadow: 0 1px 1px 2px var(--color-cod-gray-400);
  }

  &--multiline {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    background-color: transparent;
    border: none;
    padding: .8rem;
    min-height: 12rem;
    width: 100%;
    outline-width: 0;
  }
}
