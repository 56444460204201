.profile-menu {
  &__mobile-drawer {
    height: 480px;
    padding-bottom: 0;
  }

  &__desktop-menu {
    position: fixed;
    left: auto;
    right: 0;
    top: var(--header-height);
    z-index: var(--user-menu-z-index);
    background-color: var(--color-cod-gray-500);
    display: none;
    box-shadow: 0 0 8px 0 #0000003d, 0 8px 24px 0 #0000003d;
    width: 320px;

    &--active {
      display: flex;
    }
  }

  &__container {
    padding: 24px 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    border-bottom: 1px solid #2a2a2a;

    @media (--from-tablet-landscape) {
      border: none;
      padding: 24px 16px 16px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  &__user-infos {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__user-avatar {
    border-radius: 100%;
    height: 48px;
    width: 48px;
    overflow: hidden;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      color: #919191;
    }
  }

  &__user-name {
    color: var(--color-gin);
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .5px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 224px;
  }

  &__user-email {
    font: 400 1.2rem/1.8rem var(--font-gerstner);
    color: var(--color-gray-200);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 224px;
  }

  &__account-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gin);
    background-color: var(--color-cod-gray-400);
    padding: 10px 12px;
    width: 100%;
    transition: background-color .2s ease-out;

    &:hover {
      background-color: var(--color-tundora);
    }
  }

  &__link {
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    color: var(--color-gray-200);
    padding: 14px 0;
    transition: color .2s ease-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-dodger-blue);
    }

    &:hover {
      color: var(--color-gin);
    }

    &--logout {
      color: var(--color-main-dynamic);
    }

    @media (--from-tablet-landscape) {
      padding: 8px 0;
    }
  }

  &__close-button {
    width: 100%;
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-main-dynamic);
    padding: 16px;
    text-align: center;

    &:hover {
      color: var(--color-gin);
    }
  }
}
