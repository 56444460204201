.cancel-plan {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 32px;

    @media (--from-tablet-landscape) {
      flex-direction: row;
      gap: 32px;
      padding: 64px 0;
    }
  }

  &__cancel-option-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__button {
    width: 100%;

    &--destructive {
      background-color: transparent;
      color: var(--color-burnt-sienna);
    }
  }

  &__back-link {
    color: var(--color-gray-200);
    text-decoration: underline !important;
    transition: color .2s;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: var(--color-gin);
    }
  }
}
