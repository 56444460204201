.pack-card-create {
  &__status-container {
    align-items: center;
    background-color: var(--color-cod-gray-500);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 32px;
    left: 0;
    position: absolute;
    width: 32px;
  }

  &__status-text {
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;

    &--up {
      color: var(--color-fruit-salad);
    }

    &--down {
      color: var(--color-burnt-sienna);
    }
  }
}
