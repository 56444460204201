.timestamp-slider {
  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    cursor: pointer;
    touch-action: none;
  }

  &__inner-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__cursor {
    --timestamp-slider-left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px solid var(--color-white);
    width: 1px;
    left: var(--timestamp-slider-left);
    cursor: pointer;
    z-index: 1;
    opacity: 1;
    transition: opacity .2s ease-in-out;
    font: 1.2rem/1.6rem var(--font-gerstner);
    touch-action: none;

    &--hidden {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }

    &--maxi-mobile,
    &--maxi-tablet {
      height: 72px;
    }

    &--maxi-desktop {
      height: 88px;
    }

    &--mini {
      height: 80px;

      @media (--from-large-desktop) {
        height: 88px;
      }
    }
  }

  &__time {
    position: absolute;
    right: 8px;
    top: 2px;
    color: var(--color-white);
    pointer-events: none;
    touch-action: none;
  }
}
