.promotional-modal {
  color: var(--color-gin);

  &__modal-content {
    background-color: var(--color-cod-gray);
    max-width: 656px;
    width: calc(100vw - 32px);
  }

  &__heading-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    h5 {
      font-weight: 500;
    }

    @media (--from-tablet-landscape) {
      margin-bottom: 16px;
    }
  }

  &__button-icon {
    > svg > path {
      transition: stroke .2s;
    }

    &:hover > svg > path {
      stroke: var(--color-gin);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;

    @media (--from-tablet-landscape) {
      grid-column-gap: 64px;
      grid-template-columns: 1fr 282px;
      margin-right: 24px;
    }
  }

  &__text-container {
    > h2 {
      margin-bottom: 24px;

      @media (--from-tablet-landscape) {
        margin-bottom: 8px;
      }
    }

    > p {
      font: 300 1.3rem/2rem var(--font-gerstner);
      letter-spacing: .35px;

      @media (--from-tablet-landscape) {
        font-size: 1.4rem;
        letter-spacing: .25px;
      }
    }
  }

  &__img-container {
    margin: 24px 0;

    @media (--from-tablet-landscape) {
      margin: 10px 0 0;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 355px;
      justify-content: space-between;
      margin-top: 48px;
    }
  }

  &__cta {
    display: inline-block;
    max-width: 400px;

    @media (--from-tablet-landscape) {
      padding: 8px 24px;
    }
  }

  &__rich-text-content {
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    margin-top: 1rem;

    @media (--from-tablet-landscape) {
      text-align: right;
      margin-top: 0;
    }

    * {
      color: inherit;
      font: inherit;
    }

    a {
      text-decoration: underline;
    }
  }
}
