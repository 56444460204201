.trending-count-column {
  font: 500 1.2rem/1.6rem var(--font-gerstner);
  height: 40px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  &--rising {
    color: var(--color-fruit-salad);

    &::after {
      border-bottom: 5px solid var(--color-fruit-salad);
    }
  }

  &--dropping {
    color: var(--color-burnt-sienna);

    &::after {
      border-top: 5px solid var(--color-burnt-sienna);
    }
  }

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }
}
