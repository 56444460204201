.header-account {
  display: none;
  font-size: 1.4rem;

  @media (--from-tablet-landscape) {
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: .8rem;
    margin-left: -54px;
    color: var(--color-gin);
    font-weight: 500;
  }

  &__logo {
    width: 3.4rem;
    height: 3.4rem;
  }

  &__page-title {
    display: flex;
    align-items: center;
    color: #919191;

    &::before {
      content: '';
      display: block;
      height: 1.4em;
      width: 1px;
      margin-inline: .8rem;
      background-color: #2a2a2a;
    }
  }
}
