.account-settings {
  &__header {
    display: flex;
    justify-content: flex-end;

    @media (--from-tablet-landscape) {
      justify-content: space-between;
    }

    h2 {
      @media (--tablet) {
        display: none;
      }
      color: var(--color-gin);
    }
  }

  &__header-switch {
    align-items: center;
    display: flex;

    span {
      color: var(--color-gin);
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .4px;
      margin-right: 8px;
    }
  }

  &__content {
    > button {
      width: 100%;

      @media (--from-tablet) {
        width: auto;
      }
    }
  }

  &__form-container {
    margin: 32px 0;

    h4 {
      color: var(--color-gin);
      margin-bottom: 24px;
    }
  }

  &__row {
    color: var(--color-gin);
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__title {
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__sub-title {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }
}
