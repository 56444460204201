.nav {
  --menu-item-height: 36px;
  --header-container-mobile-height: 56px;
  background: var(--color-cod-gray-700);
  bottom: 58px;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  transition: transform .2s ease-in;
  width: 100%;
  z-index: var(--nav-mobile-z-index);

  @media (--mobile) {
    --menu-item-height: 44px;
    top: var(--header-height);
  }

  @media (--tablet) {
    top: var(--header-height);
  }

  @media (--from-tablet-landscape) {
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(0);
    transition: width .2s, bottom .2s ease;
    width: var(--nav-width);
    z-index: var(--nav-z-index);

    :global(body.player-is-open) & {
      bottom: var(--mini-player-height);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      border-right: 1px solid var(--color-cod-gray-500);
    }

    &__create,
    &__ap {
      &::after {
        top: 0;
        height: 100%;
      }
    }

    [data-context-open='true']& {
      overflow: visible;
    }
  }

  &__section-items--divider {
    padding-bottom: 32px;
  }

  &__create {
    &__sort-context-menu {
      @media (--mobile) {
        top: 48px !important;
      }
    }
  }

  &__dropdown-container {
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &--open {
      opacity: 1;
    }
  }

  &__section {
    margin-top: var(--top-padding);

    &--title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: opacity .2s ease-in-out;
      font: 500 1.2rem var(--font-gerstner);
      line-height: var(--top-padding);
      color: #4a4a4a;
      letter-spacing: .4px;
      margin-left: 20px;
      height: calc(var(--top-padding) * 2);
      opacity: 1;

      &--closed {
        opacity: 0;
      }
    }
  }

  &--fullscreen-mobile {
    bottom: 0;
    max-height: 100%;
  }

  :global(.isResizing) & {
    transition: none !important;
  }

  &--open {
    transform: translateX(0);
    transition-timing-function: ease-out;

    /* Hide Scroll */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &--open::-webkit-scrollbar {
    display: none;
  }

  &--hidden {
    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__menu {
    margin-bottom: auto;
  }

  &__close-btn {
    padding: 0 14px;
    width: 100%;
  }

  &__menu-item {
    align-items: center;
    color: var(--color-boulder);
    display: flex;
    font: 1.4rem var(--font-gerstner);
    letter-spacing: .0094em;
    padding: var(--top-padding);
    height: var(--menu-item-height);
    text-decoration: none;
    transition: background-color .25s ease-in-out, color .25s ease-in-out, opacity .25s ease-in-out;
    width: 100%;

    &--three-dots {
      opacity: 1;

      &--open {
        opacity: 1;
      }

      @media (--from-tablet-landscape) {
        opacity: 0;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }

    &--chevron {
      opacity: 1;
      transform: rotate(270deg);
      transition: stroke .25s ease-in-out;

      &--mobile {
        transform: rotate(0);
      }

      @media (--from-tablet-landscape) {
        opacity: 0;
      }

      &--open {
        transform: rotate(360deg);
        opacity: 1;
      }

      @media (hover: hover) {
        &:hover {
          stroke: var(--color-gin);
          opacity: 1;
        }
      }
    }

    &--menu-text-collapse-label {
      display: none;
    }

    &--menu-text-collapse-title {
      @media (hover: hover) {
        &:hover .show-hover {
          color: var(--color-gray);
          font-size: 14px;
          display: inline;
        }
      }
    }

    &--is-dragging--over {
      background-color: transparent !important;
      color: var(--color-gin) !important;

      svg:not(.nav__menu-item--chevron) {
        >path {
          fill: var(--color-gin);
        }

        >g path {
          fill: var(--color-gin);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        &:not(.nav__menu-item--active):not(.nav__menu-item--is-dragging):not(:active):not([disabled]) {
          background-color: var(--color-cod-gray-400);
          color: var(--color-boulder);
        }

        .nav__submenu-btn--context-toggle {
          opacity: 1;
        }

        .nav__menu-item--three-dots {
          opacity: 1 !important;
        }

        .nav__menu-item--chevron {
          opacity: 1 !important;
        }
      }
    }

    &:active:not(&--active):not([disabled]) {
      background-color: var(--color-cod-gray-300);
      color: var(--color-gin);

      svg:not(.nav__menu-item--chevron) {
        >path {
          fill: var(--color-gin);
        }

        >g path {
          fill: var(--color-gin);
        }
      }

      svg.nav__menu-item--chevron,
      svg :global(.use-stroke) {
        stroke: var(--color-gin);
        fill: none !important;
      }
    }

    &[data-focus-visible-added] {
      background-color: var(--color-cod-gray-500);
      outline: 1px solid var(--color-dodger-blue);
    }

    @media (--from-tablet-landscape) {
      min-height: var(--menu-item-height);
      padding: 12px 20px;
    }

    >span {
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &--nav {
      display: none;

      @media (--from-tablet-landscape) {
        border-block-end: 1px solid var(--color-cod-gray-400);
        display: flex;
        padding: 12px 0;
      }
    }

    &--open {
      background-color: var(--color-cod-gray-200);
      color: var(--color-gin);
    }

    &--active {
      background: var(--color-cod-gray-400);
      color: var(--color-gin);
    }

    &--icon {
      display: block;
      height: 24px;
      margin-inline-end: 8px;
      text-align: center;

      svg {
        * {
          transition: fill .25s, stroke .25s;
        }
      }

      &--close {
        margin-left: auto;
        margin-right: 2px;
        padding-top: 6px;
        padding-right: 10px;
        transition: opacity .25s, transform .3s;
        transform: rotate(0);

        &-artist {
          padding-top: 6px;
        }

        @media (--mobile) {
          padding-left: 80px;
        }

        @media (hover: hover) {
          &:hover {
            svg {
              * {
                fill: var(--color-gin);
              }
            }
          }
        }
      }

      &--closed {
        opacity: 0;
        transform: rotate(180deg);
      }
    }
  }

  &__submenu {
    background-color: var(--color-cod-gray-100);
    inset: 0;
    position: absolute;
    z-index: 3;

    @media (--from-tablet-landscape) {
      border-block-end: 1px solid var(--color-cod-gray-400);
      inset: var(--menu-item-height) 0 0 0;
      margin-block-start: 1px;
      min-height: var(--menu-item-height);

      &.nav__submenu--level-3 {
        inset: 0;
      }
    }
  }

  &__dots-sub-menu {
    position: absolute;
    left: 200px;
  }

  &__submenu-header {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-400);
    display: flex;
    padding-inline-end: 16px;
    position: relative;

    @media (--from-tablet-landscape) {
      padding-inline-end: 12px;
      padding-left: 20px;
    }
  }

  &__submenu-btn-container {
    display: flex;
  }

  &__submenu-btn {
    align-items: center;
    color: var(--color-alabaster);
    display: flex;
    font: 1.4rem var(--font-gerstner);
    letter-spacing: .0094em;
    padding: 12px 12px 12px 20px;
    text-decoration: none;
    transition: color .25s;
    width: 100%;

    &:not(&--context-toggle) {
      margin-inline-end: 20px;
    }

    &[data-focus-visible-added] {
      background-color: var(--color-black);
      outline: 1px solid var(--color-dodger-blue);
    }

    @media (--from-tablet-landscape) {
      height: var(--menu-item-height);
      padding: 12px 0;
    }

    &--context-toggle {
      flex: 0 0 24px;
      margin-inline-start: 8px;
      padding: 12px 0;

      @media (--from-tablet-landscape) {
        transform: rotate(90deg);

        @media (hover: hover) {
          opacity: 0;
        }
      }

      &--open {
        opacity: 1;

        svg {
          >path {
            fill: var(--color-gin);
          }

          >g path {
            fill: var(--color-gin);
          }
        }
      }

      svg {
        * {
          transition: fill .25s;
        }
      }
    }

    >span {
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &--icon {
      display: block;
      margin-inline-end: 26px;
      transition: background .25s;

      @media (--from-tablet-landscape) {
        flex: 0 0 var(--menu-item-icon-width);
        margin: 0;
      }
    }
  }

  &__submenu-header-icon {
    flex: 0 0 24px;
    margin-inline-start: 8px;
  }

  &__context-menu {
    background-color: var(--color-cod-gray);
    bottom: 0;
    position: absolute;
    right: 0;
    top: 49px;
    width: 100%;
    z-index: 10;

    @media (--from-tablet-landscape) {
      bottom: auto;
      right: auto;
      width: 240px;
      top: 650px;
      left: 215px;
    }
  }

  &__sort-context-menu {
    background-color: var(--color-cod-gray);
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 10;

    @media (--from-tablet-landscape) {
      bottom: auto;
      width: 240px;
    }

    @media (--mobile) {
      top: calc(var(--header-height) + 48px); /** 48px = nav_nav__submenu-header height **/
    }
  }

  &__context-menu-item {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    font: 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0094em;
    padding: 12px 38px 12px 20px;
    text-decoration: none;
    transition: color .25s;
    width: 100%;

    @media (--from-tablet-landscape) {
      min-height: var(--menu-item-height);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-cod-gray-300);
        color: var(--color-alabaster);
      }
    }

    >i {
      display: block;
      height: 24px;
      margin-inline-end: 20px;
      text-align: center;
      transition: background .25s;

      @media (--from-tablet-landscape) {
        flex: 0 0 var(--menu-item-icon-width);
        margin: 0;
      }
    }

    &--icon {
      @media (--from-tablet-landscape) {
        padding: 12px 38px 12px 0;
      }
    }
  }

  :global {
    .ReactCollapse--collapse {
      transition: height 300ms;
    }
  }
}
