html {
  font-family: var(--font-gerstner);
  font-size: 62.5%;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

h1,
.heading--h1 {
  font: 500 4.8rem/4.8rem var(--font-gerstner);

  @media (--from-tablet-landscape) {
    font: 500 6.4rem/6.4rem var(--font-gerstner);
  }
}

h2,
.heading--h2 {
  font: 500 2.4rem/3.2rem var(--font-gerstner);
  letter-spacing: -.0035em;

  @media (--from-tablet-landscape) {
    font: 500 3.2rem/4rem var(--font-gerstner);
  }
}

h3,
.heading--h3 {
  font: 500 1.8rem/2.4rem var(--font-gerstner);
  letter-spacing: -.0035em;

  @media (--from-tablet-landscape) {
    font: 500 2.4rem/3.2rem var(--font-gerstner);
  }
}

h4,
.heading--h4 {
  font: 500 1.6rem/2.4rem var(--font-gerstner);
  letter-spacing: -.0035em;

  @media (--from-tablet-landscape) {
    font: 500 1.8rem/2.4rem var(--font-gerstner);
  }
}

h5,
.heading--h5 {
  font: 400 1.3rem/2.4rem var(--font-gerstner);
  letter-spacing: .007em;

  @media (--from-tablet-landscape) {
    font-size: 1.4rem;
  }
}

h6,
.heading--h6 {
  font: 400 1.8rem/2.4rem var(--font-gerstner);
  letter-spacing: .0083em;

  @media (--from-tablet-landscape) {
    font: 400 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .0062em;
  }
}

.p,
.paragraph {
  font: 400 2.4rem/3.2rem var(--font-gerstner);
  letter-spacing: .021em;
  margin-bottom: 24px;

  @media (--from-desktop) {
    font-size: 3rem;
    letter-spacing: .017em;
    line-height: 3.8rem;
  }
}
