.search-albums {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 16px 16px 0;
  }

  &__mobile-loading {
    margin-right: 16px;

    &-item {
      height: 56px;
      min-width: 100%;
      padding: 0;
      width: 100%;

      @media (--from-tablet-landscape) {
        height: 56px;
        min-width: 100%;
        width: 100%;
      }
    }
  }

  &__loading {
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;

    &-item {
      height: 268px;
      min-width: 192px;
      width: 192px;
    }
  }

  &__no-results-section {
    display: grid;
    grid-template-columns: 1fr;
    margin: 16px;
    row-gap: 16px;

    @media (--from-tablet-landscape) {
      margin: 16px 16px 16px 0;
    }
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }
}
