.checkboxes {
  --cross-hover-color: var(--color-gray-200);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 8px;

  &__checkbox {
    display: block;
    flex: 0 0 auto;
  }

  &__checkbox-input {
    display: none;
  }

  &__checkbox-btn {
    background: var(--color-cod-gray-500);
    border-radius: 12px;
    color: var(--color-gray-200);
    cursor: pointer;
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    padding: 3px 8px 5px;
    transition: color .2s, background .2s, font-weight .2s;
    user-select: none;

    @media (hover: hover) {
      &:hover {
        --cross-hover-color: var(--color-gin);
        background: var(--color-cod-gray-400);
        color: var(--color-gin);
      }
    }

    &--rectangular {
      border-radius: 4px;
      padding: 12px;
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      background: var(--color-cod-gray-500);
      color: var(--color-gray-200);
    }

    &--cross {
      background-color: var(--color-tundora) !important;
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--color-gin);

      @media (hover: hover) {
        &:hover {
          background: var(--color-cod-gray-400) !important;
        }
      }
    }

    &--count {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &__checkbox-input:checked + &__checkbox-btn {
    background: var(--color-tundora);
    color: var(--color-gin);
  }

  &__checkbox-rectangular-input:checked + &__checkbox-btn {
    background: var(--color-gin);
    color: var(--color-cod-gray);
  }

  &__divider-container {
    background-color: var(--cross-hover-color);
    transition: background-color .2s;
    height: 12px;
    width: 1px;
  }

  &__cross {
    height: 20px;
    width: 20px;

    svg > path {
      stroke: var(--cross-hover-color);
      transition: stroke .2s;
    }
  }
}
