.playlists-form {
  &__modal-content {
    background-color: var(--color-cod-gray-300);
    max-width: 90%;
    padding: 16px;

    @media (--mobile) {
      width: 90%;
    }

    @media (--from-tablet-landscape) {
      max-width: 670px;
      padding: 32px;
    }

    &--small {
      @media (--from-tablet-landscape) {
        max-width: 580px;
      }
    }
  }

  &__modal-form {
    color: var(--color-gin);
  }

  &__modal-header {
    color: var(--color-gin);
    display: flex;
    justify-content: space-between;
    margin-block-end: 16px;
  }

  &__modal-text {
    font: 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .0025em;
    margin-block-end: 32px;
    padding-inline-end: 40px;
  }

  &__modal-close-icon {
    color: var(--color-gin);
  }

  &__modal-input-text {
    font-size: 14px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--color-gray-200);
    color: var(--color-gray-200);
    display: block;
    height: 40px;
    margin-block-end: 32px;
    width: 100%;
    border-radius: 0;
    appearance: none;

    @media (--from-tablet-landscape) {
      width: 50%;
    }

    &[data-focus-visible-added] {
      border-color: var(--color-gin);
      color: var(--color-gin);
      outline: none;
    }

    &--long {
      width: 100%;
      margin: 2.6rem 0;

      &::placeholder {
        opacity: 1;
      }

      & + button {
        margin-top: 2.2rem;
      }
    }
  }

  &__modal-btn {
    font: 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0032em;
    padding: 8px 12px;

    [type='submit']& {
      background-color: var(--color-gin);
      margin-right: 16px;
      color: var(--color-black);
    }

    [type='button']& {
      border: 1px solid var(--color-gin);
      color: var(--color-gin);
    }
  }
}
