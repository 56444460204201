.card-description {
  --description-font: 500 1.2rem var(--font-gerstner);
  --description-color: var(--color-gray-200);
  min-height: 16px;

  &__artist {
    font: var(--description-font) !important;
    color: var(--description-color) !important;
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > a {
      font: var(--description-font) !important;
      color: var(--description-color) !important;

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin) !important;
        }
      }
    }

    > span {
      font: var(--description-font) !important;
      color: var(--description-color) !important;
    }
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__single-line-description {
    font: var(--description-font) !important;
    color: var(--description-color) !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > div {
      font: var(--description-font) !important;
      color: var(--description-color) !important;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
