.versions-column {
  align-items: center;
  display: flex;
  gap: 8px;

  @media (min-width: 1191px) {
    display: flex !important;
  }

  &__upgrade-to-unlock {
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1rem/1.6rem var(--font-gerstner);
    height: 24px;
    justify-content: center;
    letter-spacing: .75px;
    transition: all .2s;
    padding: 10px;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-gin);
        color: var(--color-black);
        border: 1px solid var(--color-gin);
      }
    }
  }

  &__pill {
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    color: var(--color-gray-200);
    display: flex;
    font: 1rem/1.6rem var(--font-gerstner);
    height: 24px;
    justify-content: center;
    letter-spacing: .75px;
    width: 32px;

    &__download {
      display: none;
    }

    &__downloaded {
      background-color: var(--color-tundora);
      border-color: var(--color-gray-200);
      color: var(--color-gray-200);
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-white);
        border: 1px solid var(--color-gin);
        color: var(--color-black);
      }

      &:not([disabled]):hover &__text {
        display: none;
      }

      &:not([disabled]):hover &__download {
        display: flex;
        justify-content: center;

        > svg,
        > svg path {
          fill: currentColor;
          transition: fill .2s;
        }
      }
    }

    &[disabled] {
      opacity: .25;
    }
  }

  &__more-versions-btn {
    align-items: center;
    color: inherit;
    display: flex;
    font: inherit;
    transition: color .2s;
    white-space: nowrap;

    &--open > svg {
      transform: rotate(180deg);
    }

    > svg {
      fill: currentColor;
      transition: transform .2s;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }

    @media (--from-desktop) {
      display: none !important;
    }
  }

  &__default {
    @media (--from-desktop) {
      display: none !important;
    }
  }

  &__from-desktop {
    display: none !important;

    @media (--from-desktop) {
      display: flex !important;
    }

    @media (--from-large-desktop) {
      display: none !important;
    }
  }

  &__from-large-desktop {
    display: none !important;

    @media (--from-large-desktop) {
      display: flex !important;
    }
  }
}
