.user-notifications {
  &__container {
    bottom: 0;
    display: none;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 72px;
    z-index: 40;
    background-color: var(--color-cod-gray-100);
    color: var(--color-alabaster);
    width: 428px;
    height: 100%;
    box-shadow: 0 8px 24px 0 var(--color-cod-gray);
    padding-bottom: 80px; /** Last element is hidden otherwise **/

    &--open {
      z-index: 999;
      display: flex;
    }
  }

  &__container-mobile {
    background-color: var(--color-cod-gray-100);
    color: var(--color-alabaster);
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    transform: translateX(100%);
    transition: transform .2s ease-in-out;
    padding-bottom: 80px; /** Last element is hiddent otherwise **/

    &--open {
      transform: translateX(0%);
    }
  }

  &__close {
    width: 100px;
    display: flex;
  }

  &__header {
    padding: 0 var(--left-padding);
    padding-top: var(--left-padding);
    width: 100%;
    background-color: var(--color-cod-gray-300);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--half-padding);

    @media (--from-tablet-landscape) {
      padding: 0 calc(var(--left-padding) * 2);
      padding-top: calc(var(--left-padding) * 2);
    }

    &--row {
      display: flex;
      justify-content: space-between;
    }

    &--clear-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--left-padding);
      width: 100px;
    }
  }

  &__clear-button {
    color: var(--color-main-dynamic);
    transition: color .25s;
    font: 500 1.2rem var(--font-gerstner);

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__cross-button {
    @media (hover: hover) {
      &:hover {
        > svg > path {
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__items-container {
    overflow: visible;
    overflow-y: scroll;
    position: relative;
    height: 100%;

    &--modal-open {
      overflow: hidden;
    }
  }

  &__items {
    position: relative;
    height: 100%;
    padding-top: var(--top-padding);
    padding-bottom: calc(var(--top-padding) * 14);
    display: flex;
    flex-direction: column;

    /* Hide Scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @media (--from-tablet-landscape) {
      padding-bottom: calc(var(--top-padding) * 4);
    }
  }

  &__empty {
    max-height: 88px;
    padding: 24px calc(var(--left-padding) * 2);
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__items::-webkit-scrollbar {
    display: none;
  }
}

.user-notifications-item {
  align-items: center;
  display: flex;
  transition: background-color .25s;
  cursor: pointer;
  flex: 1;
  user-select: none;
  max-height: 80px;
  padding: var(--top-padding) 0;

  &__notification {
    align-items: center;
    display: flex;
    width: 100%;
    height: 48px;
  }

  &__image-container {
    flex: 0 0 48px;
    position: relative;
  }

  &__play-hover {
    align-items: center;
    background-color: rgba(var(--color-black-russian-rgb) / 80%);
    display: flex;
    position: absolute;
    inset: 0;
    justify-content: center;
    transition: opacity .2s;
    z-index: 100;
    opacity: 0;

    > svg {
      > path {
        fill: var(--color-gin);
      }
    }
  }

  &__image {
    object-fit: cover;
    width: 48px !important;
    height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-cod-gray-400);
  }

  &__right-container {
    display: flex;
    justify-content: flex-end;

    &--action {
      width: 100px;
    }
  }

  &__right-button {
    color: var(--color-main-dynamic);
    transition: color .25s;
    letter-spacing: .35px;
    font: 500 1.2rem var(--font-gerstner);
    margin-right: calc(var(--left-padding) * 2);
    margin-left: var(--left-padding);

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__download-button {
    @media (--mobile) {
      display: none;
    }
  }

  &__middle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: var(--left-padding);
  }

  &__main-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font: 500 1.3rem var(--font-gerstner);
    letter-spacing: .35px;
  }

  &__multiline-text {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font: 500 1.3rem var(--font-gerstner);
  }

  &__secondary-text {
    font: 300 1.3rem var(--font-gerstner);
    letter-spacing: .35px;
    color: var(--color-gray-200);
  }

  &__left-container {
    width: 24px;
    height: 24px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
  }

  &__read {
    position: absolute;
    background-color: var(--color-main-dynamic);
    height: 6px;
    width: 6px;
    border-radius: 3px;
  }

  &__close {
    height: 24px;
    opacity: 0;
    width: 24px;
    justify-content: center;
    transition: opacity .2s;
    margin-left: auto;

    &:focus {
      display: flex;
      height: 24px;
      opacity: 1;
      width: 24px;
    }

    &:focus + .user-notifications-item__image {
      display: none;
    }

    @media (hover: hover) {
      &:hover {
        > svg > path {
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__ellipsis {
    opacity: 1;
    margin: 0 var(--left-padding);

    @media (--from-tablet-landscape) {
      opacity: 0;
    }

    &--visible {
      opacity: 1;
    }

    @media (hover: hover) {
      &:hover {
        > svg > path {
          fill: var(--color-gin);
        }
      }
    }
  }

  &:hover &__close {
    opacity: 1;
  }

  &:hover &__read {
    opacity: 0;
  }

  &:hover &__ellipsis {
    opacity: 1;
  }

  &:hover &__play-hover {
    opacity: 1;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-cod-gray-400);
    }
  }
}

.user-notifications-section {
  padding: var(--top-padding) calc(var(--top-padding) * 2);
  font: 500 16px var(--font-gerstner);
  letter-spacing: .35px;
  user-select: none;
  max-height: 48px;

  &__play-item {
    background-color: var(--color-cod-gray-400);
    width: 81px;
    user-select: none;

    svg > path {
      fill: var(--color-gin);
    }
  }

  &__delete-item {
    background-color: var(--color-burnt-sienna);
    width: 81px;
    user-select: none;
  }

  &__delete-icon {
    svg > path {
      fill: var(--color-black);
    }
  }

  &__close-icon {
    svg > path {
      stroke: var(--color-black);
    }
  }
}
