.versions-menu {
  display: flex;
  flex-direction: column;

  &__item {
    align-items: center;
    display: flex;
    padding: 12px 16px;
  }

  &__item-play {
    flex: 1 1 auto;
    margin-right: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--color-gray-200);
    gap: 12px;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);

        i {
          svg {
            fill: var(--color-gin);
          }
        }
      }
    }
  }

  &__item-title {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    text-align: start;
  }

  &__item-button {
    flex: 0 0 auto;
    margin: 0 8px;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        path {
          fill: var(--color-gin);
        }
      }
    }
  }
}
