.invoices {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 40px;
  }

  &__heading--desktop-only {
    display: none;

    @media (--from-tablet) {
      display: table-cell;
    }
  }

  &__text {
    color: var(--color-main-dynamic);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__table {
    margin-top: 16px;
    width: 100%;

    tr {
      text-align: center;

      @media (--from-tablet) {
        border-bottom: 1px solid var(--color-cod-gray-400);
        text-align: left;
      }
    }

    th {
      color: var(--color-gray-200);
      font: 500 1rem/1.6rem var(--font-gerstner);
      letter-spacing: .75px;
      padding: 4px 0;
      text-transform: uppercase;
    }

    td:last-child,
    th:last-child {
      @media (--from-tablet) {
        text-align: right;
      }
    }

    td {
      color: var(--color-gin);
      font: 300 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .15px;
      padding: 18px 0;

      span {
        height: 24px;
        display: block;
      }
    }

    td:first-child {
      font: 500 1.4rem/2rem var(--font-gerstner);
    }
  }
}
