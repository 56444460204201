.favorite-curated-sets {
  color: var(--color-gin);
  margin-bottom: 16px;

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
