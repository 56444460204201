.for-you {
  &__artist-link {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    position: relative;
    text-decoration: none;

    &:hover > span {
      text-decoration: underline;
    }
  }

  :global {
    /* equal height slides, based on the tallest */
    .swiper-slide {
      height: auto;

      > * {
        height: 100%;
      }
    }
  }

  &__loading {
    display: flex;
    margin-top: 16px;
    overflow: hidden;

    &-item {
      height: 196px;
      min-width: 180px;
      width: 180px;

      @media (--from-tablet-landscape) {
        height: 260px;
        min-width: 264px;
        width: 264px;
      }
    }
  }

  &__title {
    display: flex;

    > svg > path {
      stroke: var(--color-main-dynamic);
    }

    @media (--from-tablet-landscape) {
      > svg {
        display: none;
      }
    }
  }
}
