.more-options-menu {
  &__container {
    position: absolute;
    z-index: 100;
  }

  &__list {
    background-color: var(--color-cod-gray-300);
    display: none;
    display: block;
    position: absolute;
    right: 100%;
    width: 200px;
  }
}

.more-options-menu-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &__btn {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    transition: color .2s;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }

    >span {
      margin-inline-start: 8px;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
