.action-modal {
  display: flex;
  flex-direction: column;
  height: auto;

  &__container {
    height: 100%;
    padding: 18px 24px;
    width: 100%;

    &--light {
      background-color: var(--color-gin);
      color: var(--color-cod-gray);
    }

    &--dark {
      background-color: var(--color-cod-gray);
      border: 1px solid var(--color-cod-gray-400);
      color: var(--color-gin);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &--close-light,
    &--close-dark {
      display: flex;
      margin-bottom: auto;
    }

    &--close-light svg {
      >path {
        stroke: var(--color-cod-gray);
      }
    }

    &--close-dark svg {
      >path {
        stroke: var(--color-white);
      }
    }

    &-title {
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .0083em;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;

    >span {
      font: 300 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .25px;
      white-space: pre-line;
    }
  }

  &__footer {
    display: flex;
    gap: 16px;
  }

  &__footer-button {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 8px 12px;
    width: auto;

    &--confirm-light {
      background-color: var(--color-cod-gray);
      color: var(--color-gin);
    }

    &--confirm-dark {
      background-color: var(--color-gin);
      color: var(--color-cod-gray);
    }

    &--cancel {
      border: 1px solid var(--color-cod-gray);
      color: inherit;
    }
  }
}
