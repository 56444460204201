.see-more-btn {
  &__container {
    display: flex;
    width: 100%;
    background: var(--color-cod-gray-300);
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    text-align: center;
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    align-items: center;
    height: 4rem;
  }

  &__text {
    color: var(--color-main-dynamic);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    transition: all .2s;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__see-less {
    color: var(--color-tundora);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    transition: all .2s;
    padding-left: var(--left-padding);
    cursor: pointer;

    &[disabled] {
      cursor: default;
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        text-decoration: var(--plain-link-text-decoration-dynamic);
      }
    }
  }
}
