.album-detail {
  &__image {
    min-width: 136px;
    min-height: 136px;

    @media (--from-tablet-landscape) {
      min-width: 296px;
      min-height: 296px;
    }
  }

  &__title-container {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    gap: 20px;
    justify-content: space-between;

    > h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__more-dots-btn {
    flex-shrink: 0;
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }

  &__description {
    color: var(--color-gin);
    font: 1.6rem/2rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (--from-tablet-landscape) {
      line-height: 2.4rem;
    }
  }

  &__play-btn {
    > svg > path {
      fill: var(--color-main-dynamic);
    }
  }
}
