.table {
  &__list {
    margin-bottom: 20px;
    width: 100%;

    @media (--from-tablet-landscape) {
      margin-bottom: 40px;
    }
  }

  &__list-header {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-500);
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .75px;
    text-align: left;
    text-transform: uppercase;
    display: none;

    @media (--from-tablet-landscape) {
      display: grid;
      grid-template-columns: [title] 432px [version] 180px [download-date] 150px [download] minmax(130px, 1fr);
      padding: 8px 24px;
    }

    &--create {
      @media (--from-tablet-landscape) {
        grid-template-columns: [title] 539px [download-date] 150px [download] minmax(130px, 1fr);
      }
    }
  }

  &__date-group-header {
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .75px;
    padding: 4px 24px;
    background-color: var(--color-cod-gray-400);
  }

  &__list-item {
    color: var(--color-gray-200);
    font: 400 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .033em;
    align-items: center;
    display: grid;
    grid-template-columns: [title] 1fr [download] 60px;
    padding: 10px 0;

    @media (--from-tablet-landscape) {
      grid-template-columns: [title] 448px [version] 180px [download-date] 80px [download] minmax(130px, 1fr);
      padding: 14px;
    }

    &--create {
      @media (--from-tablet-landscape) {
        grid-template-columns: [title] 555px [download-date] 150px [download] minmax(130px, 1fr);
      }
    }
  }

  &__list-heading--sortable &__sort-btn {
    display: block;
  }

  &__list-header-column,
  &__list-column {
    &--download-date,
    &--version {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
      }
    }

    &--sort,
    &--actions {
      justify-self: end;
    }
  }

  &__list-header--create &__list-header-column--version {
    display: none;
  }

  &__title-column {
    column-gap: 14px;
    display: inline-grid;
    grid-template-areas: 'cover text';
    grid-template-columns: 64px 1fr;
    height: 64px;

    @media (--from-tablet-landscape) {
      grid-template-columns: 40px 1fr;
      height: 40px;
      margin-right: 16px;
    }

    &-cover {
      grid-area: cover;
      width: 100%;
    }

    &-text {
      grid-area: text;
      min-width: 0;
    }

    &-title {
      align-items: center;
      display: flex;
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      letter-spacing: .0178em;
      max-width: calc(100vw - 172px);
      color: var(--color-gin);

      > span {
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-subtitle {
      display: grid;
      font: 400 1.4rem/2rem var(--font-gerstner);
      grid-template-columns: 1fr;
      letter-spacing: .0333em;
      max-width: calc(100vw - 172px);
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      > span:first-child {
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-exclusive {
      fill: var(--color-main-dynamic);
      height: 16px;
      margin-left: 5px;
      width: 16px;
    }

    &-download-info-mobile {
      display: block;

      @media (--from-tablet-landscape) {
        display: none;
      }
    }
  }

  &__download-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    transition: color .2s;
    color: var(--color-gray-200);
    font: 400 1.4rem/2rem var(--font-gerstner);

    &[disabled] {
      opacity: .5;
    }

    > svg {
      path {
        fill: var(--color-gray-200);
        transition: fill .2s;
      }
    }

    &:not([disabled]) {
      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);

          & > svg {
            path {
              fill: var(--color-gin);
            }
          }
        }
      }
    }
  }

  &__controls {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 1fr;

    @media (--from-tablet-landscape) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__cta {
    @media (--from-tablet-landscape) {
      display: inline-block;
      width: auto;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-self: end;
    color: var(--color-gray-200);
    font: 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;

    > :first-child {
      margin-right: 8px;
    }
  }

  &__list-column {
    &--actions {
      justify-self: end;
    }
  }

  &__arrow-btn {
    font-size: 0;

    > svg {
      height: 24px;
      transition: fill .2s;
      width: 24px;

      > path {
        stroke: var(--color-main-dynamic);
      }
    }

    &[disabled] {
      opacity: .25;
    }

    &:not([disabled]) {
      cursor: pointer;

      @media (hover: hover) {
        &:hover > svg {
          stroke: var(--color-hover-dynamic);
        }
      }
    }
  }
}
