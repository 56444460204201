.account-dj-verification {
  display: flex;
  justify-content: center;
  padding-top: var(--half-padding);

  &__container {
    max-width: 384px;

    @media (--from-tablet-landscape) {
      margin-left: -15%;
    }
  }
}
