.nav-header {
  &__container {
    position: sticky;
    top: 0;
    height: var(--header-height);
    padding: 0 var(--top-padding);
    width: 100%;
    border-bottom: 1px solid var(--color-cod-gray-500);
    background: var(--color-cod-gray-700);

    @media (--mobile) {
      display: contents;
    }
  }

  &__logo-container {
    display: flex;
    flex: 0 0 100%;
    padding: 0 0 16px;
    position: relative;
    top: 18px;

    @media (--from-tablet-landscape) {
      flex: 0 0 100%;
      padding: 0;
      width: calc(var(--width-nav-open) - 20px);
    }
  }

  &__logo-img {
    display: none;

    @media (--from-tablet-landscape) {
      display: initial;
    }

    > svg {
      display: block;
      height: 28px;
      width: 28px;

      @media (--from-tablet-landscape) {
        height: auto;
        width: auto;
      }
    }
  }

  &__text-container {
    align-self: center;
    min-width: 100px;
    flex-grow: 1;
    transition: .2s opacity;
    padding: 0 8px;

    @media (--from-tablet-landscape) {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      margin: 0;
    }

    &--closed {
      opacity: 0;
    }
  }

  &__info-container {
    position: relative;
    align-self: center;
    left: 14px;
    width: 200px;
  }

  &__title {
    display: none;
    font: 500 14px var(--font-gerstner);
    color: var(--color-gin);
    font-style: normal;
    line-height: 14px;
    align-items: center;
    letter-spacing: .1px;

    @media (--from-tablet-landscape) {
      display: flex;
    }

    &--closed {
      display: none;
    }
  }

  &__dropdown-container {
    --dropdown-text-color: var(--color-gray-300);
    --dropdown-text-color-hover: var(--color-gin);
    --dropdown-text-font: inherit;
    position: static;
    width: 100%;

    @media (--tablet) {
      --dropdown-text-color: var(--color-gin);
      position: relative;
      width: 100%;
      padding: .8em 1em;
      border: 1px solid #4a4a4a;
    }

    @media (--from-tablet-landscape) {
      display: none;
    }

    *::after {
      position: static;
      display: inline-block;
      vertical-align: middle;
      margin-left: .4em;
      padding-bottom: 1px;
      transform: none;
      border-width: 4px 4px 0;
    }
  }

  &__dropdown-options {
    top: 100%;
    left: 0;
    margin-top: 10px;

    @media (--from-tablet-landscape) {
      left: -48px;
      right: 14px;
    }

    & button {
      width: 100%;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;

      &:disabled {
        cursor: not-allowed;
        color: var(--color-tundora);
      }
    }
  }

  &__dropdown-button {
    @media (--tablet) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      padding-right: 0;

      &::after {
        float: right;
        margin-top: 4px;
      }
    }
  }

  &__text {
    position: relative;
    font: 500 12px var(--font-gerstner);
    color: var(--color-gray-300);
    font-style: normal;
    line-height: 12px;
    letter-spacing: .1px;

    @media (--from-tablet-landscape) {
      margin-top: 4px;
    }

    @media (hover: hover) {
      a:hover {
        text-decoration: underline;
      }
    }

    &--closed {
      display: none;
    }
  }
}
