.library-tabs {
  column-gap: 24px;
  display: flex;
  margin-bottom: -1px;

  &__tab {
    border-bottom: 1px solid transparent;
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    padding: 15px 2px 17px;
    transition: border .25s, color .25s;
    display: flex;
    align-items: center;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }
  }

  &__label {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__tag {
    width: 30px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    border-radius: 2px;
    border: 1px solid var(--color-gray-200);
    font: 500 .8rem/1rem var(--font-gerstner);
    color: var(--color-gray-200);
    margin-left: var(--half-padding);
  }
}
