.see-more-layout {
  &__desktop {
    display: none;
    padding-top: var(--top-padding);

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__mobile {
    display: block;

    &-loading-item {
      margin-bottom: 0;
    }

    @media (--from-tablet-landscape) {
      display: none;
    }
  }
}
