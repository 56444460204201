.header-library-dropdown {
  &__dropdown-container {
    --dropdown-text-color: var(--color-gin);
    --dropdown-text-color-hover: var(--color-gin);
    --dropdown-text-font: inherit;
    display: none;
    position: static;
    margin-right: 16px;

    *::after {
      position: static;
      display: inline-block;
      transform: none;
      right: 8px;
      margin-left: .4em;
      border-width: 4px 4px 0;
      vertical-align: middle;
    }

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__dropdown-button {
    font: 400 1.4rem/2rem var(--font-gerstner);
    text-align: left;
    white-space: nowrap;
    position: relative;
    width: 100%;
    padding: 8px 10px 8px 16px;
    border: 1px solid var(--color-tundora);
    transition: border-color .2s ease;
    border-radius: 0;
    color: var(--color-gin);
    gap: 4px;

    &:not([disabled]):hover {
      border-color: var(--color-gin);
    }

    &:disabled {
      svg {
        path {
          stroke: var(--color-tundora);
        }
      }
    }
  }

  &__dropdown-options {
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 192px;
    padding: 8px 0;
  }

  &__dropdown-option-button {
    & button {
      width: 100%;
      height: 100%;
      text-align: left;

      &:disabled {
        color: var(--color-tundora);
        cursor: not-allowed;
      }
    }
  }
}
