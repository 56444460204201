.banner {
  position: relative;

  &__pagination {
    display: none;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      height: 44px;
      justify-content: center;
      margin: 9px 0 -21px;
    }
  }

  &__list-centered {
    :global(.swiper-wrapper) {
      justify-content: center;
      transition-timing-function: ease-in-out;
    }
  }

  :global {
    .swiper-slide {
      width: 325px;

      @media (--from-tablet) {
        width: 640px;
      }
    }

    .swiper-button-disabled {
      opacity: .5;
    }
  }

  &__pagination-item {
    height: 100%;
    margin: 0 4px;
    position: relative;
    width: 48px;

    &::after {
      background: var(--color-dove-gray);
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: background .2s;
      width: 100%;
    }

    &:global(.swiper-pagination-bullet-active)::after {
      background: var(--color-main-dynamic);
    }
  }

  &__list-item {
    min-height: 142px;
    max-width: 325px;
    opacity: 1;
    transition: opacity .3s;

    @media (--from-tablet) {
      max-height: 272px;
      max-width: unset;
      min-height: 275px;
    }

    @media (hover: hover) {
      &:hover {
        opacity: .7;
      }
    }

    > video {
      object-fit: cover;
    }

    > video,
    > picture > img {
      max-width: 325px;
      min-height: 142px;

      @media (--from-tablet) {
        max-height: 272px;
        max-width: unset;
        min-height: 275px;
        height: 100%;
      }
    }
  }

  &__loading {
    display: flex;
    margin-top: var(--window-padding);
    overflow: hidden;
    gap: 16px;

    &-item {
      min-width: 325px;
      height: 141px;
      background-color: var(--color-cod-gray-500);

      @media (--from-tablet) {
        height: 272px;
        min-width: 608px;
      }
    }
  }
}
