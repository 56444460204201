.genres-filter {
  &__subgenre-title {
    margin-top: 18px;
    margin-bottom: 12px;
  }

  &__search {
    margin-bottom: 16px;
  }

  &__checkboxes {
    &-searching {
      >div:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &__switch {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
