/* naming convention http://chir.ag/projects/name-that-color */
:root {
  --color-black: #000;
  --color-black-russian: #000002;
  --color-black-russian-rgb: 0 0 2;
  --color-white: #fff;
  --color-cod-gray: #121212;
  --color-cod-gray-100: #151515;
  --color-cod-gray-200: #191919;
  --color-cod-gray-300: #1a1a1a;
  --color-cod-gray-400: #2a2a2a;
  --color-cod-gray-500: #212121;
  --color-cod-gray-700: #0f0f0f;
  --color-mine-shaft: #3a3a3a;
  --color-gray: #888;
  --color-gray-100: #8a8a8a;
  --color-gray-200: #919191;
  --color-gray-300: #7b7b7b;
  --color-dove-gray: #6a6a6a;
  --color-alabaster: #fafafa;
  --color-mercury: #e3e3e3;
  --color-turquoise-blue: #42ecd7;
  --color-supreme-red: #f73e00; /* main supreme */
  --color-fruit-salad: #49a147;
  --color-burnt-sienna: #eb5757;
  --color-yellow-warning: #ffad0d;
  --color-vivid-tangerine: #fb9f80; /* hover supreme */
  --color-gin: #e6efe9;
  --color-dodger-blue: #4aa5ff;
  --color-boulder: #747474;
  --color-tundora: #4a4a4a;
  --color-latin: #f73e00;  /* main latin */
  --color-citrus: #aac107; /* hover latin */
  --color-create: #1af085;  /* create */
  --color-create2: #aac107; /* hover create - TODO: change this color */
  --color-artist-platform: #dbf717; /* artist platform */
  --color-artist-platform-hover: #71800c; /* artist platform */
  --color-all: #c2973d;
  --color-pumice: #c6cfc9;
  --color-main-dynamic: var(--color-supreme-red);
  --color-hover-dynamic: var(--color-vivid-tangerine);
  --color-text-dynamic: var(--color-gin);
}
