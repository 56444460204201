.key-camelot-input {
  display: flex;
  flex-direction: column;

  &__svg {
    height: auto;
    width: 100%;

    text {
      cursor: pointer;
    }
  }

  &__types {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 18px;
    margin-top: 16px;
  }

  &__type {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    transition: color .2s;

    &--active {
      color: var(--color-main-dynamic);
    }
  }

  &__camelot {
    cursor: pointer;
    fill: var(--color-cod-gray-400);

    &:focus {
      outline: 0;
    }

    &--has-b {
      fill: var(--color-cod-gray-300);
    }

    &--active {
      fill: var(--color-main-dynamic);
    }
  }

  &__camelot-text {
    fill: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;

    &--active {
      fill: var(--color-cod-gray);
      font-weight: 500;
    }
  }
}
