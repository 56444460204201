.expansion-panel {
  color: var(--color-gin);

  &--open {
    padding-bottom: 16px;
  }

  &:not(&--open) :global .ReactCollapse--collapse {
    visibility: hidden;
  }

  &__trigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    justify-content: space-between;
    letter-spacing: .15px;
    padding: 16px 0;

    > svg {
      flex-shrink: 0;
    }

    &[data-focus-visible-added] {
      border: none;
      outline: 1px solid var(--color-dodger-blue);
    }
  }

  &__content {
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    padding-right: 24px;
  }

  :global {
    .ReactCollapse--collapse {
      transition: height 300ms;
    }
  }
}
