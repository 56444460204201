button:not([disabled]) {
  cursor: pointer;
}

button[disabled] {
  opacity: .5;
}

.ghost-loading__loading-container {
  margin: 32px 0;

  &--half-margin {
    margin: 16px 0;
  }
}

.dragging-over {
  border-bottom: 1px solid var(--color-white);
  border-top: 1px solid var(--color-white);
}

.is-dragging {
  height: 50px;
  opacity: .8;
  width: 50px;
}

.dnd-original {
  transform: none !important;
}
