.message-banner {
  height: 6.4rem;
  background-color: var(--color-cod-gray-500);
  border-color: var(--color-cod-gray-400);
  margin: var(--window-padding);
  display: flex;
  justify-content: space-between;
  padding: 0 var(--left-padding);

  @media (--from-tablet-landscape) {
    margin: var(--window-padding) var(--window-padding) calc(var(--top-padding) * -1);
  }

  &__left-container {
    display: flex;
    gap: var(--left-padding);
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    &--title {
      font: 400 1.4rem var(--font-gerstner);
      color: var(--color-gin);
    }
  }

  &__cta {
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-dodger-blue);
  }
}
