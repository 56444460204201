.volume-control {
  height: 100%;
  position: relative;
  display: block;

  &__volume {
    align-items: center;
    display: flex;

    path {
      transition: fill .2s ease-out;
    }

    &:hover {
      path {
        fill: var(--color-gin);
      }
    }
  }

  &__slider-container {
    background: var(--color-cod-gray-500);
    bottom: 32px;
    left: 0;
    display: none;
    justify-content: center;
    position: absolute;
    width: 24px;
    padding: 16px 0;
    box-shadow: 0 8px 24px 0 #000000bf;

    &--visible {
      display: flex;
    }
  }

  &__slider {
    background: var(--color-cod-gray-500);
    border-radius: 0;
    height: 88px;
  }
}
