.promo-code {
  &__title {
    color: var(--color-gin);
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    max-width: 356px;
  }

  &__input {
    margin-right: 28px;
  }
}
