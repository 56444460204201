.draggable-component {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
  }

  &__text-container {
    height: 34px;
    max-width: 213px;
    margin-left: var(--half-padding);
    box-shadow: 0 8px 24px rgb(0 0 0 / 75%);
    align-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    background-color: var(--color-cod-gray-400);
    padding: 5px 12px;
  }

  &__text {
    font: 500 1.6rem var(--font-gerstner);
    letter-spacing: .35px;
    color: var(--color-gin);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
