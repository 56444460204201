.card-bar {
  margin-bottom: 1rem;

  &__background {
    background-color: var(--color-cod-gray-500);
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__textLeft {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-white);
  }

  &__textRight {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-burnt-sienna);
  }
}
