.account-overview {
  color: var(--color-gin);

  &__desktop-divider {
    margin-top: 3rem;
    height: 1px;
    width: 100%;
    background-color: var(--color-cod-gray-200);
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__mobile-divider {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
    background-color: var(--color-cod-gray-200);

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__desktop-section-body {
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-gray-200);
    display: none;

    @media (--from-tablet-landscape) {
      display: inline;
    }
  }

  &__mobile-section-body {
    color: var(--color-gray-200);
    font: 500 1.2rem var(--font-gerstner);
    margin-bottom: 2rem;
    margin-top: 1rem;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__mobile-section-title {
    font: 500 2.4rem var(--font-gerstner);

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__desktop-section-title {
    @media (--tablet) {
      display: none;
    }
  }

  &__personal {
    margin-bottom: 16px;
    margin-top: 24px;

    @media (--from-tablet-landscape) {
      margin-top: 32px;
    }
  }

  &__profile-img-container-mobile {
    height: 80px;
    margin-top: 16px;
    position: relative;
    width: 80px;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__user-info {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  &__user-infos {
    display: flex;
    align-items: center;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__user-name {
    font: 500 1.8rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__user-id {
    font: 200 1.2rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__profile-img-container {
    height: 80px;
    margin-top: 16px;
    position: relative;
    width: 80px;
    display: none;

    @media (--from-tablet-landscape) {
      height: 168px;
      width: 168px;
      display: flex;
    }
  }

  &__profile-img-wrapper {
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    >img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    svg {
      height: 150px;
      width: 150px;

      >circle {
        display: none;
      }
    }
  }

  &__profile-edit-container {
    bottom: 0;
    position: absolute;
    right: 0;

    >svg {
      height: 26px;
      width: 26px;
    }

    @media (--from-tablet-landscape) {
      bottom: 10px;

      >svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  &__form-container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
    margin: 16px 0 32px;

    @media (--from-tablet-landscape) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__datepicker-container {
    font-size: 1.4rem;
  }

  &__dj-type-dropdown {
    top: 10px;

    @media (--tablet) {
      height: 40px;
    }

    &-container {
      border-bottom: 1px solid var(--color-cod-gray-400);
    }

    >button {
      color: var(--color-gin);
      font: 1.4rem/2.4rem var(--font-gerstner);
      width: 100%;
      text-align: left;

      &::after {
        @media (--tablet) {
          top: 30%;
        }
      }
    }

    &--placeholder {
      >button {
        color: var(--color-gray-200);
        font-weight: 200;
      }
    }
  }

  &__cta {
    display: block;
    max-width: 400px;
    width: 100%;

    @media (--from-tablet-landscape) {
      display: inline-block;
      width: auto;
    }
  }
}
