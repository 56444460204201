.mini-download-button {
  display: none;

  @media (--from-tablet) {
    align-items: center;
    display: flex;
    justify-self: flex-end;
  }

  svg path {
    transition: fill .2s;
  }

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: var(--color-gin);
      }
    }
  }
}
