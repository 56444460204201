.favorites-desktop {
  &__header {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      margin-right: 16px;
    }
  }

  &__no-result-container {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-right: 28px;
    margin-right: 1px;
  }

  &__no-results-section {
    display: grid;
    grid-template-columns: 1fr;
    margin: 32px 0;
    row-gap: 16px;
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__track-list-container {
    &--presets {
      margin-top: 32px;
    }
  }

  &__loading {
    margin-top: 16px;

    &-item {
      height: 43px;
      min-width: 100%;
      padding: 0;
      width: 100%;
    }
  }
}
