.my-playlists-by-category {
  &__grid {
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 163px));
    row-gap: 16px;

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 192px);
    }
  }

  &__artist-link {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    position: relative;
    text-decoration: none;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
    }

    &:hover > span {
      text-decoration: underline;
    }
  }
}
