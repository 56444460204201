.modal {
  background-color: var(--color-cod-gray-300);
  border: 1px solid var(--color-cod-gray-500);
  border-radius: 0;
  box-shadow: 0 8px 24px 0 #000000bf;
  position: relative;

  &--wide {
    max-width: 580px;
  }

  &__close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        transition: stroke .2s;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__label {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    color: var(--color-gray-200);
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;

    &--destructive {
      background-color: transparent;
      color: var(--color-burnt-sienna);
    }
  }
}
