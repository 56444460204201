.discount-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 25%;
    padding-block: 24px;

    @media (--from-tablet) {
      margin-top: 39%;
      padding: 24px;
    }
  }

  &__heading {
    font: 500 3.2rem/4rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &__limited-offer {
    background-color: var(--color-cod-gray-400);
    padding: 24px 32px;
    border-left: 1px solid var(--color-supreme-red);
  }

  &__limited-offer-top-content {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
  }

  &__limited-offer-tag {
    color: var(--color-cod-gray);
    text-transform: uppercase;
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    padding: 4px 12px;
    background-color: var(--color-supreme-red);
  }

  &__limited-offer-counter {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gin);
    text-transform: uppercase;
  }

  &__limited-offer-heading {
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    color: var(--color-gin);
    margin-bottom: 8px;
  }

  &__buttons {
    --color-error: var(--color-burnt-sienna);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 16px;
  }

  &__price {
    color: var(--color-gin);
  }
}
