.title-column {
  column-gap: 8px;
  display: inline-grid;
  grid-template-areas:
    'cover title'
    'cover subtitle';
  grid-template-columns: 40px minmax(0, 1fr);
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: repeat(2, 1fr);
  height: 40px;
  align-items: center;

  @media (--from-tablet) {
    column-gap: 14px;
  }

  &--no-subtitle {
    grid-template-rows: 1fr;
  }

  &__cover-wrapper,
  &__play-btn {
    grid-area: cover;
    width: 100%;
  }

  &__cover-wrapper {
    position: relative;
    height: 40px;
    width: 40px;
  }

  &__cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__cover-play-overlay {
    align-items: center;
    background-color: var(--color-cod-gray-300);
    border-radius: 100%;
    display: none;
    height: 24px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;

    > svg {
      height: 100%;
      width: 100%;

      > path {
        fill: var(--color-gin);
      }
    }
  }

  @media (hover: hover) {
    &__cover-wrapper:hover > &__cover-play-overlay {
      display: flex;
    }
  }

  &__play-btn {
    > svg > path {
      fill: var(--color-gin);
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-gray-200);
      }
    }
  }

  &__title {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    font: 500 1.4rem/2rem var(--font-gerstner);
    grid-area: title;
    letter-spacing: .0035em;

    &--active,
    &--active a {
      color: var(--color-main-dynamic);
    }

    @media (--from-tablet) {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: .015rem;
    }

    @media (--from-tablet-landscape) {
      /* this is needed for the text to get truncated with an ellipsis */
      min-width: 0;
      padding-right: 12px;
    }

    > span {
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__subtitle {
    color: var(--color-gray-200);
    display: block;
    font: 400 1.2rem/1.6rem var(--font-gerstner);
    grid-area: subtitle;
    letter-spacing: .04rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a,
    span {
      color: var(--color-grey-200);
    }

    &--active {
      a,
      span {
        /* color: var(--color-main-dynamic); */
        color: var(--color-gray-200);
      }
    }

    @media (--from-tablet) {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: .025rem;
    }
  }

  &__exclusive {
    height: 16px;
    margin-left: 5px;
    width: 16px;

    svg {
      fill: var(--color-supreme-red);
      height: 100%;
      width: 100;
    }
  }

  &__premium-tag {
    align-items: center;
    border: 1px solid var(--color-main-dynamic);
    color: var(--color-main-dynamic);
    display: flex;
    font: 500 .8rem var(--font-gerstner);
    justify-content: center;
    margin-left: 8px;
    padding: 2px;
    line-height: 10px;
    height: 14px;
    border-radius: 2px;
    overflow-x: visible !important;
  }
}
