.page-header {
  &__back-button {
    --size: 4rem;
    all: unset;
    display: block;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: var(--sp-lg) 0;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--color-cod-gray-400);

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 150%;
      height: 150%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.6);
      opacity: .8;
      border-radius: 50%;
      background-color: var(--color-gin);
      z-index: -1;
    }

    @media (--from-tablet) {
      --size: 4.8rem;
    }

    &:hover,
    &:focus-visible {
      color: var(--color-cod-gray);
      background-color: var(--color-gin);

      &::before {
        transition: .5s all ease-out;
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        background-color: var(--color-gin);
      }
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    margin-block: 1em;
    color: var(--color-secondary);
  }
}
