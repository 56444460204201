.platform-switch {
  background-color: var(--color-cod-gray-500);
  color: var(--color-alabaster);
  right: 0;
  top: var(--header-height);
  z-index: 999;
  position: fixed;
  width: 320px;
  padding: 16px;
  box-shadow: 0 0 8px 0 #0000003d, 0 8px 24px 0 #0000003d;

  &--create {
    right: 200px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__platform-link,
  &__link {
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    color: var(--color-gray-200);
    padding: 14px 0;
    transition: color .2s ease-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-dodger-blue);
    }

    &:hover {
      color: var(--color-gin);
    }

    svg {
      width: 24px;
      height: 24px;
    }

    @media (--from-tablet-landscape) {
      padding: 8px 0;
    }
  }

  &__divider {
    border-top: 1px solid var(--color-cod-gray-400);
    margin: 8px 0;
  }
}
