.list-loading {
  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: [accordion] 24px [title] 1fr [actions] 40px;
    margin: 8px 0;
    height: 40px;

    @media (--from-tablet) {
      margin: 20px 8px;
    }

    &--maxi-player,
    &--download {
      grid-template-columns: [accordion] 24px [title] 1fr [actions] 40px;

      @media (--from-tablet) {
        grid-template-columns:
          [accordion] 32px [title] minmax(230px, 1fr)
          [bpm] 68px [key] 69px [genre] 144px [download] 40px [actions] minmax(40px, auto);
      }

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 32px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 200px [actions] minmax(108px, 128px);
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 32px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 280px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [actions] minmax(128px, 170px);
      }
    }

    &--download {
      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [added] 120px [actions] minmax(128px, 170px);
      }
    }
  }

  &__column {
    height: 18px;
    background-color: var(--color-cod-gray-400);
    margin-right: 24px;
    display: block;

    &--title {
      height: 100%;
    }

    &--bpm,
    &--key,
    &--genre {
      display: none;

      @media (--from-tablet) {
        display: block;
      }
    }

    &--download,
    &--actions {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
      }
    }

    &--date-added {
      display: none;

      @media (--from-large-desktop) {
        display: block;
      }
    }

    &--accordion {
      margin-right: 0;
    }

    &--accordion,
    &--title {
      background-color: transparent;
    }
  }

  &__title-ghost-element {
    background-color: transparent;
    width: 100%;
    margin: 0;
  }

  &__item--maxi-player &__column {
    background-color: #fff2;

    &--accordion,
    &--title {
      background-color: transparent;
    }
  }
}
