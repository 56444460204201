/* @TODO - setup breakpoints */
:root {
  --mobile: 767px;
  --tablet: 1023px;
  --tablet-landscape: 1339px;
  --desktop: 1919px;
}

@custom-media --mobile (max-width: 767px);
@custom-media --from-tablet (min-width: 768px);
@custom-media --tablet (max-width: 1023px);
@custom-media --from-tablet-landscape (min-width: 1024px);
@custom-media --tablet-landscape (max-width: 1339px);
@custom-media --from-desktop (min-width: 1440px);
@custom-media --desktop (max-width: 1919px);
@custom-media --from-large-desktop (min-width: 1920px);
