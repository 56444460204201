.change {
  &__title {
    color: var(--color-gin);
    margin-bottom: 32px;
  }

  &__plans {
    margin-bottom: 32px;

    @media (--from-tablet) {
      align-items: center;
      display: flex;

      svg {
        transform: none;
      }
    }
  }

  &__plans-icon {
    text-align: center;

    svg {
      transform: rotate(90deg);
    }

    @media (--from-tablet) {
      svg {
        transform: none;
      }
    }
  }

  &__payment_failed {
    margin: 32px 0;
    max-width: 752px;

    &__notif {
      padding: 16px;
      background-color: var(--color-cod-gray-500);
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      color: var(--color-gin);
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__cancel {
      margin-top: 16px;
      font: 500 1.4rem/2rem var(--font-gerstner);
      color: var(--color-gray-200);

      a {
        text-decoration: underline;
      }
    }
  }

  &__promo-code {
    margin-bottom: 32px;

    &--bottom {
      margin-top: 32px;
    }
  }

  &__next-steps {
    color: var(--color-gin);
    margin: 32px 0;
  }

  &__account-buttons {
    display: flex;
  }

  &__next-steps-text {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-top: 16px;
  }

  &__submit {
    margin-right: 16px;
  }
}
