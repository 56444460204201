.seek-slider {
  height: 2px;
  width: 100%;
  background-color: var(--color-boulder);
  cursor: pointer;
  touch-action: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--seek-slider-progress);
    background-color: var(--color-supreme-red);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: var(--seek-slider-progress);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: radial-gradient(circle at center, var(--color-supreme-red) 0 6px, transparent 0 100%);
    cursor: pointer;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;

    &::after {
      background: radial-gradient(circle at center, var(--color-supreme-red) 0 7px, transparent 0 100%);
    }

    &::before {
      box-shadow: 0 0 0 1px var(--color-supreme-red);
    }
  }
}
