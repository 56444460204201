.tags-view {
  &--tags {
    display: flex;
    flex-wrap: wrap;
    height: 26px;
    gap: 8px;
    overflow-y: hidden;
    overflow: hidden;

    &-large {
      height: 34px;
    }

    &-ghost {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__ghost {
    min-width: 50px;
    height: 24px;
    margin-top: 4px;
    margin-bottom: 4px;

    &--large {
      min-width: 100px;
      height: 34px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__tag {
    align-items: center;
    background-color: var(--color-cod-gray-500);
    border-radius: 12px;
    color: var(--color-gray-200);
    display: flex;
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    padding: 4px 8px;
    white-space: nowrap;
    transition: background-color .2s, color .2s, border-color .2s;

    &-large {
      font: 1.6rem/2.4rem var(--font-gerstner);
      padding: 5px 16px;
      border-radius: 16px;
    }

    &__selected {
      background-color: var(--color-gin);
      border-color: var(--color-gin);
      color: var(--color-black-russian);
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-cod-gray-400);
        color: var(--color-gin);
      }

      &:not([disabled]):hover &__text {
        display: none;
      }
    }
  }

  &__view-all-tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--half-padding);
    row-gap: var(--half-padding);
  }

  &__view-all-text {
    font: 1rem/1.6rem var(--font-gerstner);
    align-items: center;
    display: flex;
    transition: color .2s linear;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
      }
    }
  }
}
