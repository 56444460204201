html,
body {
  overscroll-behavior-y: none;
}

html.stop-scroll {
  overflow: hidden;
}

/* animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-90deg, #000 30%, #0005, #000 70%) right / 300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

:root {
  --header-height: 72px;
  --toolbar-height: 49px;
  --width-nav-open: 272px;
  --width-filters-open: 305px;
  --width-nav-close: 64px;
  --menu-item-icon-width: 64px;
  --mobile-header-height: 58px;
  --left-padding: 16px;
  --dropdown-gap: 12px;
  --half-padding: 8px;
  --top-padding: 16px;
  --spin-animation: spin;
  --window-padding: 16px;
  --nav-width: var(--width-nav-open);
  --mini-player-height: 64px;

  @media (--from-tablet) {
    --mini-player-height: 72px;
  }

  @media (--from-tablet-landscape) {
    --window-padding: 32px;
  }

  @media (--from-desktop) {
    --mini-player-height: 80px;
  }

  @media (--from-large-desktop) {
    --mini-player-height: 88px;
  }
}

body {
  display: flex; /* Force footer to bottom of page */
  flex-direction: column; /* Force footer to bottom of page */
  min-height: 100vh; /* Force footer to bottom of page */
  overflow-x: hidden;
  background-color: #0f0f0f;
}

body.is-latin {
  --color-main-dynamic: var(--color-latin);
  --color-hover-dynamic: var(--color-vivid-tangerine);
  --color-text-dynamic: var(--color-gin);
  --plain-link-text-decoration-dynamic: none;
}

body.is-white-label {
  /* --color-main-dynamic: var(--color-gin);
  --color-hover-dynamic: var(--color-gin);
  --color-text-dynamic: var(--color-black);
  --plain-link-text-decoration-dynamic: none; */

  --color-main-dynamic: var(--color-supreme-red);
  --color-hover-dynamic: var(--color-vivid-tangerine);
  --color-text-dynamic: var(--color-gin);
  --plain-link-text-decoration-dynamic: none;
}

body.is-create {
  --color-main-dynamic: var(--color-create);
  --color-hover-dynamic: var(--color-gin);
  --color-text-dynamic: var(--color-black);
  --plain-link-text-decoration-dynamic: underline;
}

body.is-artist-platform {
  --color-main-dynamic: var(--color-artist-platform);
  --color-hover-dynamic: var(--color-artist-platform-hover);
  --color-text-dynamic: var(--color-black);
  --plain-link-text-decoration-dynamic: underline;
}

p,
pre,
blockquote,
a,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
fieldset {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;

  &:not(.normal-link) {
    text-decoration: none;
  }
}

img,
a {
  -webkit-user-drag: none;
}

button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
  max-width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
}

body.nav-closed {
  --nav-width: var(--width-nav-close);
}

body.nav-hidden {
  --nav-width: 0;
}

.main {
  flex: 1 0 auto; /* Force footer to bottom of page */

  @media (--from-tablet-landscape) {
    padding-inline-start: var(--nav-width);
  }

  &:not(&--nav-hidden) {
    @media (--from-tablet-landscape) {
      position: relative;
      transition: padding .2s;
    }
  }

  .isResizing & {
    transition: none !important;
  }

  &--filters-open {
    .hide-filters-open {
      display: none;
    }
  }
}

img {
  max-width: 100%;
}

.dynamic-button {
  color: var(--color-main-dynamic);
  font: 500 1.4rem var(--font-gerstner);
  letter-spacing: .35px;
  text-decoration: none;

  &:hover {
    color: var(--color-hover-dynamic);
    text-decoration: var(--plain-link-text-decoration-dynamic);
  }
}

.Toastify__toast {
  background: var(--color-cod-gray-500);
  color: var(--color-gin);
  font: 500 1.4rem var(--font-gerstner);
  border-radius: 0 !important;
}

.Toastify__toast-container {
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important;

  @media (--from-tablet) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.smcx-iframe-container {
  margin-top: 10px !important;
}

.smcx-widget.smcx-embed {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
  visibility: hidden;
}

/* stylelint-disable-next-line selector-class-pattern */
.__react_component_tooltip.show {
  background-color: var(--color-cod-gray-400) !important;
  opacity: 1 !important;
  box-shadow: 0 8px 24px 0 rgb(18 18 18 / 75%);
}

/* stylelint-disable-next-line selector-class-pattern */
.__react_component_tooltip {
  max-width: 60ch;
  line-height: 120%;

  &::after {
    border-top-color: var(--color-cod-gray-400) !important;
  }

  &.place-left::after {
    border-left-color: var(--color-cod-gray-400) !important;
  }
}
