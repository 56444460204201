.mini-player {
  &__wave {
    display: none;

    @media (--from-desktop) {
      display: grid;
      grid-template-columns: 40px 1fr 40px;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      width: 432px;
    }

    @media (--from-large-desktop) {
      width: 744px;
    }

    @media (min-width: 2560px) {
      width: 944px;
    }
  }

  &__elapsed,
  &__duration {
    color: var(--color-gray-200);
    font: 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    font-weight: 500;
    width: 40px;
  }

  &__elapsed {
    text-align: right;
  }

  &__duration {
    text-align: left;
  }

  &__volume-control {
    position: relative;
    display: none;

    @media (--from-desktop) {
      display: block;
    }
  }
}
