.desktop-wave {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-areas:
    'wave wave'
    'elapsed duration';

  @media (--from-tablet) {
    gap: 8px;
    grid-template-areas: 'elapsed wave duration';
    grid-template-columns: 40px 1fr 40px;
    justify-content: start;
  }

  @media (--from-desktop) {
    gap: 16px;
  }

  &__elapsed,
  &__duration {
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;
    white-space: nowrap;
    width: 40px;
  }

  &__elapsed {
    grid-area: elapsed;
    text-align: left;

    @media (--from-tablet) {
      text-align: right;
    }
  }

  &__duration {
    grid-area: duration;
    text-align: right;
    width: 100%;

    @media (--from-tablet) {
      text-align: left;
    }
  }

  &__wave {
    grid-area: wave;
    width: 100%;
    margin-bottom: 12px;

    @media (--from-tablet) {
      margin-bottom: 0;
    }
  }
}
