.artist-application-button {
  --background-color: var(--color-gin);
  --left-button-color: var(--color-tundora);
  display: flex;
  flex-direction: row;
  gap: 1rem;

  :focus:not(.focus-visible) {
    outline: none;
  }

  &--right-button {
    flex: 1;
    background-color: var(--background-color);
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all .2s;

    @media (hover: hover) {
      &:hover:not([disabled]) {
        --background-color: var(--color-tundora);
      }
    }
  }

  &--left-button {
    flex: 1;
    background-color: transparent;
    border: .1rem solid var(--left-button-color);
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all .2s;

    @media (hover: hover) {
      &:hover {
        --left-button-color: var(--color-gin);
      }
    }
  }

  &--boolean-button {
    flex: 1;
    background-color: transparent;
    border: .1rem solid var(--left-button-color);
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all .2s;

    @media (hover: hover) {
      &:hover {
        --left-button-color: var(--color-gin);
      }
    }
  }

  &--selected {
    flex: 1;
    background-color: transparent;
    border: .1rem solid var(--color-gin);
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all .2s;

    &--text {
      font: 500 1.6rem var(--font-gerstner);
      color: var(--color-gin);
      background-color: transparent;
      border: none;
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 4.2rem;
      outline-width: 0;
      transition: color .2s;
    }
  }

  &--gray-text {
    font: 500 1.6rem var(--font-gerstner);
    color: var(--left-button-color);
    background-color: transparent;
    border: none;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 4.2rem;
    outline-width: 0;
    transition: color .2s;
  }

  &--text {
    font: 500 1.6rem var(--font-gerstner);
    color: var(--color-cod-gray);
    background-color: transparent;
    border: none;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 4.2rem;
    outline-width: 0;
  }

  &--icon {
    align-self: center;
    display: flex;
    margin-top: .2rem;
    margin-left: .2rem;
    transition: all .2s;

    > path {
      transition: all .2s;
      stroke: var(--color-cod-gray);
    }
  }

  &--left-icon {
    align-self: center;
    display: flex;
    margin-right: .2rem;
    transform: scaleX(-1);
    transition: all .2s;

    > path {
      transition: all .2s;
      stroke: var(--left-button-color);
    }
  }
}
