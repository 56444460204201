.artist-card-image {
  display: flex;
  height: 248px;
  width: 192px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 4px;
  padding: var(--top-padding);

  @media (--from-tablet-landscape) {
    background-color: var(--color-cod-gray-500);
    transition: background-color .2s;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-cod-gray-400);
    }
  }

  &__card-overlay {
    background-color: rgb(0 0 0 / 80%);
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 80%) inset, 0 0 1px 0 rgb(0 0 0 / 80%);
    inset: 0;
    opacity: 0;
    position: absolute;
    transition: opacity .2s;
    border-radius: 100%;
    z-index: 20;

    &:not(&--disabled) {
      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > svg,
    > svg > path {
      fill: var(--color-gin);
    }
  }

  &__img-container {
    height: 160px;
    width: 160px;
    position: relative;
    margin-bottom: var(--top-padding);

    &-ghost {
      background-color: var(--color-mine-shaft);
      border-radius: 50%;
    }
  }

  &__image {
    border-radius: 100%;
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &-ghost {
      background-color: var(--color-cod-gray-500);
    }
  }

  &__text-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    inset: 0;
    width: 100%;
  }

  &__name-initials {
    display: flex;
    background-color: var(--color-tundora);
    color: var(--color-gray-200);
    font: 500 4.5rem/4.5rem var(--font-gerstner);
    inset: 0;
    padding: 16px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  &__title-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__follow-button {
    margin-top: -6px;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  &__title {
    color: var(--color-gin);
    display: block;
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .035em;
    overflow-x: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    &-ghost {
      height: 21px;
      width: 100px;
      background-color: var(--color-cod-gray-500);
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    &[data-focus-visible-added] {
      outline: none;

      &::after {
        border: 1px solid var(--color-dodger-blue);
      }
    }
  }

  &__subtitle {
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    &-ghost {
      margin-top: 4px;
      height: 21px;
      width: 80px;
      background-color: var(--color-cod-gray-500);
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        color: var(--color-gin);
      }
    }
  }
}
