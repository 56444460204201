.account-support {
  color: var(--color-gin);

  &__desktop-section-title {
    @media (--tablet) {
      display: none;
    }
  }

  &__heading {
    padding-bottom: 16px;

    @media (--from-tablet-landscape) {
      padding-top: 32px;
    }
  }

  &__panel {
    box-shadow: inset 0 -1px 0 var(--color-cod-gray-400);

    a {
      color: var(--color-main-dynamic);
      text-decoration: none;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__more-section {
    padding: 32px 0;
  }

  &__more-link {
    align-items: center;
    color: var(--color-main-dynamic);
    display: flex;
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    text-decoration: none;

    @media (--from-tablet-landscape) {
      margin-top: 20px;
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
