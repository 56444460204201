.row-component {
  --hover-background-color: none;

  &--has-hover {
    --button-visibility: hidden !important;
    margin: 0 calc(-1 * var(--left-padding));
    padding: calc(var(--left-padding) / 2) var(--left-padding);
    margin-block-end: 0 !important;
  }

  &--item {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-block-end: 16px;
    background-color: var(--hover-background-color);
  }

  &--item-image {
    flex: 0 0 56px;
    height: 56px;
    margin-inline-end: 8px;
    position: relative;
  }

  &--artist-image {
    flex: 0 0 56px;
    height: 56px;
    margin-inline-end: 8px;
    position: relative;
    border-radius: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  &--name-initials {
    display: flex;
    background-color: var(--color-tundora);
    color: var(--color-gray-200);
    font: 500 2rem/2.6rem var(--font-gerstner);
    inset: 0;
    padding: 16px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  &--item-infos {
    flex: 1 1 auto;
  }

  &--item-title {
    color: var(--color-gin);
    font: 500 1.3rem var(--font-gerstner);
    letter-spacing: .35px;
  }

  &--item-subtitle {
    color: var(--color-gray-200);
    font: 300 1.3rem var(--font-gerstner);
    letter-spacing: .35px;
    padding-block-end: 2px;
  }

  &--item-attributes {
    color: var(--color-gray-200);
    font: 300 1rem var(--font-gerstner);
    letter-spacing: .35px;
  }

  &--item-play-overlay {
    align-items: center;
    background-color: rgba(var(--color-black-russian-rgb) / 50%);
    display: flex;
    position: absolute;
    inset: 0;
    justify-content: center;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;
    opacity: 0;

    &-round {
      border-radius: 100%;
    }

    > svg {
      width: 50%;
      background-color: var(--color-black);
      height: 50%;
      border-radius: 50px;

      > path {
        fill: var(--color-gin);
      }
    }
  }

  &--button {
    opacity: 0;
    transition: opacity .2s;

    > svg > path {
      transition: fill .2s;
    }
  }

  @media (hover: hover) {
    &--item-image:hover > &--item-play-overlay {
      visibility: visible;
      opacity: 1;
    }

    &--artist-image:hover &--item-play-overlay {
      visibility: visible;
      opacity: 1;
    }

    &--has-hover:hover {
      --hover-background-color: var(--color-cod-gray-400);
    }

    &--has-hover:hover > &--button {
      opacity: 1;
    }

    &--button:hover {
      > svg > path {
        fill: var(--color-gin);
      }
    }
  }
}
