.bpm-filter {
  &__inputs {
    display: flex;
    gap: 8px;
    align-items: center;

    &-divider {
      font-size: 1.4rem;
      color: var(--color-gray-200);
    }
  }
}
