.subscription-item {
  background-color: var(--color-cod-gray-300);
  color: var(--color-gin);
  padding: 32px;
  display: flex;
  flex-direction: column;

  &__max-card-height {
    height: 430px;
  }

  &__variant {
    &--account-summary {
      width: 100%;
      min-height: 184px;
      margin-bottom: calc(var(--top-padding) * 2);
    }

    &--card {
      width: 100%;
      min-height: 320px;

      &-small {
        width: 100%;

        @media (--from-tablet-landscape) {
          width: 316px;
        }
      }

      @media (--from-tablet-landscape) {
        width: 316px;
        min-height: 435px;
      }
    }

    &--credits {
      width: 100%;
      height: 100%;

      @media (--from-tablet-landscape) {
        width: 316px;
      }
    }
  }

  &-container {
    height: 100%;
    width: 100%;

    @media (--from-tablet-landscape) {
      width: unset;
    }
  }

  &__promotion-container {
    display: flex;
    height: 24px;
    justify-content: flex-end;
  }

  &__promotion-text {
    display: flex;
    align-items: center;
    color: var(--color-cod-gray);
    font: 500 1.2rem var(--font-gerstner);
    padding: 0 8px;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  &-disabled {
    opacity: .4;
    pointer-events: none;
  }

  &__bottom-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: flex-end;
    flex: 1;
  }

  &__buttons-container {
    display: flex;
    gap: calc(var(--left-padding) / 2);
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & span {
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      text-align: right;
    }

    &--title {
      text-transform: capitalize;
    }

    &--text {
      font: 500 1.8rem/2.4rem var(--font-gerstner);
    }

    &--has-sale {
      font: 500 1.4rem var(--font-gerstner);
      color: var(--color-gray-200);
      text-decoration: line-through;
    }

    &--gray-text {
      font: 500 1.4rem var(--font-gerstner);
      color: var(--color-gray-200);
    }
  }

  &__library-text {
    color: var(--color-supreme-red);
    font: 500 1.4rem/2rem var(--font-gerstner);
    margin-bottom: 16px;
    flex: 1;
  }

  &__sale-alert {
    align-items: center;
    background-color: var(--color-supreme-red);
    color: var(--color-cod-gray);
    display: flex;
    flex: 1;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    height: 40px;
    max-height: 40px;
    margin-bottom: 16px;
    padding: 8px 16px;
    width: 100%;

    &--placeholder {
      background-color: transparent;
    }
  }

  &__description {
    color: var(--color-gin);
    font: 300 1.4rem/2rem var(--font-gerstner);

    @media (--from-tablet-landscape) {
      min-height: 60px;
    }
  }

  &__description-create {
    color: var(--color-main-dynamic);
    font: 500 1.4rem var(--font-gerstner);
    padding-top: calc(var(--top-padding) * 2);
  }

  &__value-proposition-container {
    padding: calc(var(--top-padding) * 2) 0;
  }

  &__value-proposition {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    font: 300 1.4rem/2rem var(--font-gerstner);
    gap: var(--left-padding);
    height: 28px;
  }

  &__green-icon {
    svg {
      > path {
        stroke: var(--color-fruit-salad);
      }
    }
  }

  &__cta-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__top-line {
    border-top: 1px solid var(--color-supreme-red);
  }

  &__background--premium {
    background-color: var(--color-cod-gray-500);
    border: 1px solid var(--color-tundora);
  }

  &__background--primary {
    background-color: var(--color-supreme-red);
  }

  &__background--latin {
    background-color: var(--color-supreme-red);
  }

  &__background--all {
    background-color: var(--color-supreme-red);
  }

  &__color--primary {
    color: var(--color-supreme-red);
  }

  &__color--latin {
    color: var(--color-supreme-red);
  }

  &__color--all {
    color: var(--color-supreme-red);
  }

  &__color--free {
    color: var(--color-gray-200);
  }

  &__border--primary {
    border-color: var(--color-supreme-red);
  }

  &__border--latin {
    border-color: var(--color-supreme-red);
  }

  &__border--all {
    border-color: var(--color-supreme-red);
  }

  &__border--free {
    border-color: var(--color-gray-200);
  }

  &__border--generic {
    border-color: var(--color-main-dynamic);
  }

  &__background--generic {
    background-color: var(--color-main-dynamic);
  }

  &__color--generic {
    color: var(--color-main-dynamic);
  }
}
