.cancel-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__heading {
    font: 500 3.2rem/4rem var(--font-gerstner);
    color: var(--color-gin);
    padding-top: 16px;
  }

  &__buttons {
    --color-error: var(--color-burnt-sienna);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 16px;
  }

  &__price {
    color: var(--color-gin);
  }

  &__info {
    display: flex;
    gap: 8px;
    padding: 16px;
    border: 1px solid var(--color-cod-gray-400);
    margin-top: 24px;
    color: var(--color-gray-200);
  }
}
