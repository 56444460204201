.lists-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  cursor: pointer;
  color: var(--color-gray-200);
  font: 500 1.4rem/2rem var(--font-gerstner);
  letter-spacing: .035rem;
  transition: color .2s ease-out;
  min-width: fit-content;

  path {
    transition: fill .2s ease-out;
  }

  &:hover,
  &--active {
    color: var(--color-gin);

    path {
      fill: var(--color-gin);
    }
  }
}
