.search {
  &__container {
    position: relative;
    color: #919191;
    font-size: 1.4rem;
  }

  &__input {
    display: block;
    width: 100%;
    appearance: none;
    font: inherit;
    line-height: 1.74;
    color: inherit;
    border: none;
    border-radius: 0;
    padding: var(--spacing) 40px;
    background: #2a2a2a;

    &::placeholder {
      opacity: 1;
    }
  }

  &__icon,
  &__clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__icon {
    position: absolute;
    pointer-events: none;
  }

  &__input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }

  &__input:focus-visible,
  &__input:focus-visible + &__icon,
  &__clear:hover,
  &__clear:focus-visible, {
    color: #e6efe9;
  }

  &__clear {
    right: var(--spacing);
    color: inherit;
  }

  &__clear-icon {
    all: unset;
    display: block;
  }
}
