body {
  padding-bottom: 59px;
  padding-top: var(--header-height);
  transition: padding-bottom .25s;

  &.player-is-open {
    padding-bottom: 173px;
  }

  &.maxi-player-is-open {
    overflow-y: hidden;

    /* Remove scroll from mobile when using the maxi player, appears in phones using safari */
    @media (--mobile) {
      height: 100%;
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }

  @media (--from-tablet-landscape) {
    padding-bottom: 0;
  }

  .link {
    color: var(--color-gin);
  }

  .relative-link {
    position: relative;
  }

  @media (hover: hover) {
    .relative-link:hover {
      color: var(--color-alabaster);
    }
  }

  &[data-focus-visible-added] {
    background-color: var(--color-black);
    outline: 1px solid var(--color-dodger-blue);
  }

  .underline-link {
    color: var(--color-gin);
    position: relative;
    text-decoration: none;
  }

  @media (hover: hover) {
    .underline-link:hover,
    .underline-link:hover * {
      text-decoration: underline;
    }

    .link:hover {
      color: var(--color-gin);
    }
  }

  .underline-link-track-list-item {
    color: var(--color-gray-200);
    position: relative;
    text-decoration: none;
  }

  @media (hover: hover) {
    .underline-link-track-list-item:hover {
      text-decoration: underline;
    }
  }

  .load-more-in-view {
    height: 1px;
  }
}
