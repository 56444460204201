.nav-footer {
  margin-bottom: 16px;
  margin-top: auto;
  color: var(--color-cod-gray-400);
  display: none;

  @media (--from-tablet-landscape) {
    display: block;
  }
}
