.card-image-overlay {
  background-color: rgb(var(--color-black-russian-rgb) / 50%);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: visibility .2s, opacity .2s;
  padding: var(--half-padding) 4px var(--half-padding) var(--half-padding);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 2px;

  &--visible {
    opacity: 1;
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
