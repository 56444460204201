.giveaway-detail {
  &__img-wrapper {
    margin-bottom: 40px;
    text-align: center;

    @media (--from-tablet-landscape) {
      margin-bottom: 32px;
    }

    img {
      width: 100%;
      object-fit: cover;

      @media (--from-tablet-landscape) {
        max-height: 272px;
      }
    }
  }

  &__img {
    height: auto;
    max-width: 100%;
  }

  &__intro {
    @media (--from-tablet-landscape) {
      column-gap: 53px;
      display: flex;
    }
  }

  &__summary {
    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 16px;

    @media (--from-tablet-landscape) {
      margin-bottom: 40px;
      gap: 32px;
    }
  }

  &__detail {
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    width: 164px;
    height: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      display: block;
      color: var(--color-gin);
    }

    @media (--from-tablet-landscape) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__summary-ctas {
    align-items: center;
    column-gap: 16px;
    display: flex;
    row-gap: 16px;
    flex-direction: column;

    @media (--from-tablet-landscape) {
      flex-direction: row;
    }
  }

  &__summary-cta {
    align-items: center;
    color: var(--color-gin);
    column-gap: 4px;
    display: flex;
    flex: 0 0 auto;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    justify-content: center;
    letter-spacing: .35px;

    &--primary {
      background: var(--color-gin);
      color: var(--color-cod-gray);
      padding: 8px 13.5px;
    }

    &--secondary {
      border: 1px solid var(--color-gin);
      padding: 8px 19.5px;
    }

    &--tertiary {
      justify-content: left;
    }

    &--text {
      text-decoration: none;
    }

    &--disabled {
      background: var(--color-cod-gray-500);
      color: var(--color-tundora);
      pointer-events: none;
    }
  }

  &__disclaimer-text {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
    margin-top: calc(var(--top-padding) / 2);
    margin-bottom: calc(var(--top-padding) * 2);

    @media (--from-tablet-landscape) {
      margin-bottom: 0;
    }
  }

  &__error-text {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
    opacity: 0;
    margin-bottom: calc(var(--top-padding) / 2);

    &--visible {
      color: var(--color-burnt-sienna);
      opacity: 1;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;

    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__description {
    flex: 1 0 100%;
    margin-bottom: 16px;

    * {
      color: var(--color-gin) !important;
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      letter-spacing: .15px;
    }

    a {
      color: var(--color-main-dynamic) !important;
      text-decoration: underline;
    }

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    color: var(--color-main-dynamic);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-right: auto;
  }

  &__share {
    margin-left: auto;
  }

  &__modal-title {
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    padding-top: var(--top-padding);
  }

  &__action-modal {
    @media (--from-tablet-landscape) {
      min-width: 510px !important;
    }
  }
}
