.new-releases {
  &__intro {
    align-items: center;
    display: flex;
    gap: var(--dropdown-gap);
    margin: var(--window-padding);
  }

  &__title {
    color: var(--color-gin);
    flex: 1 auto;
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 0;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__category-block {
    margin-bottom: 32px;
  }

  &__subtitle {
    color: var(--color-gin);
    display: inline-flex;
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
}
