.dropdown {
  position: relative;

  &__button {
    color: var(--color-gin);
    letter-spacing: .0071em;
    transition: background-color .2s;
    white-space: nowrap;
    border: 1px solid var(--color-tundora);
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 6px 4px 6px 12px;

    &[data-focus-visible-added],
    &:hover {
      background-color: var(--color-cod-gray-400);
    }

    &--maxi {
      font: 1.4rem/2.4rem var(--font-gerstner);
    }

    &--mini {
      font: 400 1.2rem/1.6rem var(--font-gerstner);
    }
  }

  &__options {
    display: none;
    position: absolute;
    z-index: 90;
    background-color: var(--color-cod-gray-500);
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
    padding: .25em 0;

    &--open-upward {
      bottom: 100%;
      margin-block: 5px;
    }

    &--open-downward {
      top: 100%;
      margin-block: 5px;
    }

    &--open {
      display: block;
    }

    &--left-side {
      right: 0;
      padding-block-start: -10px;
    }

    &--right-side {
      left: 0;
      padding-block-start: 5px;
    }
  }

  &__option {
    align-items: center;
    color: var(--color-gray-200);
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    cursor: pointer;
    display: grid;
    grid-template-columns: minmax(auto, 1fr) 24px 24px;
    letter-spacing: .001em;
    min-height: 40px;
    width: 320px;
    padding: 0 .5em;
    position: relative;
    gap: 1rem;

    &-btn {
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      align-items: center;
      color: inherit;
      display: flex;
      gap: .75em;
      transition: color .2s;

      svg path {
        transition: fill .2s;
        fill: var(--color-gray-200);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);

          svg path {
            fill: var(--color-gin);
          }
        }
      }

      &-label {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        text-align: start;
      }
    }
  }
}
