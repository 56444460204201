.artist-albums {
  &__ghost {
    margin-left: 12px;
    margin-top: -16px;

    @media (--from-tablet-landscape) {
      margin-top: 12px;
      margin-left: unset;
    }
  }

  &--track-tabs {
    column-gap: 24px;
    margin-bottom: -1px;
    grid-auto-flow: column;

    &__tab {
      border-bottom: 1px solid transparent;
      color: var(--color-gray-200);
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .0333em;
      padding: 15px 0 17px;
      transition: border .25s, color .25s;
      margin-right: 16px;

      @media (hover: hover) {
        &:not(&--active):not(&--disabled):hover {
          color: var(--color-gin);
        }
      }

      &--active {
        border-color: var(--color-main-dynamic);
        color: var(--color-main-dynamic);
      }

      &--disabled {
        border-color: transparent;
        color: var(--color-tundora);

        @media (hover: hover) {
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &__title {
    @media (--from-tablet-landscape) {
      margin-bottom: 16px;
    }
  }
}
