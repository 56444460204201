.list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;

  @media (--from-tablet) {
    padding: 8px 24px;
  }

  @media (--from-desktop) {
    padding: 8px 24px;
  }
}
