.card-tooltip {
  &__tooltip {
    margin: 0 -15px;
    gap: 2px;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: var(--color-cod-gray-300);
    padding: 4px 8px;

    &::after {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: 4px solid var(--color-cod-gray-300);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }

  &__tooltip-line {
    display: flex;
    gap: 4px;
    color: var(--color-gin);
  }

  &__tooltip-highlighted {
    color: var(--color-gray-200);
  }
}
