.genres {
  &__controls-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      justify-content: space-between;
      margin-right: var(--window-padding);
    }
  }

  &__heading {
    color: var(--color-gin);
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__arrow-btn {
    font-size: 0;

    &--next,
    &--prev {
      @media (--tablet) {
        display: none;
      }
    }

    > svg {
      stroke: var(--color-main-dynamic);
      transition: fill .2s;
    }

    &:not(:global .swiper-button-disabled) {
      cursor: pointer;

      &:hover > svg > path {
        stroke: var(--color-hover-dynamic);
      }
    }
  }

  &__see-more {
    &--mobile {
      @media (--from-tablet-landscape) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
        margin-inline-start: 8px;
      }
    }
  }

  &__container {
    overflow: hidden;
    width: 100%;
  }

  &__swiper {
    margin-top: -16px;
    width: 100%;

    > :global(.swiper-wrapper) {
      min-width: 100%;
    }
  }

  &__list-item-wrapper {
    display: inline-block;

    /* swiper is incorrectly calculating which row an item belongs to
    (in order to add margins), so we'll just disable their spaceBetween api
    and add our own margins */
    margin: 16px 16px 0 0 !important;
    width: auto;
  }

  &__list-item {
    background: var(--color-cod-gray-200);
    color: var(--color-alabaster);
    display: inline-block;
    font: 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0062em;
    padding: 5px 16px;
    text-decoration: none;
    transition: background-color .2s;

    @media (hover: hover) {
      &:hover {
        background: var(--color-cod-gray-400);
      }
    }
  }

  :global {
    .swiper-button-disabled {
      opacity: .5;
    }
  }
}
