.artist-detail-header {
  &--banner {
    display: flex;
    padding: var(--window-padding);
  }

  &--image-container {
    flex: 0 1 96px;
    margin-inline-end: 32px;

    @media (--from-tablet-landscape) {
      flex: 0 1 192px;
    }
  }

  &--image-initials {
    align-items: center;
    background-color: var(--color-tundora);
    color: var(--color-gray-200);
    display: flex;
    font: 500 3rem var(--font-gerstner);
    height: 100%;
    justify-content: center;

    @media (--from-tablet-landscape) {
      font: 500 6.4rem/6.4rem var(--font-gerstner);
    }
  }

  &--image {
    border-radius: 100%;
    display: block;
    height: 96px;
    width: 96px;
    overflow: hidden;

    @media (--from-tablet-landscape) {
      height: 192px;
      width: 192px;
    }

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--infos {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  &--name-container {
    display: flex;
    align-items: center;
    gap: var(--left-padding);
  }

  &--title {
    color: var(--color-gin);
    margin-block-end: 32px;
    margin-top: 2rem;

    @media (--from-tablet-landscape) {
      margin-top: 0;
    }
  }

  &--actions {
    display: none;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      gap: var(--half-padding);
    }
  }

  &--actions-mobile {
    display: flex;
    gap: var(--half-padding);
    padding: 0 var(--left-padding);
    padding-bottom: var(--top-padding);
    align-items: center;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }
}
