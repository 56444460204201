.page-header {
  display: flex;
  flex-direction: column;

  &--main-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &--buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    flex-direction: row;
  }

  &--search {
    display: flex;
    flex: 1;
    width: 100%;
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;

    @media (--from-tablet) {
      width: 20rem;
    }
  }
}
