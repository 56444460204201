.supreme-header {
  --nav-open-spacing-right: 0;
  align-items: center;
  display: flex;
  justify-content: center;

  &__container {
    align-items: center;
    background: var(--color-cod-gray-700);
    border-bottom: 1px solid var(--color-cod-gray-500);
    display: flex;
    flex-wrap: nowrap;
    height: var(--header-height);
    justify-content: space-between;
    left: 0;
    padding: 0 15px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 110;

    @media (--mobile) {
      z-index: 50;
    }

    @media (--from-tablet-landscape) {
      padding: 0 32px 0 100px;

      :global(body.nav-hidden) & {
        padding-left: 70px;
      }
    }

    &--nav-opened {
      --nav-open-spacing-right: 203px;
    }
  }

  &__logo {
    @media (--from-tablet-landscape) {
      margin-inline-end: 16px;
    }
  }

  &__logo-img {
    display: flex;

    > svg {
      height: 32px;
      width: 32px;

      @media (--from-tablet-landscape) {
        height: auto;
        width: auto;
      }
    }
  }

  &__center {
    align-items: center;
    display: none;
    flex-wrap: nowrap;
    margin-inline-end: auto;
    transition: margin-left .2s;
    margin-left: var(--nav-open-spacing-right);

    @media (--from-tablet-landscape) {
      display: flex;
    }
  }

  &__history {
    align-items: center;
    border: 1px solid var(--color-dove-gray);
    border-radius: 50%;
    display: none;
    height: 32px;
    justify-content: center;
    margin-inline-end: 8px;
    transition: background .25s;
    width: 32px;

    @media (--from-tablet-landscape) {
      display: flex;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background: var(--color-dove-gray);
      }
    }

    &[disabled] {
      opacity: .2;
    }

    &:not([disabled]):focus {
      outline: none;
    }

    &--forward {
      margin-inline-end: 16px;
    }
  }

  &__right {
    align-items: center;
    display: none;
    flex-wrap: nowrap;
    margin-inline-start: auto;
    position: relative;

    @media (--from-tablet-landscape) {
      display: flex;
    }
  }

  &__submit-track {
    padding-right: var(--left-padding);
  }

  &__credits {
    position: relative;
  }

  &__credits-btn {
    display: none;

    @media (--from-tablet-landscape) {
      align-items: center;
      color: var(--color-gray);
      display: flex;
      flex-wrap: nowrap;
      font: 1.4rem/2.4rem var(--font-gerstner);
      margin-inline-end: 8px;
    }

    > span:first-child {
      color: var(--color-gray-200);
      font-weight: 500;
      letter-spacing: .1px;
    }

    > span:not(:first-child) {
      color: var(--color-gin);
      font-weight: 700;
      letter-spacing: .1px;
      margin-inline-start: 8px;
    }

    > svg {
      fill: var(--color-gin);
    }

    &--is-open > svg {
      transform: rotate(180deg);
    }
  }

  &__subscribe {
    margin-left: 16px;
  }

  &__mobile-right {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__subscribe-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin-right: 16px;
    display: flex;
    height: 40px;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__mobile-subscribe-btn {
    font: 500 1.3rem var(--font-gerstner);
    color: var(--color-main-dynamic);
    padding-bottom: 14px;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__credits-popup {
    background-color: var(--color-cod-gray-300);
    color: var(--color-alabaster);
    display: none;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    left: 0;
    letter-spacing: .1px;
    padding: 8px 16px;
    position: absolute;
    right: 0;
    top: 30px;
    width: 180px;

    &--is-open {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: 1fr;
    }

    > span {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &__mobile-nav-btn {
    color: var(--color-gray-200);

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__anonymous-buttons {
    display: flex;
    gap: var(--left-padding);
    align-items: center;

    @media (--from-tablet-landscape) {
      min-width: 220px;
      justify-content: flex-end;
    }

    > button > div {
      font: 500 1.6rem var(--font-gerstner);
      color: var(--color-gin);
      transition: color 200ms;

      @media (hover: hover) {
        &:hover {
          color: var(--color-pumice);
        }
      }
    }
  }

  &__mobile-profile-btn {
    font: 500 1.3rem var(--font-gerstner);
    color: var(--color-main-dynamic);

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__mobile {
    align-items: center;
    background: var(--color-cod-gray);
    border-top: 1px solid var(--color-mine-shaft);
    bottom: 0;
    display: flex;
    height: var(--mobile-header-height);
    justify-content: space-around;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: var(--supreme-mobile-header-z-index);

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__RNmobile {
    display: none;
  }

  &__mobile-btn {
    display: flex;
  }

  &__mobile-search {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 110;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__mobile-search-form {
    background: var(--color-cod-gray);
    border-bottom: 1px solid var(--color-cod-gray-400);
    padding: 16px;

    .search-form__search {
      margin: 0;
      position: relative;
      width: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .search-form--legacy-mobile__search-input {
      background: var(--color-cod-gray-400);
      border: 0;
      border-radius: 100px;
      color: var(--color-alabaster);
      font: 1.4rem/2.2rem var(--font-gerstner);
      letter-spacing: .0071em;
      padding: 4px 64px 6px 40px;
      width: 100%;

      @media (--from-tablet-landscape) {
        padding: 4px 16px 6px 40px;
      }

      &::placeholder {
        color: var(--color-gray);
      }
    }
  }

  &__user-id {
    color: var(--color-gin);

    @media (--from-tablet-landscape) {
      font: 300 1.2rem/1.6rem  var(--font-gerstner);
      letter-spacing: .4px;
      margin-block-end: 16px;
    }
  }

  &__notification-mobile-btn {
    margin-inline-start: auto;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__user-menu-item {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    justify-content: space-between;
    letter-spacing: .1px;
    text-decoration: none;
    transition: color .25s;
    width: 100%;

    &--logout {
      color: var(--color-main-dynamic);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-alabaster);
      }
    }

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-dodger-blue);
    }

    > svg {
      flex: 0 0 auto;
      stroke: currentColor;
      transition: fill .25s;
    }
  }

  &__user-menu-list {
    padding: 16px;
  }

  &__user-menu-list-item {
    margin-block-end: 16px;

    &:last-of-type {
      margin-block-end: 0;
    }
  }

  &__create-volume-action {
    &-slider-container {
      background: var(--color-cod-gray);
      display: none;
      height: 100px;
      justify-content: center;
      left: 56px;
      padding-bottom: 10px;
      position: absolute;
      top: 40px;
      width: 24px;

      &--open {
        display: flex;
      }
    }

    &-slider {
      background: var(--color-cod-gray);
    }
  }
}
