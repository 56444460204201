.empty-state {
  --hover-color: var(--color-main-dynamic);
  --animation-state: paused;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: var(--top-padding);
    max-width: 298px;
    margin: calc(var(--top-padding) * 4) 0;
  }

  &__has-background {
    background-color: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-500);
  }

  &__circle-color {
    &--dynamic {
      background-color: var(--color-main-dynamic);
    }

    &--white {
      background-color: var(--color-gin);
    }
  }

  @keyframes bounce-animation {
    0% {
      transform: scale(1);
    }

    4% {
      transform: scale(1.15);
    }

    8% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes breathe-animation {
    50% {
      transform: scale(1.15);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes fadein-animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__circles-container {
    display: flex;
    height: 72px;
    width: 72px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: bounce-animation 10s 1s infinite, fadein-animation .5s .2s forwards;

    @media (hover: hover) {
      &:hover {
        --animation-state: playing;
      }
    }

    &--has-action {
      cursor: pointer;
    }
  }

  &__circle {
    position: absolute;
    display: flex;
    border-radius: 50%;
    animation: breathe-animation 1600ms linear infinite;
    animation-play-state: var(--animation-state);
  }

  &__circle-1 {
    height: 72px;
    width: 72px;
    opacity: .1;
    animation-delay: 0ms;
  }

  &__circle-2 {
    height: 56px;
    width: 56px;
    opacity: .15;
    animation-delay: 400ms;
  }

  &__circle-3 {
    height: 40px;
    width: 40px;
    opacity: .2;
    animation-delay: 800ms;
  }

  &__title {
    font: 500 1.8rem var(--font-gerstner);
    letter-spacing: .5px;
    color: var(--color-gin);
    text-align: center;
    opacity: 0;
    animation: fadein-animation .3s .2s forwards;
    animation-delay: 200ms;
  }

  &__subtitle {
    font: 500 1.5rem var(--font-gerstner);
    letter-spacing: .25px;
    color: var(--color-boulder);
    text-align: center;
    opacity: 0;
    animation: fadein-animation .3s .2s forwards;
    animation-delay: 200ms;
  }

  &__action-container {
    display: flex;
    align-items: center;
    justify-items: center;
    opacity: 0;
    animation: fadein-animation .3s .2s forwards;
    animation-delay: 200ms;
    cursor: pointer;

    &--plus-icon {
      > button > svg > path {
        transition: fill .2s ease-in-out;
        fill: var(--hover-color) !important;
      }
    }

    &--chevron-right-icon {
      > button > svg > path {
        transition: stroke .2s ease-in-out;
        stroke: var(--hover-color) !important;
      }
    }

    @media (hover: hover) {
      &:hover {
        --hover-color: var(--color-gin);
      }
    }
  }

  &__action-label {
    font: 500 1.4rem var(--font-gerstner);
    letter-spacing: .25px;
    color: var(--hover-color);
    transition: color .2s ease-in-out;
    text-align: center;
  }
}
