.loading-ellipsis {
  display: flex;
  gap: 3px;

  @keyframes fade-in {
    0% {
      background-color: var(--color-mine-shaft);
    }

    100% {
      background-color: var(--color-gray-200);
    }
  }

  &__circle {
    height: 3px;
    background-color: var(--color-mine-shaft);
    width: 3px;
    border-radius: 50%;
    animation: fade-in 900ms infinite alternate;
    transition: all .2s;
  }

  &__circle-2 {
    animation-delay: .3s;
  }

  &__circle-3 {
    animation-delay: .6s;
  }
}
