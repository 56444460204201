.application-header {
  margin: 4rem 0;

  &--logo {
    border-radius: .8rem;
  }

  &--title {
    font: 500 3.2rem var(--font-gerstner);
    color: var(--color-gin);
    margin-top: 1rem;
  }

  &--subtitle {
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-gray-200);
  }
}
