.artist-featured-in {
  margin: 16px 16px 0;

  @media (--from-tablet-landscape) {
    margin: 16px 0;
  }

  &__title {
    @media (--from-tablet-landscape) {
      margin-bottom: 16px;
    }
  }
}
