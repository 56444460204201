.filters {
  align-items: center;
  display: flex;

  :global(.filter) {
    background: var(--color-cod-gray-100);
    border-bottom: 1px solid var(--color-cod-gray-400);
    padding: 16px;

    &__reset {
      color: var(--color-gray-200);
      font: 300 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .4px;
    }

    &__view-more {
      color: var(--color-main-dynamic);
      font: 500 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .35px;
      margin-top: 18px;
      margin-bottom: 20px;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      align-items: center;
      display: flex;
      cursor: pointer;
    }

    &__chevron {
      height: 24px;
      width: 24px;
      transform: rotate(180deg);

      &--expanded {
        transform: rotate(0);
      }
    }

    &__title {
      color: var(--color-gin);
      font: 400 1.6rem/2.4rem var(--font-gerstner);
      letter-spacing: .15px;
      margin-right: auto;
      padding-right: 16px;

      &-active {
        color: var(--color-main-dynamic);
      }
    }

    :global(.ReactCollapse--content) {
      padding-top: 16px;
    }

    :global(.ReactCollapse--collapse) {
      transition: height 300ms;
    }
  }

  &__parenth {
    font-size: 13px;
  }

  &__right-aligned {
    margin-left: auto;
    margin-right: var(--window-padding);
  }

  &__filter-button {
    height: 38px;
    z-index: 30;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(20px + var(--header-height));

    @media (hover: hover) {
      &:hover i {
        background-color: var(--color-gin);
      }
    }

    &--search {
      background: none;
      border-radius: none;
      position: absolute;
      right: 42px;
      top: calc(50% - 12px);
    }
  }

  &__open-counter {
    color: var(--color-main-dynamic);
    font-style: normal;
    letter-spacing: .5px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.4rem;
    font-family: var(--font-gerstner);
    display: flex;
    margin-right: 8px;

    &--hidden {
      display: none;
    }
  }

  &--open &__filter-button {
    display: none;
  }

  &__open-btn {
    /* display: none; */
    stroke: var(--color-create);

    &--show-on-mobile {
      display: block;
    }

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__container {
    background: var(--color-cod-gray-100);
    bottom: 58px;
    display: flex;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 72px;
    transform: translateX(100%);
    transition: transform .2s ease-in;
    z-index: 40;

    .filters--open & {
      transform: translateX(0);
      transition: transform .2s ease-out;
    }

    @media (--from-tablet-landscape) {
      border-left: 1px solid var(--color-cod-gray-400);
      bottom: 0;
      left: initial;
      right: 0;
      width: var(--width-filters-open);
    }
  }

  &__wrapper {
    flex: 1 1 auto;
    overflow: auto;
  }

  &__header {
    background: var(--color-cod-gray-500);
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 12px 56px 12px 16px;
    position: relative;
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0093em;
    margin-bottom: 0;
    cursor: pointer;

    &-container {
      @media (hover: hover) {
        &:hover {
          ~ button svg path {
            fill: var(--color-gin);
          }
        }
      }
    }
  }

  &__reset {
    margin-left: auto;
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-main-dynamic);
  }

  &__close {
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
  }
}
