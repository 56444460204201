.accordion-count-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__track-count {
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
  }

  &__accordion-button {
    color: inherit;
    height: 24px;

    &--open > svg {
      transform: rotate(90deg);
    }
  }

  &__count-button-container {
    display: block;
  }

  @media (--from-tablet-landscape) and (hover: hover) {
    &__track-count {
      display: flex;

      &--hidden,
      :global(.track-list-item:hover) & {
        display: none;
      }
    }

    &__count-button-container {
      display: none;

      &--shown,
      :global(.track-list-item:hover) & {
        display: block;
      }
    }
  }
}
