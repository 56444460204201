.predictive-search {
  &__container {
    background: var(--color-cod-gray-100);
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
    max-width: 100%;
    padding: 0 var(--left-padding) var(--left-padding);
    position: absolute;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    transition: transform .12s;
    transform: scaleY(0);
    transform-origin: top;

    &--open {
      padding: 0 var(--left-padding) var(--left-padding);
      transform: scaleY(1);
      opacity: 1;
    }

    @media (--tablet) {
      left: 0;
      right: 0;
      top: calc(1px + var(--header-height));
      height: 100%;
      position: fixed;
    }

    @media (--from-tablet-landscape) {
      width: 48rem;
      max-height: 70rem;
      margin-top: calc(-1px + var(--top-padding));
      border: 1px solid var(--color-cod-gray-400);
    }
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__container::-webkit-scrollbar {
    display: none;
  }

  &__divider {
    height: 1px;
    background-color: var(--color-cod-gray-400);
    margin-top: var(--left-padding);
    margin-left: calc(-1 * var(--left-padding));
    margin-right: calc(-1 * var(--left-padding));
  }

  &__checkboxes-container {
    height: 78px;
    background-color: var(--color-cod-gray-300);
    border-bottom: 1px solid var(--color-cod-gray-400);
    margin-left: calc(-1 * var(--left-padding));
    margin-right: calc(-1 * var(--left-padding));
    padding: var(--left-padding);
  }

  &__search-text {
    color: var(--color-tundora);
    font: 500 1.1rem var(--font-gerstner);
    padding-bottom: 8px;
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.6rem var(--font-gerstner);
  }

  &__row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--top-padding) 0 calc(var(--top-padding) / 2);
  }

  &__thin-text {
    color: var(--color-gray-200);
    font: 300 1.2rem var(--font-gerstner);
    letter-spacing: .05em;

    &:hover {
      text-decoration: underline;
    }
  }

  &__list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
    margin-left: calc(-1 * var(--left-padding));
    margin-right: calc(-1 * var(--left-padding));
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1;

    &--double {
      align-items: center;
      flex-direction: row;
      text-align: start;
    }

    &-artist {
      width: 100%;
      flex-direction: row;
      align-items: center;
      background: var(--color-cod-gray-100);
      padding: 8px 0;
      gap: 1rem;

      &:hover {
        background-color: var(--color-cod-gray-400);
      }
    }

    svg {
      display: none;

      path {
        fill: var(--color-gin);
      }
    }

    @media (--from-tablet-landscape) {
      &:hover {
        svg {
          display: initial;
        }
      }
    }
  }

  &__content-title {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .035em;

    &-empty {
      margin-left: var(--left-padding);
    }
  }

  &__content-label {
    color: var(--color-gin);
    font: 300 1.2rem/1.4rem var(--font-gerstner);
    letter-spacing: .04em;
  }

  &__img-container {
    height: 56px;
    width: 56px;
    margin-left: var(--left-padding);
    position: relative;
  }

  &__image {
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &-round {
      border-radius: 100%;
    }

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--item-play-overlay {
    align-items: center;
    background-color: rgba(var(--color-black-russian-rgb) / 50%);
    display: flex;
    position: absolute;
    inset: 0;
    justify-content: center;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;
    opacity: 0;

    &-round {
      border-radius: 100%;
    }

    > svg {
      width: 50%;
      background-color: var(--color-black);
      height: 50%;
      border-radius: 50px;

      > path {
        fill: var(--color-gin);
      }
    }
  }

  @media (hover: hover) {
    &__img-container:hover &--item-play-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}
