.card-grid {
  display: grid;
  gap: 16px;
  margin-block-end: 32px;
  white-space: nowrap;

  &--artist-card {
    grid-template-columns: repeat(auto-fill, 192px);

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 192px);
    }
  }

  &--card-small {
    grid-template-columns: repeat(auto-fill, 136px);

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 192px);
    }
  }

  &--card-label {
    grid-template-columns: repeat(auto-fill, 164px);
  }

  &--card-medium {
    grid-template-columns: repeat(auto-fill, 180px);

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 264px);
    }
  }

  &--card-large {
    grid-template-columns: repeat(auto-fill, 180px);

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 296px);
    }
  }
}
