.slider {
  padding-top: 32px;
  margin: 0 8px;

  &__ranges {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
  }

  &__range {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  :global .rc-slider {
    border-radius: 0;
    height: 16px;
    padding: 6px 0;
  }

  :global .rc-slider-rail {
    background: var(--color-gray-200);
    border-radius: 0;
  }

  :global .rc-slider-track {
    background: var(--color-main-dynamic);
    border-radius: 0;
  }

  :global .rc-slider-handle {
    background: var(--color-gin);
    border: 0;
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }

  :global .rc-slider-handle-dragging {
    border: none !important;
    box-shadow: none !important;
  }

  :global .rc-slider-tooltip {
    display: none;
  }

  :global .rc-slider-tooltip-arrow {
    display: none;
  }

  :global .rc-slider-tooltip-inner {
    background: none;
    border-radius: 0;
    box-shadow: none;
    color: var(--color-gin);
    font: 400 1.4rem/2rem var(--font-gerstner);
    height: 20px;
    letter-spacing: .25px;
    min-width: 24px;
    text-align: center;
    text-decoration: none;
  }
}
