.list-grid-item {
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  display: flex;
  text-decoration: none;

  &__img-wrapper {
    align-items: center;
    background: var(--color-tundora);
    display: flex;
    flex: 0 0 auto;
    height: 56px;
    justify-content: center;
    width: 56px;

    &_rounded {
      border-radius: 100%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__img {
    display: block;
  }

  &__name-initials {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 3.2rem/4rem var(--font-gerstner);
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media (--tablet) {
      font: 500 2.2rem/3.2rem var(--font-gerstner);
    }
  }

  &__details {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    min-width: 0;
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.3rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    width: 100%;
  }

  &__subtitle {
    color: var(--color-gin);
    font: 300 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    width: 100%;
  }

  &__menu {
    color: var(--color-gray-200);
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
  }
}
