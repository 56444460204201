.secondary-page-title {
  align-items: center;
  color: var(--color-gin);
  display: flex;

  &__counter {
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: -.005em;
  }
}
