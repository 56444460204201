.no-results-block {
  background-color: var(--color-cod-gray-500);
  color: var(--color-gray-200);
  font: 500 1.8rem/2.4rem var(--font-gerstner);
  padding: 28px;
  text-align: center;

  &--with-margin {
    margin-left: var(--window-padding);
    margin-right: var(--window-padding);
  }
}
