/* Customize the label (the container) */
.checkbox {
  cursor: pointer;
  display: block;
  height: 24px;
  position: relative;
  width: 24px;

  /* Hide the browser's default checkbox */
  > input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  /* Create a custom checkbox */
  &__checkmark {
    border: 2px solid var(--color-gray-200);
    border-radius: 2px;
    height: 14px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 14px;

    /* Create the checkmark/indicator (hidden when not checked) */
    > svg {
      display: none;
      position: absolute;
    }
  }

  @media (hover: hover) {
    /* On hover, add a background color */
    &:hover > input:not(:disabled) ~ &__checkmark {
      background-color: var(--color-main-dynamic);
      border: none;
    }
  }

  input[data-focus-visible-added] ~ &__checkmark {
    border-color: var(--color-gin);
  }

  input:disabled ~ &__checkmark {
    cursor: not-allowed;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ &__checkmark {
    background-color: var(--color-main-dynamic);
    border: none;

    /* Show the checkmark when checked */
    > svg {
      display: block;
      fill: var(--color-text-dynamic);
      left: 2px;
      top: 3px;
    }
  }
}
