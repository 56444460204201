.tabs-input {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__label {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    color: var(--color-gin);
  }

  &__options-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2px;
    background-color: var(--color-tundora);
    width: 100%;
    justify-content: space-evenly;
  }

  &__option-label {
    color: var(--color-gin);
    background-color: transparent;
    transition: color, background-color .2s;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 5px 0;
  }

  &__input {
    display: none;
  }

  &__input:checked + &__option-label {
    background-color: var(--color-gin);
    color: var(--color-black);
  }
}
