.card {
  display: inline-block;
  position: relative;
  text-align: start;
  text-decoration: none;
  border-radius: 4px;

  &--small {
    width: 136px;
  }

  &--label {
    width: 164px;
  }

  &--medium {
    width: 180px;
  }

  &--large {
    width: 180px;
  }

  &--large-square {
    width: 180px;
  }

  &--user-playlist {
    max-width: 163px;
    width: 100%;
  }

  &--qc {
    border: 1px dotted red;
  }

  &--ghost {
    background-color: var(--color-cod-gray-500);

    @media (--tablet) {
      background-color: transparent;
    }
  }

  &--custom-preset-full-width-mobile {
    @media (--tablet) {
      background-color: var(--color-cod-gray-500);
      max-width: calc(100vw - 32px);
      padding: 16px;
      width: 100%;

      .card__img-container:not(.card__img-container--no-img) {
        height: auto !important;
      }
    }
  }

  @media (--from-tablet-landscape) {
    background-color: var(--color-cod-gray-500);
    padding: 16px;
    transition: background-color .2s;

    &--small {
      width: 192px;
    }

    &--user-playlist {
      max-width: none;
      width: 192px;
    }

    &--medium {
      width: 264px;
    }

    &--large {
      width: 296px;
    }

    &--large-square {
      width: 296px;
    }
  }

  @media (hover: hover) {
    &--plain-link:hover {
      background-color: var(--color-cod-gray-400);
    }

    /* Note: overlay available is meant to show the overlay in the create card */
    &--overlay-available:hover,
    &--playable:hover {
      background-color: var(--color-cod-gray-400);

      .card__img-overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--is-full-height {
    height: 100%;
  }

  &__img-container {
    background-color: var(--color-mine-shaft);
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 2px;

    &--round-image {
      background-color: black;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 50%;
    }

    .card--small & {
      height: 136px;
    }

    .card--label & {
      height: 107px;
    }

    .card--medium & {
      height: 112px;

      > img {
        /* TODO: potentially revisit these rules once we've defined
        the sizes of image sources. At the moment, the media images
        are square, but the layout expects a panoramic ratio (about 29:18). */
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .card--large & {
      height: 112px;

      > img {
        /* TODO: potentially revisit these rules once we've defined
        the sizes of image sources. At the moment, the media images
        are square, but the layout expects a panoramic ratio (about 29:18). */
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .card--medium-square & {
      height: 180px;
    }

    .card--large-square & {
      height: 180px;
    }

    .card--user-playlist & {
      height: 163px;
    }

    @media (--from-tablet-landscape) {
      .card--small & {
        height: 160px;
      }

      .card--medium & {
        height: 144px;

        > img {
          /* TODO: potentially revisit these rules once we've defined
          the sizes of image sources. At the moment, the media images
          are square, but the layout expects a panoramic ratio (about 29:18). */
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .card--large & {
        height: 174px;

        > img {
          /* TODO: potentially revisit these rules once we've defined
          the sizes of image sources. At the moment, the media images
          are square, but the layout expects a panoramic ratio (about 29:18). */
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .card--large-square & {
        height: 264px;

        > img {
          /* TODO: potentially revisit these rules once we've defined
          the sizes of image sources. At the moment, the media images
          are square, but the layout expects a panoramic ratio (about 29:18). */
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .card--user-playlist & {
        height: 160px;
      }
    }
  }

  &__img-overlay {
    background-color: rgb(var(--color-black-russian-rgb) / 50%);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;

    &--open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__label {
    background-color: var(--color-cod-gray);
    border: 1px solid var(--color-gin);
    bottom: 0;
    color: var(--color-gin);
    font: .8rem/1rem var(--font-gerstner);
    left: 0;
    letter-spacing: .05em;
    max-width: 90%;
    overflow-x: hidden;
    padding: 3px;
    position: absolute;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__title-container {
    display: flex;
    font: 300 1.2rem var(--font-gerstner);
    letter-spacing: .4px;
    color: var(--color-gin);
  }

  &__text-container {
    color: var(--color-gin);
    padding-top: 16px;
  }

  &__title {
    color: inherit;
    display: block;
    font: 1.3rem/2rem var(--font-gerstner);
    letter-spacing: .02em;
    max-width: 100%;
    max-height: 2rem;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;

    &-top {
      z-index: 10;
    }

    @media (--from-tablet-landscape) {
      font-size: 1.4rem;
      letter-spacing: .018em;
    }

    &--create {
      /* make the entire card look like a link (and not just the actual link).
      When passing in more links as props, make sure they appear on top of this
      pseudo-element (applying position: relative or z-index on the extra links
      seems to be working fine).
      Taken from: https://css-tricks.com/nested-links/ */
      &::after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &[data-focus-visible-added] {
        outline: none;

        &::after {
          border: 1px solid var(--color-dodger-blue);
        }
      }
    }
  }

  &__create-description {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
  }

  &__single-line-description {
    font: 500 1.2rem var(--font-gerstner) !important;
    color: var(--color-gray-200) !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > div {
      font: 500 1.2rem var(--font-gerstner) !important;
      color: var(--color-gray-200) !important;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /* If it's a "play-only" card (e.g. for tracks), do not make the entire card clickable on
  desktop devices (unless a user is using keyboard navigation). */
  @media (hover: hover) {
    &__title--play-only:not([data-focus-visible-added])::after {
      display: none;
    }
  }

  &__play-btn {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    > svg {
      height: 48px;
      width: 48px;
      background-color: var(--color-black);
      border-radius: 50px;
    }

    > svg > path {
      fill: var(--color-gin);
    }
  }

  &__follow-button-container {
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0;
    border-radius: 50%;
    margin-top: -6px;
    margin-bottom: -2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--visible {
      opacity: 1;
    }
  }

  &__download-more-dots-container {
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-top: -6px;
    margin-bottom: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;

    &--visible {
      opacity: 1;
    }

    > svg > path {
      fill: var(--color-gray-200) !important;
      transition: all .2s;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-tundora);
        opacity: 1;

        > svg > path {
          fill: var(--color-gin) !important;
        }
      }
    }

    &:active:not(&--active) {
      background-color: var(--color-cod-gray-500);
      opacity: 1;

      > svg > path {
        opacity: .7;
      }
    }
  }

  &__favorite-heart,
  &__more-dots-container {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 0;
  }

  &__favorite-heart {
    left: 0;
    z-index: 1;

    > svg > path {
      stroke: var(--color-gin);
    }

    &--unfavorite > svg > path {
      fill: var(--color-gin);
    }
  }

  &__more-dots-container {
    right: 0;
    z-index: 2;
  }

  &__more-dots-toggler {
    color: var(--color-gin);

    > svg {
      height: 100%;
      width: 100%;
    }

    &-small {
      >svg {
        height: 20px;
        width: 20px;
      }
    }

    > svg > path {
      fill: currentColor;
    }
  }

  &__download-icon {
    >svg {
      height: 16px;
      width: 16px;
    }
  }

  &__list {
    background-color: var(--color-cod-gray-300);
    display: none;
    position: absolute;
    width: 200px;

    &--open {
      display: block;
      right: 100%;
    }

    &--left {
      right: 100%;
    }

    &--right {
      left: 100%;
    }

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }

  &__ghost-img {
    height: 100%;
    width: 100%;
    background-color: var(--color-cod-gray-400) !important;
  }

  &__ghost-text {
    margin: -32px 0 -16px;
    padding-top: 16px;

    @media (--from-tablet-landscape) {
      margin: -16px -16px -32px;
    }
  }

  &__badge-container {
    position: relative;
    left: 4px;
  }

  &__badge {
    position: absolute;
    bottom: 4px;
    transition: opacity .2s;

    &--hidden {
      opacity: 0;
    }
  }
}
