.genres-detail {
  &__title {
    padding-bottom: var(--top-padding);
  }

  &__icon {
    padding-bottom: var(--top-padding);
  }

  &__divider {
    width: 100%;
    height: 1px;
    display: flex;
    background: var(--color-cod-gray-500);
    margin: calc(var(--top-padding) * 2) 0;

    &--no-margin {
      margin-top: 0;
    }
  }

  &__mobile-padding {
    margin-top: 6px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__horizontal-tabs-container {
    margin-top: 16px;
  }

  &__mobile-track-list {
    margin-top: 8px;
  }
}
