.header-notification-button {
  &__notifications-active {
    height: 40px;
    width: 40px;
    position: absolute;

    &--circle {
      top: 12px;
      right: 12px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: var(--color-main-dynamic);
      position: absolute;
      border: 1px solid var(--color-cod-gray);
    }
  }

  &__notifications {
    display: none;
    margin-inline-end: 16px;

    @media (--from-tablet-landscape) {
      display: flex;
    }
  }
}
