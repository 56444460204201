.breakpoint-view {
  &__desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: initial;
    }
  }

  &__mobile {
    display: initial;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }
}
