.primary-page-title {
  color: var(--color-gin);
  display: flex;
  gap: 1rem;

  &--tooltip {
    opacity: 1;
    transition: opacity .2s;

    > svg > path {
      fill: var(--color-tundora);
    }
  }
}
