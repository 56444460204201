@font-face {
  font-display: swap;
  font-family: 'Gerstner Programm FSL';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/GerstnerProgrammFSLWeb-Light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Gerstner Programm FSL';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/GerstnerProgrammFSLWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Gerstner Programm FSL';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/GerstnerProgrammFSLWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Gerstner Programm FSL';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/GerstnerProgrammFSLWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'ABCGravity ExtraCondensed';
  font-style: normal;
  src: url('../assets/fonts/ABCGravity-ExtraCondensed.otf') format('opentype');
}

:root {
  --font-gerstner: 'Gerstner Programm FSL', 'Helvetica', 'Arial', sans-serif;
  --font-abcgravity: 'ABCGravity ExtraCondensed', 'Helvetica', 'Arial', sans-serif;
}
