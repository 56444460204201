.header-profile-button {
  &__profile-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__profile-img-wrapper {
    width: 32px;
    height: 32px;
    align-items: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    > svg > circle {
      display: none;
    }
  }

  &__mobile-profile-btn {
    font: 500 1.3rem var(--font-gerstner);
    color: var(--color-main-dynamic);

    @media (--from-tablet-landscape) {
      display: none;
    }

    > svg > circle {
      display: none;
    }

    &--notification-active {
      > svg > circle {
        display: block;
      }
    }
  }
}
