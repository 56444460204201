.artist-detail-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  margin: 0;
  margin-top: -2rem;
  margin-bottom: 2rem;

  @media (--from-tablet-landscape) {
    flex-direction: row;
    gap: 4rem;
    margin: 0;
    margin-top: -2rem;
    margin-bottom: 1rem;
  }

  &--bio-container {
    flex: 1;

    @media (--from-tablet-landscape) {
      max-width: 48rem;
    }
  }

  &--links-container {
    min-width: 22rem;
    margin-right: auto;

    @media (--from-tablet-landscape) {
      margin-left: 4rem;
    }
  }

  &--link-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  &--link-icon {
    border-radius: .4rem;
    height: 2.4rem;
    width: 2.4rem;
  }

  &--link-img {
    svg > g > path {
      fill: var(--color-gray-200);

      @media (hover: hover) {
        &:hover {
          fill: var(--color-gin);
        }
      }
    }
  }

  &--link-data {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gray-200);
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: color .5s;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }
}
