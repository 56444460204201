.search-artists {
  &__mobile-loading-item {
    margin-bottom: 0 !important;

    &-round {
      border-radius: 100%;
    }
  }

  &__loading {
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;

    &-image {
      background-color: var(--color-cod-gray-500);
    }

    &-item {
      height: 264px;
      min-width: 192px;
      width: 192px;

      &-round {
        border-radius: 100%;
        background-color: var(--color-cod-gray-400);
      }
    }
  }

  &__intro-section {
    display: grid;
    grid-template-columns: 1fr;
    margin: 16px;
    row-gap: 16px;

    @media (--from-tablet-landscape) {
      margin: 16px 16px 16px 0;
    }
  }
}
