.account-payments {
  color: var(--color-gin);

  &__desktop-section-title {
    @media (--tablet) {
      display: none;
    }
  }

  &__content {
    @media (--from-tablet-landscape) {
      margin-top: 32px;
    }
  }

  &__collapse-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;

    &--button {
      color: var(--color-white);
    }
  }

  &__plans-container {
    margin-bottom: 32px;
  }
}
