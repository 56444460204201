.trending-media {
  &__intro {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: space-between;
  }

  &__sub-intro {
    display: flex;
    gap: var(--dropdown-gap);
    align-items: center;
    padding-top: var(--top-padding);
    padding-bottom: var(--top-padding);
  }

  &__title {
    color: var(--color-gin);
    flex: 1 auto;
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 0;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__filters {
    flex: 0 auto;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__subtitle {
    color: var(--color-gin);
    display: flex;
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;

    @media (--from-tablet-landscape) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__dropdown-ghost {
    height: 40px;
    margin-top: var(--top-padding);
    width: calc(192px * 2 + var(--dropdown-gap));
    margin-left: var(--window-padding);
    background-color: var(--color-cod-gray-400);
  }
}
