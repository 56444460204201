.anonymous-user-banner {
  display: flex;
  background-color: var(--color-main-dynamic);
  justify-content: space-between;
  padding: var(--left-padding);
  flex-direction: column;

  @media (--from-tablet-landscape) {
    flex-direction: row;
  }

  &--mobile-variant {
    padding: 8px;
    border-radius: 4px;
    flex-direction: row;
    gap: 16px;
    cursor: pointer;
  }

  &__text-container-mobile-variant {
    flex: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      max-height: 311px;
    }

    > span {
      font: 500 1.8rem var(--font-gerstner);
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      max-height: 311px;
    }

    > button {
      display: block;
      width: 130px;
      background-color: var(--color-black-russian);
      color: var(--color-gin);
      margin: var(--top-padding) 0;

      @media (--from-tablet-landscape) {
        margin: 0;
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-mine-shaft);
        }
      }
    }

    > span {
      font: 500 2.4rem var(--font-gerstner);
      color: var(--color-black-russian);
      max-width: 212px;

      @media (--from-tablet-landscape) {
        font: 500 3.2rem var(--font-gerstner);
        max-width: 283px;
      }
    }
  }
}
