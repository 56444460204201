.download-accordion {
  &__container {
    overflow: hidden;
  }

  &__item {
    --first-column-offset: 24px;
    display: grid;
    align-items: center;
    padding: 8px 0;
    grid-row-gap: 16px;
    color: var(--color-gray-200);
    font: 400 1.4rem/1.6rem var(--font-gerstner);
    letter-spacing: .033em;
    text-align: left;
    transition: background-color .2s, color .2s;
    background-color: var(--color-cod-gray-300);
    grid-template-columns: [accordion] var(--first-column-offset) [title] 1fr [actions] 24px;

    @media (--from-tablet) {
      --first-column-offset: 32px;
      padding-inline: 8px;
    }

    @media (--from-tablet) {
      grid-template-columns:
        [accordion] var(--first-column-offset) [title] minmax(230px, 1fr)
        [bpm] 68px [key] 69px [genre] 144px [download] 40px [actions] minmax(40px, auto);
    }

    @media (--from-tablet-landscape) {
      grid-template-columns:
        [accordion] var(--first-column-offset) [title] 1fr
        [bpm] 55px [key] 69px [genre] 120px [download] 170px [actions] 128px;
    }

    @media (min-width: 1360px) {
      padding: 8px;
      grid-template-columns:
        [count] var(--first-column-offset) [title] minmax(auto, 1fr)
        [bpm] 69px [key] 69px [genre] 144px [download] 180px [added] 120px [actions] 128px;
    }

    @media (--from-desktop) {
      padding: 8px;
      grid-template-columns:
        [count] var(--first-column-offset) [title] minmax(auto, 1fr)
        [bpm] 69px [key] 69px [genre] 144px [download] 400px [actions] 128px;
    }

    @media (--from-large-desktop) {
      grid-template-columns:
        [count] var(--first-column-offset) [title] 1fr
        [bpm] 69px [key] 69px [genre] 144px [download] 340px [added] 120px [actions] 128px;
    }

    &--wide-offset {
      --first-column-offset: 52px;
    }

    &--download-trending,
    &--stream-trending {
      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] var(--first-column-offset) [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 200px [actions] 128px;
      }

      @media (min-width: 1360px) {
        padding: 8px;
        grid-template-columns:
          [count] var(--first-column-offset) [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 180px [added] 120px [actions] 128px;
      }

      @media (--from-desktop) {
        padding: 8px;
        grid-template-columns:
          [count] var(--first-column-offset) [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 300px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] var(--first-column-offset) [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [added] 120px [actions] 128px;
      }
    }

    &--maxi-player {
      background-color: #e6efe914;

      @media (--from-tablet-landscape) and (hover: none) {
        grid-template-columns:
          [accordion] 32px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [actions] 128px;
      }

      @media (--from-tablet-landscape) and (hover: hover) {
        grid-template-columns:
          [accordion] 32px [title] minmax(230px, 1fr)
          [bpm] 55px [key] 69px [genre] 120px [download] 170px [actions] 128px;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 32px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 280px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [actions] minmax(128px, 170px);
      }
    }
  }

  &__column {
    &--title {
      display: flex;
      margin-right: 16px;
    }

    &--bpm,
    &--key,
    &--genre {
      display: none;

      @media (--from-tablet) {
        display: block;
      }
    }

    &--versions-content {
      display: none;

      @media (--from-tablet) and (hover: hover) {
        align-items: center;
        display: flex;
        gap: 8px;
      }
    }

    &--actions {
      display: flex;
      align-items: center;
      grid-column-start: -2;
      gap: 4px;
      transition: opacity .2s ease-out;
      justify-content: flex-end;

      @media (--from-tablet-landscape) and (hover: hover) {
        display: none;
      }

      @media (--from-desktop) {
        justify-content: center;
      }

      &--desktop-visible {
        display: flex;
      }
    }
  }

  &__download-btn {
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    box-sizing: content-box;
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    height: 20px;
    letter-spacing: .75px;
    text-align: center;
    transition: border .2s, color .2s;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__downloaded {
      background-color: var(--color-tundora);
      border-color: var(--color-gray-200);
      color: var(--color-gray-200);
    }

    &-text {
      display: none;

      @media (--from-tablet-landscape) {
        display: inline !important;
      }
    }

    @media (hover: hover) {
      &:not([disabled])&:hover {
        border-color: var(--color-gin);
        background-color: var(--color-white);
        color: var(--color-black);

        path {
          fill: var(--color-black);
        }
      }
    }

    &[data-focus-visible-added] {
      border-color: var(--color-gin);
      color: var(--color-gin);
      outline: none;
    }

    &[disabled] {
      opacity: .25;
    }
  }

  &__action-btn {
    display: none;
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;

    @media (--from-tablet-landscape) {
      display: flex;
    }

    > svg {
      transition: fill .2s;
    }

    > svg > g > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg {
        fill: var(--color-gin);
      }

      &:hover > svg > g > path {
        fill: var(--color-gin);
      }
    }

    &--desktop-only {
      display: none;

      @media (--from-tablet-landscape) {
        display: flex;
      }
    }

    &--three-dots {
      display: flex;

      &:hover {
        path {
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__action-btn-desktop-track-is-in-list {
    > svg path {
      transition: fill .2s;
      fill: var(--color-main-dynamic) !important;
    }
  }

  &__item:hover &__column--actions {
    display: flex;
  }
}
