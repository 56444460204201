.account-downloads {
  &__top-section {
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }
  }

  &__desktop-section-title {
    display: none;

    @media (--from-tablet-landscape) {
      color: var(--color-gin);
      display: block;
    }
  }

  &__sort-btn {
    display: none;
  }
}
