.mobile-drawer {
  --drag-handle-height: 36px;
  --bottom-spacing: 16px;
  background: var(--color-cod-gray-100);
  bottom: 0;
  height: 356px;
  left: 0;
  max-height: 100%;
  padding: var(--drag-handle-height) 0 var(--bottom-spacing);
  position: fixed;
  right: 0;
  transform: translateY(100%);
  transition: transform .2s;
  width: 100%;
  z-index: 200;

  &__drag-handle {
    height: var(--drag-handle-height);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &::after {
      background: var(--color-cod-gray-400);
      border-radius: 100px;
      content: '';
      display: block;
      height: 4px;
      left: 50%;
      position: absolute;
      top: 16px;
      transform: translateX(-32px);
      width: 64px;
    }
  }

  &--open {
    transform: translateY(0);
  }

  &__wrapper {
    max-height: 100%;
    overflow: auto;
  }

  &--dynamic {
    height: auto;
    max-height: none;

    .mobile-drawer__wrapper {
      max-height: calc(75vh - var(--drag-handle-height) - var(--bottom-spacing));
      /* stylelint-disable-next-line unit-no-unknown */
      max-height: calc(75dvh - var(--drag-handle-height) - var(--bottom-spacing));
    }
  }
}

.mobile-drawer-shadow {
  background: rgb(var(--color-black-russian-rgb) / 60%);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .2s, visibility .2s, backdrop-filter .2s;
  visibility: hidden;
  width: 100%;
  z-index: 199;

  &--visible {
    backdrop-filter: blur(24px);
    opacity: 1;
    visibility: visible;
  }
}
