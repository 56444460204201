.pin-modal {
  align-items: center;
  display: flex;
  justify-content: center;

  &__modal-main {
    background: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-200);
    max-width: 608px;
    top: 75%;
    width: 100%;
  }

  &__container {
    margin: 32px;
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > h3 {
      color: var(--color-gin);
    }
  }

  &__content {
    margin-top: 16px;

    > span {
      color: var(--color-gin);
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      letter-spacing: .15px;
    }

    > button:disabled {
      opacity: .7;
    }
  }

  &__input-section {
    display: flex;
    margin: 16px 0;
  }

  &__button {
    background-color: var(--color-gin);
    border: 1px solid var(--color-gin);
    color: var(--color-cod-gray);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    height: 40px;
    letter-spacing: .35px;
    padding: 8px 12px;
    text-align: center;
    width: auto;

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-gray);
        border-color: var(--color-gray);
      }
    }

    svg {
      margin-right: 14px;

      path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--inactive {
      opacity: .6;
    }
  }

  &__button-icon {
    > svg > path {
      stroke: var(--color-gin);
      transition: stroke .2s;
    }

    &--active {
      > svg > path {
        stroke: var(--color-gray-200);
        transition: stroke .2s;
      }
    }
  }

  &__button-cancel {
    background: transparent;
    border: 1px solid var(--color-gray-200);
    color: var(--color-gray-200);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-left: 16px;
    padding: 8px 12px;
    text-align: center;
    transition: color .2s;

    @media (hover: hover) {
      &:not([disabled]):hover {
        border: 1px solid var(--color-gin);
        color: var(--color-gin);
      }
    }
  }

  &__button-forgot {
    background: transparent;
    color: var(--color-gin);
    display: block;
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-bottom: 32px;
    transition: color .2s;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gray-200);
      }
    }
  }
}
