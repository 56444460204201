.my-playlist-detail {
  &__title-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    > h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__collab-modal {
    font: 500 12px var(--font-gerstner);
    letter-spacing: .4px;
    color: var(--color-cod-gray);
  }

  &__copy-modal {
    min-height: 600px;
  }

  &__copy-dropdown {
    height: 40px;
    background-color: var(--color-cod-gray-300);
    margin-bottom: 16px;

    &--options {
      width: 100%;
      overflow: scroll;
      max-height: 260px;
    }

    button {
      padding-left: 8px;

      &::after {
        right: 8px;
      }
    }
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;
    display: flex;
    align-items: center;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    > *:last-child::before {
      color: inherit;
      content: ' / ';
    }
  }

  &__description {
    color: var(--color-gin);
    font: 1.6rem/2rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (--from-tablet-landscape) {
      line-height: 2.4rem;
    }
  }

  &__play-btn {
    > svg > path {
      fill: var(--color-main-dynamic);
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-hover-dynamic);
      }
    }
  }

  &__add-to-queue-btn {
    @media (hover: hover) {
      &:hover > svg {
        fill: var(--color-gin);
      }
    }
  }

  &__play-btn,
  &__add-to-queue-btn {
    > svg {
      transition: fill .2s;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: var(--half-padding);
  }
}
