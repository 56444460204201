.compare-plans {
  color: var(--color-gin);

  &__library-name {
    margin-top: 32px;

    &--color-primary {
      color: var(--color-supreme-red);
    }

    &--color-latin {
      color: var(--color-supreme-red);
    }

    &--color-all {
      color: var(--color-supreme-red);
    }
  }

  &__container {
    background-color: var(--color-cod-gray-300);
    border: 1px solid var(--color-supreme-red);
    margin-top: 16px;
    padding: 32px;

    &--color-primary {
      border-color: var(--color-supreme-red);
    }

    &--color-latin {
      border-color: var(--color-supreme-red);
    }

    &--color-all {
      border-color: var(--color-supreme-red);
    }
  }

  &__header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding-bottom: 32px;

    &--description {
      flex: .6;
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      max-width: 444px;
    }

    &--plan-1,
    &--plan-2 {
      align-items: center;
      display: flex;
      flex: .2;
      flex-direction: column;

      & span {
        font: 500 1.8rem/2.4rem var(--font-gerstner);
      }

      & h4 {
        font: 500 3.2rem/4rem var(--font-gerstner);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;

    &-item {
      display: flex;
      gap: 8px;

      &--description {
        display: flex;
        flex: .6;
        flex-direction: column;
        gap: 8px;
        width: 444px;

        @media (--mobile) {
          flex: .5;
          width: auto;
        }

        & p {
          font: 300 1.4rem/2rem var(--font-gerstner);
          letter-spacing: .025rem;
        }
      }

      &--plan-1,
      &--plan-2 {
        align-items: center;
        display: flex;
        flex: .2;
        flex-direction: column;
        gap: 16px;

        @media (--mobile) {
          flex: .25;
          width: auto;
        }

        & p {
          font: 500 1.6rem/2.4rem var(--font-gerstner);
        }
      }
    }
  }

  &__cta {
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--color-supreme-red);
    color: var(--color-supreme-red);
    display: flex;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    gap: 8px;
    height: 40px;
    justify-content: center;
    padding: 8px 12px;
    width: 122px;

    @media (--mobile) {
      font: 500 1.2rem/2rem var(--font-gerstner);
    }

    &--primary {
      border: 1px solid var(--color-supreme-red);
      color: var(--color-supreme-red);
    }

    &--latin {
      border: 1px solid var(--color-supreme-red);
      color: var(--color-supreme-red);
    }

    &--all {
      border: 1px solid var(--color-supreme-red);
      color: var(--color-supreme-red);
    }

    & > svg {
      height: 12px;
      width: 12px;
    }

    &--selected {
      background-color: var(--color-gray-200);
      border: none;
      color: var(--color-white);
    }

    &[disabled] {
      opacity: .25;
    }
  }

  &__checkbox {
    height: 12px;
    width: 12px;

    &--checked-primary {
      fill: var(--color-supreme-red);
    }

    &--checked-latin {
      fill: var(--color-supreme-red);
    }

    &--checked-all {
      fill: var(--color-supreme-red);
    }

    &--unchecked {
      fill: var(--color-tundora);
    }
  }

  &__buttons-container {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }
}
