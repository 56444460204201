.account {
  &__layout {
    --color-secondary: var(--color-gray-200);
    --color-subscription-standard: #d9badd;
    --color-subscription-premium: #93c6d9;
    --color-subscription-all: #c9de4a;
    --color-subscription-premiumplus: var(--color-subscription-all);
    --color-subscription: var(--color-gin);
    --color-success: #49a147;
    --color-warning: #e1a852;
    --color-error: #eb5757;
    --color-info: #4aa5ff;
    --sp-xsm: .8rem;
    --sp-sm: 1.2rem;
    --sp-md: 1.6rem;
    --sp-lg: 2.4rem;
    --sp-xl: 3.6rem;
    max-width: 76rem;
    box-sizing: content-box;
    padding-inline: var(--sp-lg);
    color: var(--color-gin);
    font-size: 1.6rem;
    line-height: 1.5;
    margin: var(--sp-lg) auto var(--sp-xl);

    @media (--from-tablet) {
      --sp-xsm: .8rem;
      --sp-sm: 1.2rem;
      --sp-md: 2rem;
      --sp-lg: 3.2rem;
      --sp-xl: 6.4rem;
    }

    &--wide {
      max-width: 106rem;
    }

    &--fluid {
      max-width: none;
      padding-inline: 0;
    }
  }

  &__button {
    display: inline-block;
    padding: .8rem 1.2rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .015rem;
    transition: .4s background-color;
    color: var(--color-secondary);

    &:hover,
    &:focus-visible {
      background-color: var(--color-cod-gray-400);
    }

    &--primary {
      border: 1px solid currentColor;
      color: var(--color-gin);
    }
  }

  &__label {
    padding: .4rem 0;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 500;
    color: var(--color-gray-200);
    text-align: left;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    h2:first-child {
      margin-top: var(--sp-lg);
    }
  }
}

@keyframes loading-indicator {
  from {
    background-position: 150% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.loading-wrapper {
  display: inline-block;
  color: transparent;
  border-radius: 3px;
  animation: 2s loading-indicator infinite;
  line-height: 1.1;
  background:
    linear-gradient(
      to right,
      var(--color-mine-shaft) 33%,
      var(--color-cod-gray-500) 50%,
      var(--color-mine-shaft) 83%
    ) center
    center/300% 100%;

  &--block {
    display: block;
  }
}
