.version-pill {
  align-items: center;
  border: 1px solid var(--color-gray-200);
  border-radius: 12px;
  color: var(--color-gray-200);
  display: flex;
  font: 1rem/1.6rem var(--font-gerstner);
  height: 24px;
  justify-content: center;
  letter-spacing: .75px;
  width: 32px;

  &__download {
    display: none;
  }

  &__downloaded {
    background-color: var(--color-tundora);
    border-color: var(--color-gray-200);
    color: var(--color-gray-200);
  }

  @media (hover: hover) {
    &:not([disabled]):hover {
      background-color: var(--color-white);
      border: 1px solid var(--color-gin);
      color: var(--color-black);
    }
  }

  &:not([disabled]):hover &__text {
    display: none;
  }

  &:not([disabled]):hover &__download {
    display: flex;
    justify-content: center;

    > svg,
    > svg path {
      fill: currentColor;
      transition: fill .2s;
    }
  }

  &[disabled] {
    opacity: .25;
  }
}
