.search {
  &__mobile-tabs-container {
    column-gap: 24px;
    display: flex;
    margin-bottom: -1px;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__mobile-tab {
    align-items: center;
    border-bottom: 1px solid transparent;
    color: var(--color-gray-200);
    display: inline-flex;
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    justify-content: center;
    letter-spacing: .0333em;
    padding: 15px 0 17px;
    text-align: center;
    text-decoration: none;
    transition: border .25s, color .25s;
    flex-shrink: 0;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }

    > span {
      font: 500 .8rem/1rem var(--font-gerstner);
    }
  }
}
