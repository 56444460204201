.device-verification {
  &__use-more {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gray-200);

    &-link {
      text-decoration: underline;
      font: 500 1.4rem/2rem var(--font-gerstner);
      color: var(--color-gray-200);
    }
  }
}
