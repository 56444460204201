.section-title {
  font: 500 1.4rem var(--font-gerstner);
  color: var(--color-gin);
  margin-top: 3rem;
  margin-bottom: 1rem;

  &--no-margin {
    margin-top: 0;
  }

  &--half-margin {
    margin-top: var(--top-padding);
  }
}

.section-small {
  font: 500 1.4rem var(--font-gerstner);
  color: var(--color-tundora);
  margin: 1rem 0;
}

.section-header {
  font: 500 1.4rem var(--font-gerstner);
  color: var(--color-gray-200);
  margin: 1rem 0;
}

.error-text {
  font: 300 1.2rem var(--font-gerstner);
  color: var(--color-burnt-sienna);
  margin: 1rem 0;
}
