.create-preset-wave {
  display: flex;
  position: relative;
  width: 100%;
  height: 3px;

  &__wave {
    height: 3px;
    background-color: var(--color-tundora);
    width: 100%;
  }

  &__elapsed-wrapper {
    background-color: var(--color-create);
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
  }

  &__no-preview {
    display: flex;
    align-items: center;

    &--wave {
      flex: 1;
      height: 3px;
      background-color: var(--color-tundora);
    }

    &--text {
      color: var(--color-tundora);
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 0 10px;
    }
  }
}
