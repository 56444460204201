.nav-toggle {
  --button-size: 32px;
  display: none;
  position: fixed;
  top: calc((var(--header-height) - var(--button-size)) / 2 - 1px);
  left: 0;
  width: var(--button-size);
  height: var(--button-size);
  transform: translateX(calc(var(--nav-width) -1 * (var(--button-size) + 12px)));
  cursor: pointer;
  color: var(--color-boulder);
  transition: .2s transform ease-out;
  z-index: 120;

  &:hover,
  &:focus-visible {
    color: var(--color-gin);
  }

  @media (--from-tablet-landscape) {
    display: block;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: var(--button-size);
    height: var(--button-size);
    transition: .2s transform;
  }

  &--closed {
    transition-timing-function: ease;
    transform: translateX(var(--nav-width));

    .nav-toggle__wrapper {
      transform: rotate(180deg);
    }
  }
}
