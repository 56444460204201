.enter-giveaway {
  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__detail {
    color: var(--color-boulder);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    margin-bottom: 16px;
  }

  &__summary-cta {
    align-items: center;
    color: var(--color-boulder);
    column-gap: 4px;
    display: flex;
    flex: 0 0 auto;
    font: 500 1.4rem/2rem var(--font-gerstner);
    justify-content: center;
    letter-spacing: .25px;

    &--primary {
      background: var(--color-gin);
      color: var(--color-cod-gray);
      padding: 8px 13.5px;
    }

    &--share {
      min-width: 120px;
    }

    &--secondary {
      border: 1px solid var(--color-gin);
      padding: 8px 19.5px;
    }

    &--tertiary {
      justify-content: left;
    }
  }

  &__entry {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  &__entry-text {
    color: var(--color-boulder);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__entry-button {
    width: 100px;
  }
}
