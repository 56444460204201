.container {
  margin-left: 16px;
  margin-right: 16px;

  @media (--from-tablet-landscape) {
    margin-left: 32px;
    margin-right: 32px;
  }
}

/**
 * @example
 * .grid > .some-child-el { grid-column: col-start / span 3; } // span from col 1 to col 3
 * .grid > .some-child-el { grid-column: col-start 6 / span 4; } // span from col 6 to col 10
 * .grid > .some-child-el { grid-row: 1 / 3; } // span from row 1 to row 3
 */
.grid {
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(6, [col-start] 1fr);

  @media (--from-tablet-landscape) {
    grid-template-columns: repeat(12, [col-start] 1fr);
  }

  @media (--from-tablet-landscape) {
    column-gap: 30px;
  }

  @media (--from-desktop) {
    column-gap: 48px;
  }
}
