.spacing {
  &--left-first-child {
    > div:first-child {
      padding-left: var(--window-padding);
    }

    > a:first-child {
      padding-left: var(--window-padding);
    }
  }

  &--left {
    padding-left: var(--left-padding);

    &-half {
      padding-left: calc(var(--left-padding) / 2);
    }
  }

  &--bottom {
    padding-bottom: var(--top-padding);

    &-half {
      padding-bottom: calc(var(--top-padding) / 2);
    }
  }

  &--top {
    padding-top: var(--top-padding);

    &-half {
      padding-top: calc(var(--top-padding) / 2);
    }

    &-double {
      padding-top: calc(var(--top-padding) * 2);
    }

    &-margin {
      margin-top: var(--top-padding);
    }
  }

  &--right {
    padding-right: var(--left-padding);
  }

  &--vertical {
    padding-top: var(--left-padding);
    padding-bottom: var(--left-padding);
  }

  &--horizontal {
    margin-left: var(--left-padding);
    margin-right: var(--left-padding);

    &-double {
      margin-left: calc(var(--left-padding) * 2);
      margin-right: calc(var(--left-padding) * 2);
    }
  }

  &--page-padding {
    padding: var(--window-padding);

    .spacing {
      &--page-padding { /** really bad workaround, for componetsn in pages **/
        padding: 0;
      }
    }
  }

  &--page-padding-mobile {
    margin: var(--left-padding);

    @media (--from-tablet-landscape) {
      margin: 0;
    }
  }

  &--gap {
    gap: var(--top-padding);
  }

  &__window {
    margin: var(--window-padding);

    &--top {
      margin-top: var(--window-padding);
    }

    &--left {
      margin-left: var(--window-padding);
    }

    &--right {
      margin-right: var(--window-padding);
    }

    &--bottom {
      margin-bottom: var(--window-padding);
    }

    &--vertical {
      margin: var(--window-padding) 0;
    }

    &--horizontal {
      margin: 0 var(--window-padding);
    }
  }
}
