.search-curated-sets {
  &__mobile-loading-item {
    margin-bottom: 0;
  }

  &__intro-section {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
