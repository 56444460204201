.player-wave-canvas {
  cursor: pointer;
  position: relative;
  touch-action: none;
  width: 100%;
  height: 100%;

  &__canvas {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
