.track-list-ghost-loading {
  &__container {
    padding-top: 24px;
  }

  &__header {
    height: 55px;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 16px;
    background: var(--color-cod-gray-500);
  }

  &__loading {
    padding-left: 10px;
    padding-bottom: 8px;

    &__item-container {
      margin-top: -16px;
      min-width: 100%;
      padding: 0;
      padding-bottom: 16px;
      padding-left: 0;
      width: 100%;
      display: flex;
      align-items: center;

      @media (--from-tablet-landscape) {
        display: grid;
        grid-template-columns:
          [accordion] 0 [left] minmax(262px, 1fr)
          [info] 374px [versions] minmax(auto, 1fr);
      }

      @media (--from-desktop) {
        display: grid;
        grid-template-columns:
          [accordion] 0 [left] 376px
          [info] 374px [versions] minmax(auto, 1fr);
      }

      &--create {
        padding-bottom: 18px;
        margin-top: -14px;
      }

      &--left {
        @media (--from-tablet-landscape) {
          margin-left: var(--left-padding);
        }
      }

      &--info {
        margin-left: var(--left-padding);
        height: 20px;
        margin-bottom: 16px;
        background-color: var(--color-cod-gray-400);
        margin-right: var(--left-padding);
      }

      &--versions {
        height: 20px;
        margin-bottom: 16px;
        background-color: var(--color-cod-gray-400);
      }
    }

    &-title {
      height: 70px;
      margin-top: -20px;
      margin-bottom: -10px;
    }

    &-item {
      background-color: transparent !important;
    }
  }
}
