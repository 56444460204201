.search-songs {
  @media (--from-tablet-landscape) {
    margin-top: var(--window-padding);
  }

  &__sorting {
    margin-left: auto;
  }

  &__see-more {
    padding-top: 4px;
  }

  &__intro-section {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__ghost-loading-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
