.new-releases-per-category {
  &__intro {
    align-items: center;
    display: flex;
    gap: var(--dropdown-gap);
  }

  &__container {
    padding-top: var(--top-padding);
  }

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 0;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }
}
