.key-keyboard-input {
  padding: 16px 0 48px;

  &__types {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;

    &-inner {
      display: flex;
      gap: 16px;
    }
  }

  &__type {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    transition: color .2s;

    &--active {
      color: var(--color-main-dynamic);
    }
  }

  &__svg {
    height: auto;
    width: 100%;
  }

  &__key {
    cursor: pointer;
    fill: var(--color-cod-gray-300);

    &:focus {
      outline: 1px solid var(--color-dodger-blue);
    }

    &--active {
      fill: var(--color-main-dynamic);
    }
  }

  &__key-text {
    fill: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    user-select: none;

    &--active {
      fill: var(--color-cod-gray);
      font-weight: 500;
    }
  }

  &__small-key {
    cursor: pointer;
    fill: var(--color-cod-gray-400);

    &:focus {
      outline: 1px solid var(--color-dodger-blue);
    }

    &--active {
      fill: var(--color-main-dynamic);
    }
  }

  &__small-key-text {
    fill: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    user-select: none;

    &--active {
      fill: var(--color-cod-gray);
      font-weight: 500;
    }
  }
}
