/* react-modal by default includes some inline styles that don't really work
for us.
In order to replace them, we need to include our own class names for overlay/content
containers - as per the docs, this removes the default inline styles.
Example below.
  <ReactModal
    className="react-modal__content"
    overlayClassName="react-modal__overlay"
http://reactcommunity.org/react-modal/styles/
*/
.react-modal {
  &__overlay {
    background-color: rgb(var(--color-black-russian-rgb) / 50%);
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
    inset: 0;
    position: fixed;
    z-index: 999;
  }

  &__content {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    left: 50%;
    max-width: 448px;
    outline: none;
    overflow: auto;
    padding: 18px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}
