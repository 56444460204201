.search-form {
  &--legacy-mobile {
    &__search {
      margin-inline-end: 16px;
    }

    &__search-container-input {
      position: relative;
    }

    &__search-input {
      background: var(--color-cod-gray-400);
      border: none;
      border-radius: 100px;
      color: var(--color-alabaster);
      font: 1.4rem/2.4rem var(--font-gerstner);
      height: 40px;
      letter-spacing: .007em;
      padding: 8px 32px 8px 40px;
      width: 328px;

      &::placeholder {
        color: var(--color-gray-200);
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }

    &__search-input:focus + &__search-submit path {
      fill: var(--color-alabaster);
    }

    &__search-submit {
      left: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__search-filters {
      position: absolute;
      right: 40px;
      top: calc(50% - 12px);
    }

    &__search-clear {
      position: absolute;
      right: 12px;
      top: calc(50% - 12px);
    }

    &__search-suggestions {
      background: var(--color-cod-gray);
      left: 0;
      max-height: 300px;
      overflow: auto;
      position: absolute;
      top: 100%;
      width: 300px;
    }

    &__search-suggestion {
      color: var(--color-gray);
      display: block;
      font: 500 1.2rem/1.2rem var(--font-gerstner);
      letter-spacing: .0208em;
      overflow: hidden;
      padding: 8px 13px 8px 8px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
