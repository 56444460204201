.maxi-player {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--maxi-player-z-index);
  width: 100%;
  /* stylelint-disable-next-line unit-no-unknown */
  height: 100svh;
  overflow: hidden;
  overscroll-behavior-y: none;
  pointer-events: none;

  &--open {
    overflow-y: scroll;
    pointer-events: auto;
    color-scheme: dark;
  }

  &__top-area {
    position: relative;
    height: calc(var(--header-height) - 1px); /* header minus border width */
    top: 0;
    width: 100%;
    z-index: 20;
  }

  &__container {
    position: relative;
    width: 100%;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    transition: .35s transform cubic-bezier(.22, .61, .36, 1);
    transform: translateY(100%);
    pointer-events: auto;

    &--enter {
      transform: translateY(100%);
    }

    &--enter-active,
    &--enter-done {
      transform: translateY(0);
    }

    &--exit {
      transform: translateY(0);
      transition-timing-function: cubic-bezier(.55, .09, .68, .53);
    }

    &--exit-active,
    &--exit-done {
      transform: translateY(100%);
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    /* stylelint-disable-next-line unit-no-unknown */
    min-height: calc(100svh - var(--header-height) - 1px);
    display: grid;
    align-content: start;
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(auto, 1fr);
    padding-bottom: 8px;
    backdrop-filter: blur(12px);

    @media (--from-tablet) {
      backdrop-filter: blur(16px);
    }

    @media (--from-desktop) {
      backdrop-filter: blur(20px);
    }

    @media (--from-large-desktop) {
      backdrop-filter: blur(28px);
    }

    @media (width: 2560px) {
      backdrop-filter: blur(36px);
    }
  }

  &__top-menu {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 16px 32px;
    align-items: center;

    @media (--from-tablet) {
      display: none;
    }
  }

  &__mobile-overlay {
    background: var(--color-cod-gray-100);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &__mobile-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__mobile-menu__column {
    display: flex;
  }

  &__minimize {
    margin-right: 16px;
  }

  &__breadcrumb {
    color: var(--color-gin);
    font: 1.4rem/2.4rem var(--font-gerstner);
    margin-inline-end: auto;
  }

  &__mobile-menu-items {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: -12px;
  }

  &__mobile-menu-item {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    margin: 12px;
  }

  &__mobile-cover-wrapper {
    margin: 8px auto 20px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__mobile-cover {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 40%;
    height: auto;
  }

  &__mobile-title-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    > button {
      display: flex;
    }
  }

  &__mobile-title {
    color: var(--color-gin);
    font-size: 1.6rem;
    font-weight: 500;
    font-family: var(--font-gerstner);
    line-height: 2.4rem;
    letter-spacing: .035rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70vw;
  }

  &__subtitle {
    color: var(--color-gin);
    font: 400 1.4rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    a {
      color: inherit;
    }

    @media (--from-desktop) {
      line-height: 2.4rem;
    }
  }

  &__meta-items {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__meta-item {
    color: var(--color-gray-200);
    font: 400 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .04rem;

    &:not(:last-of-type)::after {
      content: '/';
      margin-left: 8px;
    }
  }

  &__mobile-versions-button {
    margin-top: 24px;
  }

  &__mobile-player-ctrls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 100%;
  }

  &__player-ctrls-main {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  &__mobile-player-ctrls--bar {
    justify-content: space-around;
    margin: 0 16px;
  }

  &__player-ctrl {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      path {
        transition: fill, stroke .2s ease-out;
      }
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    &--play,
    &--pause {
      svg {
        width: 56px;
        height: 56px;
      }

      path {
        fill: var(--color-gin);
      }
    }

    &--prev,
    &--next {
      color: var(--color-grey-200);
      height: 40px;
      width: 40px;
    }

    &--repeat,
    &--shuffle {
      svg {
        height: 24px;
        width: 24px;
      }
    }

    &--repeat-one-icon {
      display: flex;
      align-items: center;

      svg {
        scale: 1.2;
      }
    }

    &--like {
      &:hover {
        path {
          fill: var(--color-gin);
        }
      }
    }

    &--show-more {
      path {
        transition: stroke .2s;
      }

      &:hover {
        path {
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__tablet {
    display: none;
    margin: 0 24px 32px;

    @media (--from-tablet) {
      display: block;
    }

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__desktop {
    display: none;
    margin: 0 24px 32px;

    @media (--from-tablet-landscape) {
      display: block;
    }

    @media (--from-desktop) {
      margin: 0 32px 40px;
    }
  }

  &__tablet-wrapper {
    column-gap: 32px;
    display: grid;
    grid-template-areas:
      'cover track-info'
      'cover menu'
      'controls controls'
      'actions actions';
    grid-template-columns: 160px minmax(0, 1fr);
    align-items: center;
  }

  &__desktop-wrapper {
    column-gap: 32px;
    display: grid;
    grid-template-areas: 'cover track-info';
    grid-template-columns: 240px minmax(0, 1fr);
    align-items: center;

    @media (--from-desktop) {
      grid-template-columns: 320px minmax(0, 1fr);
    }
  }

  &__desktop-right-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-area: track-info;
  }

  &__desktop-cover-wrapper {
    background: var(--color-dove-gray);
    grid-area: cover;
    aspect-ratio: 1 / 1;
    width: 160px;
    height: 160px;
    position: relative;
    z-index: 10;

    @media (--from-tablet-landscape) {
      width: 240px;
      height: 240px;
    }

    @media (--from-desktop) {
      width: 320px;
      height: 320px;
    }
  }

  &__desktop-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__desktop-track-info {
    grid-area: track-info;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    @media (--from-desktop) {
      gap: 4px;
    }
  }

  &__desktop-player-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 0;

    @media (--from-desktop) {
      padding-bottom: 4px;
    }
  }

  &__desktop-player-title {
    color: var(--color-gin);
    font: 400 2.4rem/3.2rem var(--font-gerstner);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50vw;

    @media (--from-tablet-landscape) {
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 4rem;
      max-width: 40vw;
    }

    @media (--from-desktop) {
      font-size: 3.6rem;
      max-width: calc(100vw - 584px);
    }
  }

  &__desktop-player-title-versions-button {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__tablet-player-menu {
    grid-area: menu;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__menu-right-controls {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__desktop-controls {
    grid-area: controls;
    width: 100%;
    display: grid;
    justify-items: start;
    justify-content: start;
    align-items: center;
    gap: 32px;
    margin-top: 24px;
    grid-template-columns: [controls-left] 152px [wave] minmax(300px, 1fr);

    @media (--from-tablet-landscape) {
      gap: 32px;
      margin-top: 0;
      justify-items: start;
      justify-content: start;
      grid-template-columns: [controls-left] 152px [wave] minmax(328px, 1fr) [controls-right] 104px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media (--from-desktop) {
      gap: 40px;
      padding-top: 32px;
      padding-bottom: 27px;
      grid-template-columns: [controls-left] 152px [wave] minmax(328px, 1fr) [controls-right] 104px;
    }

    @media (--from-large-desktop) {
      grid-template-columns: [controls-left] 152px [wave]  minmax(552px, 1800px) [controls-right] minmax(104px, 1fr);
    }
  }

  &__desktop-left-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media (--from-tablet-landscape) {
      gap: 8px;
    }
  }

  &__desktop-right-controls {
    align-items: center;
    gap: 16px;
    display: flex;
    justify-self: end;
  }

  &__desktop-actions {
    position: relative;
    grid-area: actions;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    margin-top: 24px;

    @media (--from-tablet-landscape) {
      margin-top: 0;
      width: 100%;
    }
  }

  &__desktop-actions-downloads {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__desktop-actions-quick-actions {
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__desktop-breadcrumb {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .015em;
    margin-bottom: 0;
  }

  &__desktop-menu {
    align-items: center;
    display: flex;
    grid-area: menu;
    position: sticky;
    top: 20px;
  }

  &__desktop-menu-item {
    align-items: center;
    color: var(--color-boulder);
    display: flex;
    font: 1.2rem/2rem var(--font-gerstner);
    letter-spacing: .0208em;
    margin: 0 32px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
      position: absolute;
      right: 0;
    }

    i {
      margin-right: 4px;
    }
  }

  &__desktop-menu-item--active {
    color: var(--color-gin);
  }

  &__desktop-player-versions {
    color: var(--color-gin);
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .0071em;
    margin-right: 15px;
    padding-right: 12px;
    position: relative;

    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--color-main-dynamic);
      content: '';
      height: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }
  }
}
