.news-card {
  &__subtitle {
    font: 300 1.1rem/1.6rem var(--font-gerstner);
    letter-spacing: .036em;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      letter-spacing: .0333em;
    }
  }
}
