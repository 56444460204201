/* For simplicity and consistency, track-list-item and track-list components are both using
the styles defined in this file.
track-list-item does not have its own .module.css file. */
.track-list {
  --hover-button-opacity: 0;

  &__list {
    width: 100%;
    margin-bottom: 8px;
  }

  &__label,
  &__label-link {
    color: var(--color-gray-200);
    font: 400 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .04rem;
    text-align: left;

    @media (--from-tablet) {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: .025rem;
    }

    &--clickable {
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);
        }
      }
    }
  }

  &__counter {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .04rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list-heading {
    display: none;
    align-items: center;
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    cursor: default;
    letter-spacing: .08em;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid #e6efe90a;
    padding: 8px 0;

    @media (--from-tablet) {
      display: grid;
      padding-inline: 8px;
    }
  }

  &__list-item {
    display: grid;
    align-items: center;
    padding: 8px 0;
    color: var(--color-gray-200);
    font: 400 1.4rem/1.6rem var(--font-gerstner);
    letter-spacing: .033em;
    text-align: left;
    transition: background-color .2s, color .2s;

    @media (--from-tablet) {
      padding: 16px 8px;
      margin-top: 4px;
    }
  }

  &__list-item--clickable {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        --hover-button-opacity: 1;
        background-color: #212121;
      }
    }
  }

  &__list-item--maxi-player {
    @media (hover: hover) {
      &:hover {
        background-color: #e6efe914;
      }
    }
  }

  &__draggable-item-wrapper--is-dragging &__list-item {
    background-color: var(--color-cod-gray-300);
    color: var(--color-gin);
  }

  &__list-heading,
  &__list-item {
    max-width: 100%;

    &--is-album-restricted {
      opacity: .25;
      pointer-events: none;
    }

    &--dragging-over {
      border-bottom: 2px solid var(--color-main-dynamic);
    }

    &--download,
    &--download-maxi,
    &--queue,
    &--crate {
      grid-template-columns: [accordion] 24px [title] 1fr [actions] 40px;

      @media (--from-tablet) {
        grid-template-columns:
          [accordion] 32px [title] minmax(230px, 1fr)
          [bpm] 68px [key] 69px [genre] 144px [download] 40px [actions] minmax(40px, auto);
      }

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 32px [title] 1fr
          [bpm] 55px [key] 69px [genre] 120px [download] 170px [actions] minmax(108px, 128px);
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 32px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 280px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [actions] minmax(128px, 170px);
      }
    }

    &--download,
    &--crate {
      @media (min-width: 1360px) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 180px [added] 120px [actions] 128px;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 32px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 280px [added] 120px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 32px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 340px [added] 120px [actions] 128px;
      }
    }

    &--download-trending,
    &--stream-trending {
      grid-template-columns: [accordion] 52px [title] 1fr [actions] 40px;

      @media (--from-tablet) {
        grid-template-columns:
          [accordion] 52px [title] minmax(230px, 1fr)
          [bpm] 68px [key] 69px [genre] 144px [actions] minmax(40px, auto);
      }

      @media (--from-tablet) {
        grid-template-columns:
          [accordion] 52px [title] minmax(230px, 1fr)
          [bpm] 68px [key] 69px [genre] 144px [download] 40px [actions] minmax(40px, auto);
      }

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 52px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 200px [actions] 128px;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 52px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 280px [actions] 128px;
      }

      @media (min-width: 1360px) {
        grid-template-columns:
          [count] 52px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [genre] 144px [download] 180px [added] 120px [actions] 128px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 52px [title] 1fr
          [bpm] 69px [key] 69px [genre] 144px [download] 390px [added] 120px [actions] 128px;
      }
    }
  }

  &__sort-btn {
    display: none;
  }

  &__list-heading--sortable &__sort-btn {
    display: block;
  }

  &__date-timeago {
    color: var(--color-main-dynamic);
  }

  &__clickable-label {
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .75px;
    text-align: left;
    color: var(--color-gray-200);
    text-transform: uppercase;
    transform: color .2s !important;
    display: flex;
    align-items: center;

    &--active {
      color: var(--color-gin);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__heading-column,
  &__column {
    &--bpm,
    &--key,
    &--time,
    &--date,
    &--genre {
      display: none;

      @media (--from-tablet) {
        display: flex;
      }
    }

    &--date {
      display: none;

      @media (min-width: 1360px) {
        display: flex;
      }
    }

    &--bpm,
    &--key,
    &--time,
    &--date {
      @media (--from-tablet-landscape) {
        margin-right: 16px;
      }
    }

    &--title {
      display: flex;
      margin-right: 16px;
    }

    &--accordion {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &--stream-actions,
    &--online-crate-actions,
    &--offline-crate-actions {
      gap: 8px;
      justify-self: end;
    }

    &--count,
    &--stream-actions,
    &--edit-mode-checkbox {
      display: flex;
    }

    &--downloadable-pill {
      line-height: 0;
    }

    &--count,
    &--checkbox,
    &--edit-mode-checkbox {
      display: flex;
      justify-content: flex-start;
      padding-right: 8px;
    }
  }

  &__heading-column {
    &--versions {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
      }
    }

    &--quick-actions {
      display: none;

      @media (--from-desktop) {
        display: block !important;
      }
    }
  }

  &__column {
    &--versions {
      display: none;

      @media (--from-tablet) {
        display: block;
      }
    }

    &__quick-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (--from-tablet) {
        display: none;
      }
    }
  }

  &__versions-content {
    display: none;

    @media (--from-tablet-landscape) {
      display: flex;
    }
  }

  &__list-item--download-accordion-open {
    background-color: var(--color-cod-gray-300);

    &--maxi-player {
      background-color: #e6efe914;
    }
  }
}
