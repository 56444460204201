.crop-image {
  justify-content: center;

  &__subtitle {
    font: 300 1.4rem var(--font-gerstner);
    letter-spacing: -.0035em;
    color: rgba(255 255 255 50%);
    margin: calc(var(--top-padding) / 2) 0;
  }

  &__crop-container {
    margin-top: calc(var(--top-padding) / 2);
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: calc(var(--top-padding));
  }

  &__button {
    border: 1px solid var(--color-gray-200);
    color: var(--color-gray--200);
    height: 24px;
    width: 24px;
    align-self: center;
    display: flex;
  }

  &__left-buttons {
    display: flex;
    flex-direction: row;
    gap: calc(var(--left-padding) / 2);
    justify-content: flex-start;
  }

  &__right-buttons {
    display: flex;
    flex-direction: row;
    gap: calc(var(--left-padding) / 2);
    justify-content: flex-end;
  }

  /* React-easy-crop styling https://www.npmjs.com/package/react-easy-crop */
  &__containerClassName {
    height: 260px !important;
    position: relative !important;
    overflow: hidden !important;
    background-color: var(--color-cod-gray);

    @media (--from-tablet-landscape) {
      height: 340px !important;
    }
  }

  &__cropAreaClassName {
    content: none !important;
    border-radius: 50%;
    box-shadow: none;
    box-sizing: content-box !important;
    border: none !important;
    height: 100% !important;
    width: 100% !important;

    &::after {
      content: none !important;
    }

    &::before {
      content: none !important;
    }
  }

  &__hiddenCropAreaClassName {
    height: 100% !important;
    width: 100% !important;
  }
}
