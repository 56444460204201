.checkout-item {
  background-color: var(--color-cod-gray-300);
  border-top: 1px solid var(--color-main-dynamic);
  color: var(--color-gin);
  min-height: 255px;
  padding: 32px;
  width: 396px;

  &__border--primary {
    border-top-color: var(--color-supreme-red);
  }

  &__border--latin {
    border-top-color: var(--color-supreme-red);
  }

  &__border--all {
    border-top-color: var(--color-supreme-red);
  }

  &__border--generic {
    border-top-color: var(--color-main-dynamic);
  }

  @media (--mobile) {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    & span {
      font: 500 2.4rem/3.2rem var(--font-gerstner);
    }

    &--title {
      text-transform: capitalize;
    }
  }

  &__library {
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    margin-bottom: 24px;
  }

  &__type {
    color: var(--color-gin);
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    margin: 16px 0 8px;

    &--title {
      text-transform: capitalize;
    }
  }

  &__content {
    display: flex;
    font: 300 1.4rem/2rem var(--font-gerstner);
    justify-content: space-between;
    letter-spacing: .25px;
    margin-bottom: 8px;

    &__pay {
      height: 40px;
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      align-items: center;
      padding: 8px 16px;
      color: var(--color-cod-gray);
    }

    &__background--primary {
      background-color: var(--color-supreme-red);
    }

    &__background--latin {
      background-color: var(--color-supreme-red);
    }

    &__background--all {
      background-color: var(--color-supreme-red);
    }

    &__background--generic {
      background-color: var(--color-main-dynamic);
    }

    &-discount {
      color: var(--color-gray-200);
    }

    &--highlight {
      color: var(--color-main-dynamic);
    }
  }

  &__description {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .25px;
    margin-top: 16px;
  }

  &__description-create {
    margin-top: 16px;

    &--item {
      align-items: center;
      color: var(--color-gin);
      display: flex;
      font: 300 1.4rem/2rem var(--font-gerstner);
      gap: 8px;

      svg {
        > path {
          stroke: var(--color-main-dynamic);
        }
      }
    }
  }
}
