.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: inherit;
  background-color: inherit;
}

.button {
  appearance: none;
  position: relative;
  padding: 1rem 1.2rem;
  font-family: inherit;
  line-height: 1.2;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-gin);
  border: 1px solid currentColor;
  border-radius: 0;
  transition: .3s all;
  text-align: center;

  &:disabled {
    opacity: .6;
  }

  @media (--mobile) {
    text-align: center;
    width: 100%;
  }

  @media (--from-tablet) {
    padding: 1rem 1.2rem;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  button:not(:disabled):hover &,
  a:hover &, {
    color: #121212;
    background-color: var(--color-gin);
  }

  &--secondary {
    color: var(--color-secondary);
    border: none;
    background-color: transparent;

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible,
    button:not(:disabled):hover &,
    a:hover &, {
      background-color: transparent;
      color: var(--color-gin);
    }
  }

  &--primary {
    color: #121212;
    border-radius: 0;
    transition: .3s background-color, .3s opacity;
    background-color: var(--color-gin);

    @media (--mobile) {
      text-align: center;
      width: 100%;
    }

    @media (--from-tablet) {
      padding: 1rem 1.2rem;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible,
    button:not(:disabled):hover &,
    a:hover &, {
      opacity: .85;
    }
  }

  &--inverted {
    color: var(--color-gin);
    border-color: #121212;
    background-color: #121212;

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible,
    button:not(:disabled):hover &,
    a:hover &, {
      border-color: var(--color-gin);
    }
  }

  &--destructive {
    color: black;
    border-color: var(--color-error);
    background-color: var(--color-error);

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      background-color: var(--color-error);
      opacity: .85;
    }
  }
}
