.generic {
  &__input {
    background: none;
    border: 0;
    border-bottom: 1px solid var(--color-cod-gray-400);
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    padding: 10px 28px 10px 0;
    transition: background .2s, color .2s;
    width: 100%;

    &:focus {
      border: 0;
      border-bottom: 1px solid var(--color-gin);

      &::placeholder {
        color: var(--color-gin);
      }
    }

    &[data-focus-visible-added] {
      box-shadow: inset 0 -1px 0 var(--color-gin);
      outline: none;
    }

    &::placeholder {
      color: var(--color-gray-200);
      font: 300 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .25px;
    }
  }
}
