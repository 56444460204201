.favorites-mobile {
  &__tab-wrapper {
    display: inline-block;
    width: auto !important;
  }

  &__tab {
    border-bottom: 1px solid transparent;
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    margin-right: 25px;
    padding: 15px 0 17px;
    transition: border .25s, color .25s;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }

    > span {
      font: 500 .8rem/1rem var(--font-gerstner);
    }
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }
}
