.create-wave {
  display: flex;
  position: relative;
  width: 100%;

  &__wave {
    height: 34px;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    image-rendering: -webkit-optimize-contrast;
    width: 100%;

    &--elapsed {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      image-rendering: -webkit-optimize-contrast;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &__elapsed-wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
  }
}
