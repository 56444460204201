.sort-button {
  --content-color: var(--color-gray-200);
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  span {
    font: 500 1.4rem/1.4rem var(--font-gerstner);
    color: var(--content-color);
    align-items: center;
    margin-right: var(--half-padding);
    transition: color .2s;
  }

  &__icon {
    path {
      fill: var(--content-color);
      transition: fill .2s;
    }
  }

  &--active {
    --content-color: var(--color-main-dynamic);
  }

  @media (hover: hover) {
    &:hover {
      --content-color: var(--color-gin);

      path {
        --content-color: var(--color-gin);
      }
    }
  }
}
