.account {
  &__select {
    background-color: var(--color-cod-gray);
  }

  /* TODO: aim to replace this with global .button styles */
  &__button {
    background-color: var(--color-gin);
    border: 1px solid var(--color-gin);
    color: var(--color-cod-gray);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    height: 40px;
    letter-spacing: .35px;
    padding: 8px 12px;
    text-align: center;
    width: 100%;

    @media (--from-tablet) {
      width: auto;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-gray);
        border-color: var(--color-gray);
      }
    }

    svg {
      margin-right: 14px;

      path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--inactive {
      opacity: .6;
    }
  }

  &__button-secondary {
    border: 1px solid var(--color-gin);
    color: var(--color-gin);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    height: 40px;
    letter-spacing: .35px;
    padding: 8px 12px;
    text-align: center;
    width: 100%;

    @media (--from-tablet) {
      width: auto;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-tundora);
      }
    }

    svg {
      margin-right: 14px;

      path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--inactive {
      opacity: .6;
    }
  }

  /* react-select override css */
  &__control {
    background-color: var(--color-cod-gray-100) !important;
    border: none !important;
    border-radius: 0 !important;
  }

  &__control--is-focused {
    box-shadow: none !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__placeholder {
    font: 300 1.4rem/2rem var(--font-gerstner);
  }

  &__single-value,
  &__menu {
    color: var(--color-gin) !important;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
  }

  &__menu {
    background-color: var(--color-cod-gray-100) !important;
  }

  &__option--is-focused {
    background-color: var(--color-gray-100) !important;
  }
}
