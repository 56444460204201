.card-carousel {
  margin: 32px 0;

  &__card-list {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: var(--left-padding);

    /* Hide Scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__card-list::-webkit-scrollbar {
    display: none;
  }

  &__controls-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 var(--window-padding) 16px 0;

    @media (--from-tablet-landscape) {
      justify-content: space-between;
    }

    &--no-right-margin {
      margin-right: 0;
    }
  }

  &__heading {
    text-decoration: none;
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__arrow-btn {
    font-size: 0;

    > svg > path {
      stroke: var(--color-main-dynamic);
      transition: stroke .2s;
    }

    &__default {
      @media (hover: hover) {
        &:hover:not([disabled]) {
          cursor: pointer;

          > svg > path {
            stroke: var(--color-gin);
          }
        }
      }
    }
  }

  &__prev-btn,
  &__next-btn {
    @media (--tablet) {
      display: none;
    }
  }

  &__see-more {
    &--mobile {
      @media (--from-tablet-landscape) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
        margin-inline-start: 8px;
      }
    }
  }

  :global {
    .swiper-slide {
      width: auto;
    }

    .swiper-button-disabled {
      opacity: .5;
    }
  }
}
