.account-add-payment {
  &--spacer {
    margin-top: 2rem;
  }

  &__form {
    @media (--from-tablet-landscape) {
      max-width: 75%;
    }

    &--stripe {
      max-width: 460px;
    }

    h5 {
      color: var(--color-gin);
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      letter-spacing: .1px;
    }

    h4 {
      color: var(--color-gin);
      letter-spacing: .1px;
    }
  }

  &__form-content {
    margin-bottom: 40px;
    margin-top: 16px;
  }

  @media (--from-tablet) {
    &__form-grid {
      display: grid;
      grid-gap: 16px;

      &--2 {
        grid-template-columns: 1fr 1fr;
      }

      &--2-bigger {
        grid-template-columns: 2fr 1fr;
      }

      &--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  &__information {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
  }

  &__information-text,
  &__information-card {
    color: var(--color-gin);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 12px;
  }

  &__information-title {
    color: var(--color-gin);
    flex: auto;
    margin-bottom: 16px;
    order: -1;

    @media (--from-tablet) {
      flex: 1 0 100%;
    }
  }

  &__information-text {
    flex: 1 0 100%;

    @media (--from-tablet) {
      flex: auto;
    }
  }

  &__information-card {
    @media (--mobile) {
      margin-bottom: 16px;
      order: -1;
    }
    align-items: center;
    display: flex;
    gap: 10px;
  }

  &__information-container {
    display: flex;

    @media (--mobile) {
      display: contents;
    }
  }

  &__information-icon {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    height: 20px;
    width: 20px;
    margin-top: -10px;
  }

  &__payment-methods {
    display: flex;
    gap: 16px;
    margin: 32px 0;
    width: 100%;

    button {
      align-items: center;
      background: none;
      border: 1px solid var(--color-gin);
      color: var(--color-gin);
      display: flex;
    }
  }

  &__paypal-info {
    color: var(--color-gin);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    margin-bottom: 40px;
  }

  &__form-button {
    height: 40px;
    padding: 8px 12px;
  }
}

.terms-description {
  color: var(--color-secondary);
  margin-block: var(--sp-sm);
  font-size: 1.2rem;

  a {
    color: var(--color-main-dynamic);
  }
}
