.custom-text-field {
  --border-color: var(--color-tundora);
  border: .1rem solid var(--border-color);
  display: flex;
  flex-direction: row;
  transition: border .2s;
  border-radius: 4px;
  width: 100%;

  @media (--from-tablet-landscape) {
    max-width: 245px;
  }

  &--has-errors {
    --border-color: var(--color-burnt-sienna);
    transition: border 1s;
    margin-bottom: 0;
    color: var(--color-burnt-sienna) !important;
  }

  :focus:not(.focus-visible) {
    outline: none;
  }

  @media (hover: hover) {
    &:hover:not([aria-disabled='true']) {
      --border-color: var(--color-gin);
    }
  }

  &--left-icon {
    align-self: center;
    margin-left: 1rem;
  }

  &--right-icon {
    align-self: center;
    margin-right: 1rem;
  }

  &--error-text {
    color: var(--color-burnt-sienna) !important;
  }

  &--text {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    background-color: transparent;
    border: none;
    padding: .8rem;
    height: 100%;
    min-height: 4.2rem;
    width: 100%;
    outline-width: 0;
    transition: color 1s;
  }
}
