.toolbar {
  align-items: center;
  display: flex;
  overflow: none;

  &--stick-to-header {
    position: sticky;
    top: var(--header-height);
    z-index: 30;
    background: var(--color-cod-gray);
  }

  &--scrollable {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
