.featured-bpm-exclusives-all {
  &__card-details {
    >*:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }

  &__card-label {
    align-items: center;
    display: flex;

    >svg {
      height: auto;
      width: 13px;

      >path {
        fill: var(--color-main-dynamic);
      }
    }
  }
}
