@keyframes loader {
  0% {
    opacity: .2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.loader {
  background: var(--color-cod-gray);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 500;

  svg {
    animation: loader 2s ease infinite;
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }
}
