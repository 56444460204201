.search-input {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
    all: unset;
    border: 1px solid var(--color-cod-gray-400);
    background-color: var(--color-cod-gray-500);
    padding: 4px 44px 4px 40px;
    font: 400 1.4rem/2.4rem var(--font-gerstner);
    color: var(--color-gin);
    transition: background-color .2s, border-color .2s, color .2s ease-out;
    z-index: 0;
    box-sizing: border-box;

    &:focus {
      border-color: var(--color-gin);
      background-color: var(--color-cod-gray-400);
    }

    &::placeholder {
      font: 400 1.4rem/2.4rem var(--font-gerstner);
      color: var(--color-gray-200);
    }
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;

    path {
      transition: stroke .2s, fill .2s;
    }
  }

  &__input:focus > &__search-icon {
    path {
      fill: var(--color-gin);
    }
  }

  &__clear-button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    &--hidden {
      display: none;
    }

    svg {
      path {
        transition: fill .2s;
        fill: var(--color-gray-200);
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--color-gin);
        }
      }
    }
  }
}
