.player-background {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px 4px 0 0;
    border-top: 1px solid #e6efe91a;
    overflow: hidden;
    z-index: 1;
    background-color: var(--color-cod-gray-300);
  }

  &__image {
    --image-url: '';
    position: absolute;
    z-index: 2;
    top: 0%;
    left: 0;
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
    opacity: .08;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    background-image: var(--image-url);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (--from-tablet) {
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to bottom, #1a1a1a00, #1a1a1a);
      z-index: 3;
    }
  }
}
