.squared-button {
  align-items: center;
  color: var(--color-gray-200);
  display: flex;
  transition: color .2s, background-color .2s, border-color .2s;
  border: 1px solid var(--color-gray-200);
  padding: .6rem;
  height: 40px;
  white-space: nowrap;

  &--full-width {
    width: 100%;
    justify-content: center;
  }

  &--disabled {
    background-color: var(--color-gray-200) !important;
    pointer-events: none;
  }

  &--outline {
    border: 1px solid var(--color-gray-200);
    background-color: transparent;
    color: var(--color-gray-200);

    @media (hover: hover) {
      &:hover {
        color: var(--color-pumice);
        border: 1px solid var(--color-pumice);
      }
    }

    &--fill {
      > svg path {
        fill: currentColor;
        transition: fill .2s;
      }
    }

    &--fill-stroke {
      > svg {
        fill: currentColor;
        transition: fill .2s;

        > path {
          stroke: currentColor;
          transition: stroke .2s;
        }
      }
    }

    &--stroke {
      > svg > path,
      > svg path {
        stroke: currentColor;
        transition: stroke .2s;
      }
    }

    &--no-hover {
      @media (hover: hover) {
        &:hover {
          cursor: not-allowed;
          background-color: transparent;
          color: var(--color-gray-200);
          border: 1px solid var(--color-gray-200);
        }
      }
    }
  }

  &--filled {
    border: none;
    background-color: var(--color-gin);
    color: var(--color-cod-gray);

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: var(--color-pumice);
      }
    }

    &--no-hover {
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          background-color: var(--color-pumice);
        }
      }
    }

    &--fill {
      > svg > path,
      > svg path {
        fill: currentColor;
        transition: fill .2s;
      }
    }

    &--stroke {
      > svg > path,
      > svg path {
        stroke: currentColor;
        transition: stroke .2s;
      }
    }
  }

  &--dynamic {
    border: none;
    background-color: var(--color-main-dynamic);
    color: var(--color-cod-gray);
    transition: background-color .2s;

    @media (hover: hover) {
      &:hover {
        opacity: .8;
        cursor: pointer;
      }
    }

    &--fill {
      > svg > path,
      > svg path {
        fill: currentColor;
        transition: fill .2s;
      }
    }

    &--stroke {
      > svg > path,
      > svg path {
        stroke: currentColor;
        transition: stroke .2s;
      }
    }

    &--no-hover {
      @media (hover: hover) {
        &:hover {
          background-color: var(--color-main-dynamic);
          color: var(--color-cod-gray);
          cursor: pointer;
        }
      }
    }
  }

  &--colored-red {
    border: none;
    background-color: var(--color-supreme-red);
    color: var(--color-cod-gray);

    &--no-hover {
      @media (hover: hover) {
        &:hover {
          opacity: .8;
          cursor: pointer;
        }
      }
    }

    &--fill {
      > svg > path {
        fill: currentColor;
        transition: fill .2s;
      }
    }

    &--stroke {
      > svg > path {
        stroke: currentColor;
        transition: stroke .2s;
      }
    }
  }

  &--outline-active {
    border: 1px solid var(--color-tundora);
    background-color: var(--color-cod-gray-500);
    color: var(--color-gray-200);

    @media (hover: hover) {
      &:hover {
        color: var(--color-pumice);
        border: 1px solid var(--color-pumice);
      }
    }

    &--fill {
      > svg path {
        fill: currentColor;
        transition: fill .2s;
      }
    }

    &--fill-stroke {
      > svg {
        fill: currentColor;
        transition: fill .2s;

        > path {
          stroke: currentColor;
          transition: stroke .2s;
        }
      }
    }

    &--stroke {
      > svg > path {
        stroke: currentColor;
        transition: stroke .2s;
      }
    }

    &--no-hover {
      @media (hover: hover) {
        &:hover {
          cursor: not-allowed;
          background-color: transparent;
          color: var(--color-gray-200);
          border: 1px solid var(--color-gray-200);
        }
      }
    }
  }

  > span {
    display: inline-block;
    margin-inline-start: 5px;
    font: 500 1.6rem var(--font-gerstner);
    padding-right: .5rem;
  }
}
