.artist-detail {
  &--button-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      flex-direction: row;
      margin-right: 64px;
      float: right;
      position: relative;
      bottom: 85px;
      margin-bottom: 0;
    }

    &--item {
      color: var(--color-gray-200);
      font: 500 13px var(--font-gerstner);

      &--active {
        border-color: var(--color-main-dynamic);
        color: var(--color-main-dynamic);
      }

      &--icon {
        display: block;
        height: 24px;
        text-align: center;

        &--active {
          border-color: var(--color-main-dynamic);
          color: var(--color-main-dynamic);
        }

        svg {
          * {
            transition: fill .25s, stroke .25s;
          }
        }
      }
    }
  }
}

.detail-mobile {
  &__tab-container {
    column-gap: 24px;
    display: flex;
    padding-left: var(--left-padding);
  }

  &--tab {
    border-bottom: 1px solid transparent;
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    padding: 15px 0 17px;
    transition: border .25s, color .25s;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }

    > span {
      font: 500 .8rem/1rem var(--font-gerstner);
    }
  }
}
