.search-item {
  --remove-button-opacity: 0;
  margin-left: calc(-1 * var(--left-padding));
  margin-right: calc(-1 * var(--left-padding));

  &:hover {
    --remove-button-opacity: 1;
    background-color: var(--color-cod-gray-400);
  }

  &__container {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__left-container {
    display: flex;
    justify-content: flex-start;
    gap: var(--left-padding);
    align-items: center;
    flex: 1;
    margin-left: var(--left-padding);
  }

  &__right-container {
    align-items: center;
    align-self: center;
    justify-content: center;
    display: flex;
    margin-right: var(--left-padding);
    opacity: var(--remove-button-opacity);
  }
}
