.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
  min-height: 40vh;

  @keyframes breathe-animation {
    50% {
      transform: scale(1.15);
    }

    100% {
      transform: scale(1);
    }
  }

  &__circles {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 72px;
  }

  &__circle {
    position: absolute;
    display: flex;
    border-radius: 50%;
    animation: breathe-animation 1600ms linear infinite;
    background-color: var(--color-gin);
  }

  &__circle-1 {
    height: 72px;
    width: 72px;
    opacity: .1;
    animation-delay: 0ms;
  }

  &__circle-2 {
    height: 56px;
    width: 56px;
    opacity: .15;
    animation-delay: 400ms;
  }

  &__circle-3 {
    height: 40px;
    width: 40px;
    opacity: .2;
    animation-delay: 800ms;
  }

  &__icon {
    path {
      fill: var(--color-gin);
    }
  }

  &__title {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .015rem;
    color: var(--color-gray-200);
  }
}
