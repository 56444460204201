.stripe-form {
  &__form {
    width: 100%;
    min-height: 600px;
  }

  &__loading {
    height: 760px;
    display: grid;
    place-items: center;
  }

  &__submit-button {
    height: 48px;
    margin-top: 24px;
  }

  &__spacer {
    padding-top: 16px;
  }
}
