.custom-icon {
  --hover-background-color: transparent;
  --hover-background-border-color: transparent;
  --hover-icon-color-gray: var(--color-gray-200);
  --hover-icon-color-boulder: var(--color-boulder);
  --hover-icon-color-dynamic: var(--color-main-dynamic);
  --hover-icon-color-red: var(--color-supreme-red);
  --hover-icon-color-delete: var(--color-burnt-sienna);
  --hover-icon-color-white: var(--color-gin);
  --hover-icon-color-black: var(--color-cod-gray);
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(&--current) {
    color: #919191;
    transition: all .2s ease-in-out;
  }

  &:hover {
    color: white;
  }

  &__hide-on-focus-tooltip {
    &:focus {
      visibility: hidden;
    }
  }

  &__container {
    /* Icon Container Styles */
    &--square {
      background-color: var(--hover-background-color);
      border: 1px solid var(--hover-background-border-color);
    }

    &--round {
      background-color: var(--hover-background-color);
      border: 1px solid var(--hover-background-border-color);
      border-radius: 100%;
    }

    /* Icon Container Size */
    &__size {
      &--regular {
        height: 40px;
        width: 40px;
      }

      &--small {
        height: 16px;
        width: 16px;
      }

      &--large {
        height: 54px;
        width: 54px;
      }
    }

    /* Container Background Color */
    &__background-color {
      &--transparent {
        background-color: transparent;

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--gray {
        background-color: var(--color-cod-gray-400);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--dynamic {
        background-color: var(--color-main-dynamic);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--red {
        background-color: var(--color-supreme-red);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--delete {
        background-color: var(--color-burnt-sienna);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--white {
        background-color: var(--color-gin);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--black {
        background-color: var(--color-cod-gray-400);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }

      &--boulder {
        background-color: var(--color-boulder);

        &--active {
          &:active:not(&--active) {
            opacity: .85;
          }
        }
      }
    }
  }

  /* Icon Color */
  &__icon {
    display: block;

    &__color {
      &__g-path {
        &--gray g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-gray);
        }

        &--dynamic g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-dynamic);
        }

        &--red g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-red);
        }

        &--delete g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-red);
        }

        &--white g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-white);
        }

        &--black g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-black);
        }

        &--boulder g path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-boulder);
        }
      }

      &__path {
        &--gray path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-gray);
        }

        &--dynamic path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-dynamic);
        }

        &--red path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-red);
        }

        &--delete path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-delete);
        }

        &--white path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-white);
        }

        &--black path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-black);
        }

        &--boulder path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-boulder);
        }
      }

      &__stroke-path {
        &--gray path {
          transition: stroke .2s ease-in-out;
          stroke: var(--hover-icon-color-gray);
        }

        &--dynamic path {
          transition: stroke .2s ease-in-out;
          stroke: var(--hover-icon-color-dynamic);
        }

        &--red path {
          transition: fill .2s ease-in-out;
          stroke: var(--hover-icon-color-red);
        }

        &--delete path {
          transition: stroke .2s ease-in-out;
          stroke: var(--hover-icon-color-delete);
        }

        &--white path {
          transition: stroke .2s ease-in-out;
          stroke: var(--hover-icon-color-white);
        }

        &--black path {
          transition: stroke .2s ease-in-out;
          stroke: var(--hover-icon-color-black);
        }

        &--boulder path {
          transition: fill .2s ease-in-out;
          fill: var(--hover-icon-color-boulder);
        }
      }

      &__path-stroke-fill {
        &--gray {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-gray);
            stroke: var(--hover-icon-color-gray);
          }
        }

        &--dynamic {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-dynamic);
            stroke: var(--hover-icon-color-dynamic);
          }
        }

        &--red {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-red);
            stroke: var(--hover-icon-color-red);
          }
        }

        &--delete {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-delete);
            stroke: var(--hover-icon-color-delete);
          }
        }

        &--white {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-white);
            stroke: var(--hover-icon-color-white);
          }
        }

        &--black {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-black);
            stroke: var(--hover-icon-color-black);
          }
        }

        &--boulder {
          path {
            transition: all .2s ease-in-out;
            fill: var(--hover-icon-color-boulder);
            stroke: var(--hover-icon-color-boulder);
          }
        }
      }
    }

    &__flip {
      &--horizontal {
        transform: scaleX(-1);
      }

      &--vertical {
        transform: scaleY(-1);
      }
    }
  }

  /* Icon Container Hover */
  &__icon-hover {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        --hover-icon-color-gray: var(--color-gin);
        --hover-icon-color-boulder: var(--color-gin);
        --hover-icon-color-dynamic: var(--color-hover-dynamic);
        --hover-icon-color-red: var(--color-gin);
        --hover-icon-color-delete: var(--color-gin);
        --hover-icon-color-white: var(--color-gray-200);
        --hover-icon-color-black: var(--color-black);
      }
    }
  }

  /* Icon Hover is Active */
  &__icon-hover-active {
    --hover-icon-color-gray: var(--color-gin);
    --hover-icon-color-boulder: var(--color-gin);
    --hover-icon-color-dynamic: var(--color-hover-dynamic);
    --hover-icon-color-red: var(--color-gin);
    --hover-icon-color-delete: var(--color-gin);
    --hover-icon-color-white: var(--color-gray-200);
    --hover-icon-color-black: var(--color-black);
  }

  /* Icon Container Hover */
  &__background-hover {
    @media (hover: hover) {
      &:hover {
        --hover-background-color: var(--color-cod-gray-500);
      }
    }

    &__border {
      @media (hover: hover) {
        &:hover {
          --hover-background-border-color: var(--color-cod-gray-400);
        }
      }

      /* Icon Container Border Active */
      &--active {
        &:active:not(&--active) {
          --hover-background-border-color: var(--color-cod-gray-300);
        }
      }
    }

    /* Icon Container Active */
    &--active {
      &:active:not(&--active) {
        --hover-background-color: var(--color-cod-gray-100);
        --hover-icon-color-gray: var(--color-gin);
      }
    }
  }

  /* Icon Background on Hover */
  &__background-hover-active {
    --hover-background-color: var(--color-cod-gray-500);

    &__border {
      --hover-background-border-color: var(--color-cod-gray-400);
    }
  }
}
