.account-plan {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  flex: 1;

  &__plan-container {
    padding-top: var(--top-padding);

    @media (--from-tablet-landscape) {
      padding-top: calc(var(--top-padding) * 2);
    }

    &--layout {
      padding-top: var(--top-padding);
      width: 100%;
      display: flex;
      gap: var(--left-padding);
      flex-direction: column-reverse;

      @media (--from-tablet-landscape) {
        display: grid;
        grid-template-columns: repeat(auto-fill, 316px);
      }
    }
  }

  &__promotion-text {
    font: 500 1.8rem var(--font-gerstner);
    color: var(--color-gray-200);
    letter-spacing: .5px;
    padding-top: calc(var(--top-padding) * 2);
  }

  &__cancel-text {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    letter-spacing: .35px;
    transition: color .2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        color: var(--color-pumice);
      }
    }
  }

  &__support {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__support-text {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-main-dynamic);
    letter-spacing: .35px;
    transition: color .2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
      }
    }
  }

  &__subtitle {
    font: 500 1.4rem var(--font-gerstner);
    padding-top: var(--top-padding);
    color: var(--color-gray-200);
    max-width: 54rem;
    letter-spacing: .25px;
    min-height: 7.8rem;
  }

  &__cancelled_info {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gray-200);
    max-width: 54rem;
    letter-spacing: .25px;
  }

  &__section-title {
    padding-bottom: var(--top-padding);
    padding-top: calc(var(--top-padding) * 2);
    color: var(--color-gray-200);
  }

  &__description-create--item {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    font: 300 1.4rem/2rem var(--font-gerstner);
    gap: 8px;

    svg {
      > path {
        stroke: var(--color-main-dynamic);
      }
    }
  }
}
