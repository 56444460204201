.predictive-search {
  &__container {
    background: var(--color-cod-gray-100);
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
    max-width: 100%;
    padding: 0 var(--left-padding) var(--left-padding);
    position: absolute;
    width: 100%;
    overflow: hidden;

    @media (--tablet) {
      left: 0;
      right: 0;
      top: calc(1px + var(--header-height));
      height: 100%;
      position: fixed;
    }

    @media (--from-tablet-landscape) {
      width: 48rem;
      max-height: 79.4rem;
      margin-top: -1px;
      border: 1px solid var(--color-cod-gray-400);
    }
  }

  &__divider {
    height: 1px;
    background-color: var(--color-cod-gray-400);
    margin-top: var(--left-padding);
    margin-left: calc(-1 * var(--left-padding));
    margin-right: calc(-1 * var(--left-padding));
  }

  &__checkboxes-container {
    height: 78px;
    background-color: var(--color-cod-gray-300);
    border-bottom: 1px solid var(--color-cod-gray-400);
    margin-left: calc(-1 * var(--left-padding));
    margin-right: calc(-1 * var(--left-padding));
    padding: var(--left-padding);
  }

  &__search-text {
    color: var(--color-tundora);
    font: 500 1.1rem var(--font-gerstner);
    padding-bottom: 8px;
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.6rem var(--font-gerstner);
  }

  &__row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--top-padding) 0;
  }

  &__thin-text {
    color: var(--color-gray-200);
    font: 300 1.2rem var(--font-gerstner);
    letter-spacing: .05em;

    &:hover {
      text-decoration: underline;
    }
  }

  &__list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &--double {
      align-items: center;
      flex-direction: row;
      text-align: start;
    }

    &-artist {
      flex-direction: row;
      align-items: center;
    }

    svg {
      display: none;

      path {
        fill: var(--color-gin);
      }
    }

    @media (--from-tablet-landscape) {
      &:hover {
        opacity: .7;

        svg {
          display: initial;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__content-title_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 1rem;
  }

  &__content-title {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .035em;
  }

  &__content-label {
    color: var(--color-gin);
    font: 300 1.2rem/1.4rem var(--font-gerstner);
    letter-spacing: .04em;
  }

  &__img-container {
    height: 56px;
    width: 56px;
  }

  &__image {
    border-radius: 100%;
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__name-initials {
    display: flex;
    background-color: var(--color-tundora);
    color: var(--color-gray-200);
    font: 500 2rem/2.6rem var(--font-gerstner);
    inset: 0;
    padding: 16px;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
}
