.news {
  @media (--from-tablet-landscape) {
    margin-right: 64px;
  }

  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    > h2 {
      color: var(--color-gin);
    }
  }

  &__title {
    color: var(--color-gin);
    margin-bottom: 1rem;
  }

  &__grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;

    @media (--from-tablet) {
      grid-template-columns: repeat(auto-fill, 234px);
    }

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 296px);
    }
  }

  &__big-card {
    color: var(--color-gin);
    display: grid;
    grid-row-gap: 24px;
    grid-template-columns: 1fr;
    margin-bottom: 32px;

    @media (--from-tablet-landscape) {
      align-items: flex-start;
      display: flex;
      gap: 32px;
      margin-right: 42px;
      margin-top: 32px;
    }

    @media (--from-desktop) {
      margin-right: 103px;
    }
  }

  &__big-card-img-container {
    background-color: var(--color-cod-gray-500);
    padding: 16px;

    @media (--from-tablet-landscape) {
      flex: 0 0 50%;
    }

    @media (--from-desktop) {
      flex: 0 0 608px;
    }
  }

  &__big-card-title {
    display: block;
    font: 500 2.4em/3.2rem var(--font-gerstner);

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__big-card-subtitle {
    font: 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin: 16px 0;

    @media (--from-tablet-landscape) {
      font-size: 1.8rem;
    }
  }

  &__big-card-text-content {
    font: 1.6rem/2rem var(--font-gerstner);
    letter-spacing: .4px;

    /* cut the text to a max of 6 lines */
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    display: -webkit-box;

    -webkit-line-clamp: 6;
    overflow-y: hidden;
    text-overflow: ellipsis;
    /* stylelint-enable */

    @media (--from-tablet-landscape) {
      line-height: 2.4rem;
    }
  }

  &__big-card-cta {
    margin-top: 16px;
    padding: 8px 24px;
  }

  > h3 {
    color: var(--color-gin);
    margin: 32px 0;
  }
}
