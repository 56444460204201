.missing-img-placeholder {
  align-items: center;
  background-color: var(--color-cod-gray-100);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 100%;

  > svg {
    height: 32px;
    width: 32px;
  }
}
