.three-dots-menu {
  &__red {
    color: var(--color-burnt-sienna);
    transition: color .2s linear;

    &--hover {
      color: var(--color-gin);
    }
  }

  &__container {
    position: absolute;
    z-index: var(--three-dots-sheet-z-index);

    @media (--mobile) {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      display: block;
      z-index: var(--three-dots-sheet-mobile-z-index);
      position: fixed;
    }

    &--bottom-bar-visible {
      padding-bottom: 120px;
    }
  }

  &__list {
    background-color: var(--color-cod-gray-500);
    display: none;
    right: 100%;
    top: 0;
    width: 200px;
    position: absolute;
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);

    @media (--mobile) {
      width: 100%;
      height: 100%;
      position: sticky;
      padding-bottom: max(2.6rem, calc(calc(env(safe-area-inset-bottom) * 2) + 2.6rem));
    }

    @media (--from-tablet) {
      &--auto-width {
        width: min-content;
      }
    }

    &--open {
      display: block;
      right: 100%;

      @media (--mobile) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
    }

    &--display-option {
      position: absolute;
      right: 200px;
      background-color: var(--color-cod-gray-500);
      width: min-content;
      /* stylelint-disable-next-line length-zero-no-unit */
      top: 0px;

      @media (--mobile) {
        position: unset;
        margin-top: auto;
        width: 100%;
        background-color: transparent;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: center;

    @media (--from-tablet) {
      width: 200px;
    }

    &__title {
      border-bottom: 1px solid var(--color-cod-gray-400);
    }

    &--downloadable {
      background-color: var(--color-cod-gray-400);

      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &__button {
      align-items: center;
      color: var(--color-gray-200);
      display: flex;
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      letter-spacing: .1px;
      transition: color .2s;
      width: 100%;

      @media (--from-tablet) {
        padding: 8px 4px;
      }

      &--active {
        color: var(--color-gin);
      }

      &--cancel-btn,
      &--done-btn {
        font: 500 1.6rem/2.4rem var(--font-gerstner);
      }

      &--done-btn {
        color: var(--color-gin);
      }

      &--cancel-btn {
        color: var(--color-burnt-sienna);
      }

      @media (hover: hover) {
        &:not([disabled])&:hover {
          color: var(--color-gin);
        }
      }

      &[disabled] {
        opacity: .25;
      }

      > span {
        margin-inline-start: 8px;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        @media (--mobile) {
          overflow: visible;
        }
      }

      > svg {
        flex: 0 0 24px;
      }

      > svg > path {
        fill: currentColor;
      }
    }
  }

  &__btn-extra-label {
    color: var(--color-main-dynamic);
    text-align: right;
    min-width: 60px;
  }

  &__btn {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    transition: color .2s;
    width: 100%;
    padding: 8px;

    @media (--mobile) {
      padding: 10px 20px;
    }

    &--downloadable {
      font: 500 1.1rem var(--font-gerstner);
      padding: 8px 12px;

      > span {
        margin-inline-start: 0 !important;
        text-align: end !important;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }

    > span {
      margin-inline-start: 8px;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > svg {
      flex: 0 0 24px;
    }

    > svg > path {
      fill: currentColor;
    }

    &--icon-unFavorite {
      > svg > path {
        fill: transparent;
        stroke: currentColor;
      }
    }

    &--icon-favorite {
      > svg > path {
        fill: currentColor;
        stroke: currentColor;
      }
    }

    &--icon-findRemixes {
      :global(.stroke-svg) {
        stroke: currentColor;
      }

      :global(.fill-svg) {
        fill: currentColor;
      }

      &--disable {
        :global(.stroke-svg) {
          stroke: var(--color-dove-gray);
          fill: none;
        }

        :global(.fill-svg) {
          fill: var(--color-dove-gray);
        }

        @media (hover: hover) {
          &:hover {
            color: var(--color-dove-gray);

            :global(.stroke-svg) {
              stroke: var(--color-dove-gray);
              fill: none;
            }

            :global(.fill-svg) {
              fill: var(--color-dove-gray);
            }
          }
        }
      }
    }

    &--active {
      color: var(--color-gin);
    }

    &--disable {
      user-select: none;
      color: var(--color-dove-gray);

      > svg > g > path {
        fill: var(--color-dove-gray);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-dove-gray);

          > svg > g > path {
            fill: var(--color-dove-gray);
          }
        }
      }
    }

    &--disable-text {
      font: 300 1.1rem var(--font-gerstner);
      width: 100px;
      text-align: right;
      user-select: none;
      color: var(--color-dove-gray);
    }
  }

  &__divider {
    width: 100% !important;
    font-size: 1.3rem;
    color: var(--color-tundora);
    margin-top: -4px;
    display: inline-block;
    text-align: end;
    margin-right: 18px;
  }

  &__divider-preset {
    margin-right: 6px;
  }
}
