.toast-close-button {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-left: 1rem;
  width: 13rem;

  &--title {
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-main-dynamic);
    white-space: pre-line;
    text-align: right;

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
        text-decoration: var(--plain-link-text-decoration-dynamic);
      }
    }
  }

  &--close-button {
    align-items: center;
    padding-top: .5rem;
    display: none;

    @media (--from-tablet) {
      display: inline;
    }
  }
}
