.crate-detail {
  &__img-container {
    /* TODO: replace this color with the one coming from the api */
    background-color: var(--color-cod-gray);
    color: var(--color-white);
    height: 136px;
    padding: 2px 8px;
    position: relative;

    @media (--from-tablet-landscape) {
      height: 296px;
      padding: 18px 24px;
    }

    &--black-text {
      color: var(--color-black);
    }

    >span {
      font: 4.8rem/4.8rem var(--font-gerstner);

      @media (--from-tablet-landscape) {
        font-size: 6.4rem;
        line-height: 6.4rem;
      }
    }
  }

  &__image {
    bottom: 0;
    height: 50%;
    position: absolute;
    right: 0;
    width: 50%;
  }

  &__title-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    >h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__more-dots-btn {
    flex-shrink: 0;
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    >*:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }

  &__description {
    color: var(--color-gin);
    font: 1.6rem/2rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (--from-tablet-landscape) {
      line-height: 2.4rem;
    }
  }

  &__main-btn {
    align-items: center;
    background-color: var(--color-main-dynamic);
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
  }

  &__spinner {
    align-items: center;
    background-color: var(--color-cod-gray);
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
    animation: var(--spin-animation) 2s linear infinite;
  }

  &__download-btn {
    transition: all .25s;

    &:hover {
      filter: opacity(.7);
    }

    svg > path {
      fill: var(--color-main-dynamic);
    }
  }

  &__delete-btn {
    transition: all .25s;

    &:hover {
      filter: opacity(.7);
    }

    svg > path {
      fill: var(--color-gray-200);
    }
  }

  &__empty {
    align-items: center;
    background-color: var(--color-cod-gray-500);
    color: var(--color-gray-200);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 16px 0;
    padding: 28px 0;

    &--back {
      text-decoration: underline;
    }
  }

  &__progress-popup {
    max-width: 600px;

    &-title {
      font: 1.6rem/1.8rem var(--font-gerstner);
      display: flex;
      align-items: center;

      @media (--from-tablet-landscape) {
        font: 2.4rem/3.2rem var(--font-gerstner);
      }

      svg {
        margin-right: 1rem;
        animation: var(--spin-animation) 2s linear infinite;
      }
    }
  }
}
