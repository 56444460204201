.artist-portal-list {
  --default-number-width: 102px;
  margin-bottom: 16px;

  &__list {
    margin-bottom: 4px;
    width: 100%;
  }

  &__list-heading {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .08em;
    text-align: left;
    text-transform: uppercase;
    background: var(--color-cod-gray-300);

    &--album-analytics-small {
      display: none !important;
    }
  }

  &__list-heading,
  &__list-item,
  &__download-accordion {
    align-items: center;
    display: grid;
    padding: 8px 0;
  }

  &__list-heading,
  &__list-item {
    max-width: 100%;
    border: 1px solid var(--color-cod-gray-400);

    &--location {
      grid-template-columns: [count] 24px [flag] 60px [source] 1fr [downloads] auto [actions] 4rem;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [flag] 60px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width) [actions] 4rem;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [flag] 60px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [actions] 4rem;
      }
    }

    &--sources {
      grid-template-columns: [count] 24px [icon] 60px [source] 1fr [downloads] auto [actions] 4rem;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [icon] 60px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [actions] 4rem;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [icon] 60px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [actions] 4rem;
      }
    }

    &--dj-type {
      grid-template-columns: [count] 24px [source] 1fr [downloads] auto [actions] 4rem;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [actions] 4rem;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [source] 1fr [percentage] var(--default-number-width)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [actions] 4rem;
      }
    }

    &--album-analytics {
      grid-template-columns: [accordion] 24px [title] 1fr [downloads] auto [actions] 4rem;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 24px [title] minmax(262px, 1fr)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [date] 108px [actions] 1fr;
      }

      @media (--from-desktop) {
        grid-template-columns:
          [accordion] 24px [title] minmax(auto, 1fr)
          [previews] var(--default-number-width) [downloads] var(--default-number-width)
          [estreach] var(--default-number-width) [impressions] var(--default-number-width)
          [date] 108px [actions] 1fr;
      }
    }

    &--album-analytics-small {
      grid-template-columns: [title] auto [downloads] var(--default-number-width);
    }
  }

  &__list-item {
    border: 0 1px 1px 1px solid var(--color-cod-gray-500);

    &--album-analytics-small {
      border: none;
    }

    &--sources,
    &--dj-type,
    &--location {
      height: 62px;
    }
  }

  &__column {
    &--source {
      font: 500 1.4rem/2rem var(--font-gerstner);
      color: var(--color-gin);
    }
  }

  &__track-container {
    padding: 0 calc(2 * var(--left-padding));
  }

  &__heading-column,
  &__column {
    &--actions {
      justify-self: end;
      display: flex;
      margin-right: var(--half-padding);
    }

    &--previews,
    &--downloads,
    &--estreach,
    &--impressions,
    &--date,
    &--percentage {
      margin-right: 16px;

      @media (--from-tablet-landscape) {
        margin-right: 16px;
      }

      @media (--tablet) {
        display: none;
      }

      &--visible-type {
        @media (--tablet) {
          display: block;
        }
      }

      &--album-analytics-small {
        margin-right: 0;
        text-align: right;
      }
    }

    &--title-without-artist,
    &--title {
      display: flex;
      margin-right: 16px;
    }

    &__flag {
      &-emoji {
        font: 500 1.6rem/2rem var(--font-gerstner);
      }

      &-container {
        width: 34px;
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--color-cod-gray-500);
        border: 1px solid var(--color-tundora);
        border-radius: 2px;
      }
    }

    &--count {
      text-align: center;
    }
  }

  &__list-item,
  &__download-accordion {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .033em;
    text-align: left;
    transition: background-color .2s, color .2s;
  }

  &__download-accordion {
    background-color: var(--color-cod-gray-300);
  }

  &__download-accordion-trigger {
    color: inherit;

    &--open > svg {
      transform: rotate(90deg);
    }
  }

  &__action-btn-container {
    @media (--from-tablet-landscape) {
      display: flex;
      margin-right: 1rem;
    }
  }

  &__action-btn-desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }

    > svg > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-gin);
      }
    }
  }

  :global {
    .ReactCollapse--collapse {
      transition: height 300ms;
    }
  }

  &__source-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--color-cod-gray-500);
    border: 1px solid var(--color-tundora);
    width: 34px;
    height: 34px;
    border-radius: 2px;
  }
}
