.playlist-cover-image {
  &__image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 400px;
    margin: 0 auto;
  }

  &__image-item {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__collaborative-hover {
    background-color: rgba(var(--color-black-russian-rgb) / 80%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: opacity .2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }

  &__collaborative-img-container {
    background-color: var(--color-cod-gray);
    color: var(--color-white);
    height: 136px;
    width: 136px;
    position: relative;

    @media (--from-tablet-landscape) {
      height: 296px;
      width: 296px;
    }

    &--full {
      height: 100%;
      width: 100%;
    }
  }

  &__quarter-image {
    box-sizing: border-box;
    height: 50%;
    width: 50%;
  }

  &__img-container {
    /* TODO: replace this color with the one coming from the api */
    background-color: var(--color-cod-gray);
    color: var(--color-white);
    height: 136px;
    width: 136px;
    position: relative;

    &--full {
      height: 100%;
      width: 100%;
    }

    @media (--from-tablet-landscape) {
      height: 296px;
      width: 296px;
    }

    > svg {
      bottom: 0;
      height: 100px;
      position: absolute;
      right: 0;
      width: 100px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__missing-img-placeholder {
    align-items: center;
    background-color: var(--color-cod-gray-400);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 100%;

    > svg {
      height: 24px;
      width: 24px;

      @media (--from-tablet-landscape) {
        height: 40px;
        width: 40px;
      }
    }
  }
}
