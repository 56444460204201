.search-input {
  --highlight-color: var(--color-gray-200);
  --highlight-bottom-bar: var(--color-cod-gray-400);
  position: relative;
  width: 100%;

  @media (hover: hover) {
    &:hover {
      --highlight-color: var(--color-gin);
      --highlight-bottom-bar: var(--color-tundora);
    }
  }

  &__alternate-mobile-layout {
    @media (--tablet) {
      --highlight-color: var(--color-gray-200);
      --highlight-bottom-bar: var(--color-cod-gray-400);
      width: 100%;
      padding-left: 16px;
      background: var(--color-cod-gray-400);
      border: none;
      border-radius: 100px;
      color: var(--color-alabaster);
      font: 300 20rem var(--font-gerstner);
      outline: none;
      height: 4rem;
      align-items: center;
      padding-top: 1px;
    }
  }

  &__large {
    @media (--from-tablet-landscape) {
      height: 4.2rem;
      width: 30rem;
      background-color: var(--color-cod-gray-500);
      border: 1px solid var(--color-cod-gray-400);
      border-radius: 100px;
      padding-left: 16px;
    }

    @media (--from-desktop) {
      width: 48rem;
    }
  }

  &__small {
    width: 245px;
    border-radius: 8px;
    border: 1px solid var(--highlight-bottom-bar);
  }

  &__input {
    background: none;
    border: none;
    border-bottom: 1px solid var(--highlight-bottom-bar);
    border-radius: 0;
    color: var(--color-gin);
    display: block;
    font: 300 1.4rem var(--font-gerstner);
    letter-spacing: .25px;
    padding: 8px;
    transition: background .2s, color .2s, border-color .2s;
    width: 100%;
    padding-left: 30px;
    height: 100%;

    &[data-focus-visible-added] {
      --highlight-color: var(--color-gin);
      outline: none;
    }

    &::placeholder {
      color: var(--highlight-color);
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      transition: color .2s;
    }

    &--small {
      font: 500 1.2rem/1.6rem var(--font-gerstner) !important;
      color: var(--color-gin);
      border-bottom: none;
      padding-left: 40px;
    }

    &--large {
      font: 500 1.4rem var(--font-gerstner);
      color: var(--color-gin);
      border-bottom: none;

      &[data-focus-visible-added] {
        --highlight-color: var(--color-gray-200);
        outline: none;
        padding-left: 30px;
      }

      &::placeholder {
        font: 300 1.4rem var(--font-gerstner);
        color: var(--color-gray-200);
        opacity: 1;
      }
    }
  }

  &__search-filters {
    position: absolute;
    right: 40px;
    top: calc(50% - 12px);
  }

  &__input:placeholder-shown ~ &__search-filters {
    display: none;
  }

  &__clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &--large {
      right: 5px;

      @media (--from-tablet-landscape) {
        right: 0;
      }
    }
  }

  &__icon-alternate,
  &__icon {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);

    path {
      transition: stroke .2s, fill .2s;
    }

    >path {
      stroke: var(--highlight-color);
    }
  }

  &__icon {
    &--large {
      top: 40%;
      transform: translateY(-60%);
      left: 6px;

      >path {
        stroke: var(--highlight-color);
      }
    }
  }

  &__icon-alternate {
    &--large {
      left: 12px;

      >path {
        fill: var(--highlight-color);
        stroke: none;
      }
    }
  }

  &__input--alternate-mobile-layout ~ &__icon-alternate {
    @media (--tablet) {
      left: 12px;
      right: auto;
    }
  }

  &__input:placeholder-shown ~ &__icon {
    display: block;
  }

  &__input:placeholder-shown ~ &__clear {
    display: none;
  }
}
