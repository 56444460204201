:root {
  --nav-z-index: 110;
  --nav-mobile-z-index: 500;
  --supreme-mobile-header-z-index: 100;
  --maxi-player-z-index: 120;
  --mini-player-z-index: 120;
  --three-dots-sheet-z-index: 130;
  --three-dots-sheet-mobile-z-index: 600;
  --user-menu-z-index: 130;
}
