.now-playing-animation {
  transition: visibility .2s, opacity .2s;

  svg {
    path {
      height: 56px;
      width: 56px;
      fill: var(--color-main-dynamic);
    }
  }

  &--artist {
    svg {
      path {
        fill: var(--color-supreme-red);
      }
    }
  }
}
