/* For simplicity and consistency, track-list-create-item and track-list-create components
are both using the styles defined in this file.
track-list-create-item does not have its own .module.css file. */
.track-list-create {
  margin-bottom: 16px;

  &__tag-view {
    margin: 2rem 0;

    &--ghost-spacer {
      margin-bottom: 8px;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .006em;
    margin-bottom: 0;
    margin-right: auto;

    span {
      font-size: 1.8rem;
      letter-spacing: .0277em;
      line-height: 2.4rem;
    }
  }

  &__image-overlay {
    align-items: center;
    background-color: rgb(var(--color-black-russian-rgb) / 50%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__list {
    margin-bottom: 4px;
    width: 100%;
    transition: ease-out 50ms;
    transform: opacity;

    &--fadeOut {
      opacity: 0;
    }
  }

  &__list-heading {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .08em;
    text-align: left;
    text-transform: uppercase;
  }

  &__list-heading,
  &__list-item {
    align-items: center;
    border-left: 2px solid transparent;
    display: grid;
    grid-template-columns: [count] 24px [image] 64px [title] 1fr [actions] 24px;
    padding: 8px 0;

    @media (--from-tablet-landscape) {
      grid-template-columns:
        [count] 24px [image] 48px [play] 32px [wave] 4fr [title] 4fr
        [bpm] 69px [key] 69px [time] 69px [tags] 6fr [actions] 88px;
    }

    &__preset {
      grid-template-columns: [count] 24px [image] 64px [title] 1fr [actions] 24px;

      &--collboration {
        @media (--from-tablet-landscape) {
          grid-template-columns: [count] 24px [image] 64px [title] 1fr [actions] 48px;
        }
      }

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [image] 48px [play] 32px [wave] 4fr [title] 4fr
          [synth] 87px [genre] 120px [tags] 6fr [actions] 88px;
      }
    }

    &--collboration {
      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [image] 48px [play] 32px [wave] 4fr [title] 4fr
          [bpm] 69px [key] 69px [time] 69px [tags] 6fr [actions] 120px;
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      transition: border-color .25s;
    }

    &--wave {
      grid-template-columns: [count] 24px [image] 64px [wave] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [image] 48px [play] 32px [wave] 4fr [title] 4fr
          [bpm] 69px [key] 69px [time] 69px [tags] 6fr [actions] 88px;
      }
    }
  }

  &__list-item {
    --hover-button-opacity: 0;
    color: var(--color-gray-200);
    cursor: default;
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .033em;
    text-align: left;
    transition: background-color .2s, color .2s;

    @media (--from-tablet-landscape) {
      min-height: 60px;
    }

    @media (hover: hover) {
      &:hover {
        --hover-button-opacity: 1;
        background-color: var(--color-cod-gray-300);
        color: var(--color-gin);
      }
    }
  }

  &__sort-btn {
    display: none;
  }

  &__list-heading--sortable &__sort-btn {
    display: block;
  }

  &__heading-column {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .08em;
    text-align: left;
    text-transform: uppercase;

    &--title {
      @media (--from-tablet-landscape) {
        grid-column: 2 / span 4;
      }
    }
  }

  &__row {
    align-items: center;
    display: flex;
  }

  &__midi {
    align-items: center;
    border: 1px solid var(--color-gin);
    color: var(--color-gin);
    display: flex;
    font: 500 .8rem/1rem var(--font-gerstner);
    height: 14px;
    justify-content: center;
    letter-spacing: .007em;
    margin-left: 10px;
    min-width: 27px;

    @media (--from-tablet-landscape) {
      margin-left: 0;
      margin-right: 12px;
    }
  }

  &__preset {
    align-items: center;
    border: 1px solid var(--color-gin);
    color: var(--color-gin);
    display: none;
    font: 500 .8rem/1rem var(--font-gerstner);
    height: 14px;
    justify-content: center;
    letter-spacing: .007em;
    min-width: 40px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 0;
    margin-right: 12px;

    @media (--from-desktop) {
      display: flex;
    }
  }

  &__heading-column,
  &__column {
    [disabled] {
      opacity: .25;
    }

    &--count {
      display: flex;
      justify-content: center;
    }

    &--image {
      position: relative;
    }

    &--wave,
    &--image,
    &--play {
      display: flex;
      margin-right: 8px;
    }

    &--bpm,
    &--key,
    &--time,
    &--tags,
    &--synth,
    &--genre,
    &--play {
      display: none;

      @media (--from-tablet-landscape) {
        display: flex;
      }
    }

    &--bpm,
    &--key,
    &--time,
    &--tags,
    &--genre,
    &--synth,
    &--wave {
      @media (--from-tablet-landscape) {
        margin-right: 16px;
      }
    }
  }

  &__column {
    &--title {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      margin-right: 16px;
    }

    &--hidden-desktop {
      display: none;

      @media (--from-tablet-landscape) {
        display: grid;
      }
    }

    &--wave {
      position: relative;
      flex-direction: column;

      &--preset {
        position: relative;
        display: flex;
        height: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    &--actions {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }

    &--collboration {
      display: none;

      @media (--from-tablet-landscape) {
        display: block;
        transition: opacity .2 ease-in-out;
        opacity: var(--hover-button-opacity);
      }
    }
  }

  &__action-btn-desktop {
    display: none;
    scroll-margin-top: 150px;

    @media (--from-tablet-landscape) {
      display: block;
    }

    > svg {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg {
        fill: var(--color-gin);
      }
    }

    &--favorite {
      color: var(--color-gray-200);
      transition: color .2s;

      > svg > path {
        fill: transparent;
        stroke: currentColor;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);
        }
      }
    }

    &--download > svg {
      width: 18px;
      height: 18px;
    }

    &--is-downloaded > svg path {
      fill: var(--color-main-dynamic);
    }

    &--is-favorite > svg > path {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  &__sound-third-line-mobile-content {
    color: var(--color-gray-200);
    font: 300 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (--from-tablet-landscape) {
      display: none;
    }

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }

  &__play-btn {
    display: flex;

    > svg > path {
      fill: var(--color-gin);
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:not([disabled])&:hover > svg > path {
        fill: var(--color-gray-200);
      }
    }
  }

  &__sound-pack,
  &__sound-artist {
    color: var(--color-gin);
    display: flex;
    font: 300 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sound-pack {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__sound-title-container {
    align-items: center;
    display: flex;
  }

  &__sound-title {
    color: var(--color-gin);
    font: 500 1.3rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--from-tablet-landscape) {
      font-size: 1.4rem;
    }
  }

  &__ghost-loading {
    margin: 8px 0;
  }

  &__artwork-container {
    --hover-opacity: 0;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        --hover-opacity: 1;
      }
    }

    &--hover-container {
      border-radius: .3rem;
      opacity: var(--hover-opacity);
      background-color: var(--color-cod-gray-400);
      position: absolute;
      bottom: -1.9rem;
      left: 0;
      display: flex;
      z-index: 3;
      max-width: 30rem;

      &__last-item {
        bottom: 41px;
      }
    }

    &--artwork-title {
      padding: .5rem 1.2rem;
      font: 500 1.1rem var(--font-gerstner);
      color: var(--color-gin);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      word-break: break-all;
      text-overflow: ellipsis;
    }
  }
}
