.quick-actions-column {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;

  @media (--from-tablet) and (hover: hover) {
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;

    &--visible,
    :global(.track-list-item:hover) & {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media (--from-tablet) and (hover: none) {
    justify-content: flex-start;
  }

  @media (--from-desktop) {
    justify-content: center;
  }

  &__button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      transition: fill .2s ease-out, stroke .2s ease-out;
    }

    &:not([disabled]):hover {
      path {
        fill: var(--color-gin);
      }
    }

    &:not([disabled]):active {
      path {
        fill: var(--color-gin);
      }
    }

    &--three-dots {
      &:not([disabled]):hover,
      &:not([disabled]):active {
        path {
          stroke: var(--color-gin);
        }
      }
    }

    &--tablet-up-only {
      display: none;

      @media (--from-tablet-landscape) {
        display: flex;
      }
    }
  }
}
