.checkbox {
  --size: 24px;
  display: inline-block;
  width: var(--size);
  height: var(--size);

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: .01;
  }

  &__ui {
    --fill-color: var(--color-main-dynamic);
    --stroke-color: transparent;
    --tick-color: #121212;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 2px;

    input:not(:checked) + & {
      --fill-color: transparent;
      --stroke-color: currentColor;
      --tick-color: transparent;
    }

    input:focus-visible + & {
      box-shadow: 0 0 0 2px white;
    }
  }
}
