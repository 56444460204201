.sort-options-menu {
  &__container {
    position: absolute;
    z-index: 100;
  }

  &__list {
    background-color: var(--color-cod-gray-300);
    display: none;
    position: absolute;
    width: 200px;

    &--open {
      display: block;
      right: 100%;
    }

    &--left {
      right: 100%;
    }

    &--right {
      left: 100%;
    }

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }
}

.sort-options-menu-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &__btn {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    transition: color .2s;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }

    >span {
      margin-inline-start: 8px;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  >svg {
    path {
      stroke: var(--color-gin);
    }
  }
}
