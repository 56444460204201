.featured-in {
  margin: 32px 8px;

  &__loading {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: 16px;

    &-item {
      height: 196px;
      min-width: 180px;
      width: 180px;
      background-color: #e6efe914;

      @media (--from-tablet-landscape) {
        height: 260px;
        min-width: 296px;
        width: 296px;
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__playlist-card {
    background-color: #e6efe90a;

    &:hover {
      background-color: #e6efe914;
    }
  }
}
