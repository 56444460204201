.sign-up-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--half-padding);

  &__action-modal {
    @media (--from-tablet-landscape) {
      max-width: 464px !important;
    }
  }

  &__images {
    --album-size-desktop: 130px;
    --album-size-mobile: 130px;
    --center-album-size-desktop: 150px;
    --center-album-size-mobile: 150px;
    --center-album-animation: running;
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    justify-content: center;

    @media (hover: hover) {
      &:hover {
        --center-album-animation: paused;
      }
    }

    @media (--from-tablet-landscape) {
      height: calc(40px + var(--center-album-size-desktop));
    }

    &--container {
      width: var(--album-size-mobile);
      min-width: var(--album-size-mobile);
      height: var(--album-size-mobile);
      min-height: var(--album-size-mobile);
      z-index: 3;
      transition: all .5s linear;
      object-fit: contain;

      @media (--from-tablet-landscape) {
        width: var(--album-size-desktop);
        height: var(--album-size-desktop);
        min-width: var(--album-size-desktop);
        min-height: var(--album-size-desktop);
      }

      /* @keyframes shake {
        0% { transform: rotate(.4deg); }
        50% { transform: rotate(-.4deg); }
        100% { transform: rotate(.4deg); }
      } */

      /* @media (hover: hover) {
        &:hover {
          opacity: 1;

          animation: shake 500ms infinite;
          animation-timing-function: linear;
          z-index: 5;
          box-shadow: 0 0 10px 10px rgba(var(--color-black-russian-rgb) / 10%);
        }
      } */
    }

    &--most-left {
      height: var(--album-size-mobile);
      width: 100%;
      mask-image: linear-gradient(to right, transparent 75%, rgba(var(--color-black-russian-rgb) / 30%));

      @media (--from-tablet-landscape) {
        height: var(--album-size-desktop);
      }
    }

    &--most-right {
      height: var(--album-size-mobile);
      width: 100%;
      mask-image: linear-gradient(to left, transparent 75%, rgba(var(--color-black-russian-rgb) / 30%));

      @media (--from-tablet-landscape) {
        height: var(--album-size-desktop);
      }
    }

    /* stylelint-disable */
    &--left {
        mask-image: linear-gradient(to right, transparent 20%, rgba(var(--color-black-russian-rgb) / 90%));
    }

    &--right {
        mask-image: linear-gradient(to left, transparent 20%, rgba(var(--color-black-russian-rgb) / 90%));
    }
    /* stylelint-enable */

    &--center {
      height: var(--center-album-size-mobile);
      width: var(--center-album-size-mobile);
      min-height: var(--center-album-size-mobile);
      min-width: var(--center-album-size-mobile);
      z-index: 4;

      /* animation: pulse 3s infinite;
      animation-timing-function: cubic-bezier(.47, 0, .745, .715);
      animation-play-state: var(--center-album-animation); */
      opacity: 1;
      box-shadow: 0 0 10px 10px rgba(var(--color-black-russian-rgb) / 20%);

      /* @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.02); }
        100% { transform: scale(1); }
      } */

      @media (--from-tablet-landscape) {
        height: var(--center-album-size-desktop);
        width: var(--center-album-size-desktop);
        min-height: var(--center-album-size-desktop);
        min-width: var(--center-album-size-desktop);
      }
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
    align-self: center;
    max-width: 400px;
  }

  &__title {
    text-align: center;
    font: 500 2.4rem var(--font-gerstner);
    color: var(--color-gin);
    margin-top: var(--top-padding);
  }

  &__subtitle {
    text-align: center;
    font: 500 1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
  }

  &__divider {
    margin: var(--top-padding) 0;
    border: 1px solid var(--color-cod-gray-400);
    transition: all .2s;

    @media (--from-tablet-landscape) {
      margin: var(--top-padding) 0 0 0;
      border: 0 solid var(--color-cod-gray-400);
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    gap: var(--half-padding);
    margin-bottom: var(--top-padding);
    align-self: center;
    max-width: 400px;

    &--text {
      font: 500 14px var(--font-gerstner);
      color: var(--color-gin);
      align-items: center !important;
      gap: 8px;

      @media (--from-tablet-landscape) {
        font: 500 16px var(--font-gerstner);
      }

      :first-child {
        margin-top: 2px;
      }
    }
  }

  &__buttons-container {
    display: flex;
    gap: var(--left-padding);
    align-self: center;
    margin-top: var(--half-padding);
    justify-content: center;
  }
}
