.card-image {
  &__image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 400px;
    margin: 0 auto;
  }

  &__image-item {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
