.player-wave-supreme {
  width: 100%;
  position: relative;

  &--mini {
    height: 32px;
    min-width: 328px;
  }

  &--maxi-mobile,
  &--maxi-tablet {
    height: 32px;
  }

  &--maxi-desktop {
    height: 48px;
  }
}
