.sign-up-tracklist {
  margin-top: calc(-1 * var(--top-padding));

  @media (--from-tablet-landscape) {
    margin-top: 0;
  }

  &__button-container {
    height: 480px;
    z-index: 20 !important;
    width: 100%;
    margin-top: -480px;

    @media (--from-tablet-landscape) {
      height: 400px;
      margin-top: -400px;
    }
  }

  &__button {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__tracks {
    padding-left: calc(var(--left-padding) * 1.5);
    padding-top: var(--half-padding);
    z-index: 2;

    @media (--from-tablet-landscape) {
      padding-left: var(--half-padding);
      padding-top: var(--half-padding);
    }
  }
}
