.context-menu {
  &__hidden-desktop {
    display: none;

    @media (--tablet) {
      display: block;
    }
  }

  &__sub-item {
    background-color: var(--color-cod-gray-300);
    left: 100%;
    top: calc(100% - 40px);
    position: absolute !important;
  }

  &__container {
    position: fixed;
    z-index: 1000;

    @media (--tablet) {
      width: 100%;
      height: 100%;
      bottom: 0;
      display: block;
      position: fixed;
      transition: visibility .4s linear, background .2s linear;
      background: rgb(var(--color-black-russian-rgb) / 60%);
      visibility: visible;
    }

    &--hide {
      @media (--tablet) {
        visibility: hidden;
        background: rgb(var(--color-black-russian-rgb) / 0%);
      }
    }

    &--cancel-btn {
      --padding-bottom: 0;
      color: var(--color-burnt-sienna);
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      height: 40px;
      margin: var(--top-padding) 0;
      padding-bottom: 0;

      &--bottom-bar-visible {
        padding-bottom: 150px;
      }
    }
  }

  &__list {
    background-color: var(--color-cod-gray-300);
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    display: block;
    right: 100%;
    transition: transform .2s ease-in-out;
    border-radius: 4px;
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);

    @media (--tablet) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      padding-top: var(--top-padding);
    }

    &--hide {
      @media (--tablet) {
        transform: translateY(100%);
      }
    }
  }
}

.context-menu-item {
  --main-color: var(--color-gray-200);
  user-select: none;
  display: flex;
  align-items: center;
  height: 40px;
  width: 206px;

  @media (--tablet) {
    width: 100%;
  }

  &__label {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--main-color);

    &--delete {
      color: var(--color-burnt-sienna);
      transition: color .2s;
    }
  }

  &__divider {
    height: 1px;
    width: 100%;
    display: flex;
    background-color: var(--color-cod-gray-400);
  }

  &__icon-container {
    width: 24px;
    margin: 0 var(--half-padding);

    @media (--tablet) {
      margin: 0 var(--left-padding);
    }

    &--placeholder {
      margin: 0 var(--half-padding);

      @media (--tablet) {
        margin: 0 var(--left-padding);
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      --main-color: var(--color-gin);

      svg path {
        fill: var(--main-color);
      }

      &__label {
        color: var(--color-gin);
      }

      span {
        color: var(--color-gin);
      }
    }
  }
}
