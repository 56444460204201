.folder-card {
  &__shared-folder {
    display: flex;
    flex: 1;
    background-color: var(--color-cod-gray-300);
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--top-padding);
    height: 100%;
  }
}
