.dropdown {
  --dropdown-text-color: var(--color-main-dynamic);
  --dropdown-text-color-hover: var(--color-hover-dynamic);
  --dropdown-text-font: 1.4rem/2.4rem var(--font-gerstner);
  --chevron-color: var(--color-tundora);
  --border-dropdown-text-color: var(--color-gray-200);
  --additional-label-text-color: var(--color-tundora);
  position: relative;

  &__flex {
    display: flex;
    flex: 1;
    height: 40px;

    @media (--from-tablet-landscape) {
      width: 192px !important;
      max-width: 192px !important;
    }
  }

  &__labels-container {
    display: flex;
    align-items: center;
  }

  &__chevron {
    rotate: 90deg;

    > path {
      stroke: var(--chevron-color);
      transition: stroke .2s;
    }

    &--open {
      --chevron-color: var(--color-gin);
      rotate: -90deg;
    }
  }

  &__new-mobile-style {
    @media (--tablet) {
      --dropdown-text-color: var(--color-gin);
      background: var(--color-cod-gray-300);
      padding: 12px 0 12px 8px;

      >button {
        width: 100%;
        text-align: left;

        &[data-focus-visible-added] {
          color: var(--color-gin);
        }

        &::after {
          right: 8px;
        }
      }
    }
  }

  &__button {
    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--dropdown-text-color);
      content: '';
      height: 0;
      position: absolute;
      right: 0;
      top: calc(50% + 1px);
      transform: translateY(-50%);
      transition: border-top-color .2s;
      width: 0;
    }

    &--none {
      box-shadow: none;
      outline: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      color: var(--color-gray-200);
      font: 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .35px;
      padding: 10px 17px 10px 0;
      text-align: start;
      width: 100%;

      &[data-focus-visible-added],
      &:hover {
        color: var(--color-gin);
        outline: none;
      }

      &::after {
        border-top-color: inherit;
      }
    }

    &--text-input {
      box-shadow: none;
      outline: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      color: var(--color-gray-200);
      font: 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .35px;
      padding: 10px 17px 10px 0;
      text-align: start;
      width: 100%;
      margin-left: .8rem;

      &[data-focus-visible-added],
      &:hover {
        color: var(--color-gin);
        outline: none;
      }

      &::after {
        border-top-color: inherit;
        right: .8rem;
      }
    }

    &--inline {
      color: var(--dropdown-text-color);
      font: var(--dropdown-text-font);
      letter-spacing: .0071em;
      outline: 0;
      padding-right: 17px;
      transition: color .2s;

      &[data-focus-visible-added],
      &:hover {
        color: var(--dropdown-text-color-hover);

        &::after {
          border-top-color: inherit;
        }
      }
    }

    &--border-input {
      display: flex;
      width: 100%;
      padding: 8px 6px 8px 12px;
      justify-content: space-between;
      align-items: center;
      color: var(--border-dropdown-text-color);
      font: 500 12px var(--font-gerstner);
      line-height: 16px;
      border-radius: 4px;
      border: 1px solid var(--color-cod-gray-400);
      background: var(--color-cod-gray-100);
      transition: background-color .2s, color .2s;

      &__additional-label-text {
        color: var(--additional-label-text-color);
        font: 500 12px var(--font-gerstner);
        transition: color .2s;
        margin-left: 2px;
      }

      &[data-focus-visible-added],
      &:hover {
        --additional-label-text-color: var(--color-gin);
        --chevron-color: var(--color-gin);
        --border-dropdown-text-color: var(--color-gin);
        background-color: var(--color-cod-gray-300);
      }

      &:active:not(&--active) {
        --chevron-color: var(--color-gin);
        --additional-label-text-color: var(--color-gin);
        --border-dropdown-text-color: var(--color-gin);
      }

      &--open {
        --additional-label-text-color: var(--color-gin);
        --border-dropdown-text-color: var(--color-gin);
      }

      &::after {
        content: none;
      }
    }

    &--input {
      appearance: none;
      background-color: transparent;
      border: 0;
      box-shadow: inset 0 -1px 0 var(--color-cod-gray-400);
      color: var(--color-gray-200);
      font: 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .35px;
      padding: 10px 17px 10px 0;
      text-align: start;
      transition: color .2s, box-shadow .2s, outline-color .2s;
      width: 100%;

      &[data-focus-visible-added],
      &:hover {
        box-shadow: inset 0 -1px 0 var(--color-gin);
        color: var(--color-gin);
        outline: none;
      }

      &::after {
        border-top-color: inherit;
      }
    }
  }

  &__options {
    display: none;
    padding-block-start: 5px;
    position: absolute;
    right: 0;
    top: 110%;
    z-index: 38;
    max-height: 440px;
    overflow-y: scroll;
    background-color: var(--color-cod-gray-300);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 8px 24px 0 rgba(var(--color-black-russian-rgb) / 75%);

    &--inverted {
      top: 0;
      transform: translateY(-100%);
      flex-direction: column-reverse;

      &--open {
        display: flex !important;
        overflow: none;
      }
    }

    &--open {
      display: block;
      z-index: 101;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__options::-webkit-scrollbar {
    display: none;
  }

  &__option {
    align-items: center;
    background-color: var(--color-cod-gray-300);
    color: var(--color-gray-200);
    cursor: pointer;
    display: flex;
    height: 40px;
    letter-spacing: .001em;
    min-width: 192px;
    padding-inline-start: 16px;
    position: relative;
    transition: color .25s;
    justify-content: space-between;
    padding-inline-end: 16px;

    &--active {
      color: var(--color-gin);
    }

    &--active-icon {
      svg {
        * {
          stroke: var(--color-gin);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__option-btn {
    color: inherit;
    font: 500 1.4rem var(--font-gerstner);

    /* make the entire card look like a link (and not just the actual link).
    When passing in more links as props, make sure they appear on top of this
    pseudo-element (applying position: relative or z-index on the extra links
    seems to be working fine).
    Taken from: https://css-tricks.com/nested-links/ */
    &::after {
      content: '';
      inset: 0;
      position: absolute;
    }

    &[data-focus-visible-added] {
      outline: none;

      &::after {
        border: 1px solid var(--color-dodger-blue);
      }
    }
  }

  &__text-input {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    background-color: transparent;
    border: none;
    height: 100%;
    width: 95%;
    outline-width: 0;
  }
}
