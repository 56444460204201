.my-playlists {
  &__grid {
    margin: calc(var(--left-padding)) 0;
    column-gap: var(--left-padding);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(0, 163px));
    row-gap: var(--left-padding);

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, minmax(0, 192px));
    }
  }

  &__shared-folder-container {
    display: flex;
    width: 100%;
    aspect-ratio: 1 / 1.12;
    flex-direction: column;
  }

  &__shared-folder {
    display: flex;
    flex: 1;
    background-color: var(--color-cod-gray-300);
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--top-padding);
  }

  &__list-item-folder {
    margin-bottom: var(--top-padding);
    position: relative;

    svg {
      height: auto;
      width: 100%;
    }
  }

  &__list-item-short-title {
    bottom: 38px;
    color: var(--color-white);
    font: 500 4.8rem/4.8rem var(--font-gerstner);
    left: 8px;
    position: absolute;

    @media (--from-tablet-landscape) {
      bottom: 40px;
      font-size: 6.4rem;
      line-height: 6.4rem;
    }
  }

  &__list-item-count {
    bottom: 11px;
    color: var(--color-white);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    left: 8px;
    letter-spacing: .4px;
    position: absolute;

    @media (--from-tablet-landscape) {
      bottom: 24px;
    }
  }

  &__list-item-title {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
  }

  &__list-item-sub-title {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }
}
