.minimize-button {
  &__container {
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    pointer-events: none;
    padding: 0 8px;

    @media (--from-tablet) {
      width: 100%;
      justify-content: flex-end;
    }

    @media (--from-tablet-landscape) {
      padding-right: 20px;
      top: -8px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 100px;
    background-color: transparent;
    transition: background-color .2s ease-out, color .2s ease-out;
    cursor: pointer;
    opacity: 1;
    color: var(--color-gray-200);
    pointer-events: all;
    position: absolute;
    top: 20px;

    path {
      transition: stroke .2s ease-out;
    }

    @media (--from-tablet) {
      padding: 8px 8px 8px 16px;
    }

    &--scrolled {
      background-color: var(--color-cod-gray);
    }

    &:hover {
      color: var(--color-gin);

      path {
        stroke: var(--color-gin);
      }
    }
  }

  &__button-label {
    font: 500 1.4rem/1.6rem var(--font-gerstner);
    letter-spacing: .1em;
    letter-spacing: .025rem;
    display: none;

    @media (--from-tablet) {
      display: block;
    }
  }

  &__button-icon {
    display: flex;
    align-items: center;
    rotate: 180deg;
  }
}
