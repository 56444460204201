.my-playlists {
  --list-width-from-tablet: 240px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px); /* fallback for browsers that do not support svh units */
  /* stylelint-disable-next-line unit-no-unknown */
  height: calc(100svh - 110px);
  margin-bottom: 10px;

  &:not(&--submenu) {
    height: calc(100vh - 60px);
    /* stylelint-disable-next-line unit-no-unknown */
    height: calc(100svh - 60px);
  }

  @media (--from-tablet) {
    width: min-content;
    height: auto;
    max-height: 70vh;
    margin-bottom: 0;
  }

  &__list {
    flex-grow: 1;
    overflow-y: auto;
    color-scheme: dark;

    @media (--from-tablet) {
      width: var(--list-width-from-tablet);
    }
  }
}

.my-playlists-item {
  --spacing: 8px;
  color: var(--color-gray-200);
  font: 500 1.4rem/2.4rem var(--font-gerstner);
  letter-spacing: .1px;
  width: 100%;
  background-color: var(--color-cod-gray-500);

  @media (--mobile) {
    padding: 10px 20px;
    background-color: #212121;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing);
    width: 100%;
    padding: 4px 0;
    transition: color .2s;
    color: inherit;
    font: inherit;

    @media (--from-tablet) {
      padding: var(--spacing);
    }

    &:not(.my-playlists-item__wrapper--disabled):hover {
      cursor: pointer;

      &:hover {
        @media (hover: hover) {
          color: var(--color-gin);
        }
      }
    }
  }

  &__label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    display: block;
    width: 40px;
    height: 40px;
    margin: calc(-1 * var(--spacing));
  }

  &__checkbox {
    margin-left: auto;
  }

  &--footer {
    border-top: 1px solid #2a2a2a;
  }

  &__highlight {
    background: rgb(217 217 217 / 30%);
  }

  &--no-results,
  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100px;
    flex-grow: 1;
  }

  &--no-results {
    @media (--from-tablet) {
      width: var(--list-width-from-tablet);
    }
  }

  &--loading {
    flex-direction: column;
  }
}

.search {
  &__input {
    background-color: #2a2a2a !important;

    &::placeholder {
      color: #919191;
      opacity: 1;
    }
  }
}

@keyframes loading-indicator {
  from {
    background-position: 0 50%;
  }

  to {
    background-position: -170% 50%;
  }
}

.loading-wrapper {
  display: inline-block;
  color: transparent;
  border-radius: 3px;
  animation: 2s loading-indicator infinite;
  line-height: 1.1;
  background:
    linear-gradient(
      to right,
      var(--color-mine-shaft) 33%,
      var(--color-cod-gray-500) 50%,
      var(--color-mine-shaft) 83%
    ) center
    center/300% 100%;

  &--block {
    display: block;
  }
}
