.switcher-tabs {
  &__artist {
    column-gap: 24px;
    display: flex;
    margin-bottom: -1px;
    border-bottom: 1px solid var(--color-cod-gray-400);

    &__tab {
      position: relative;
      border-bottom: 1px solid transparent;
      color: var(--color-gray-200);
      font: 400 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .0333em;
      padding: 15px 0 17px;
      transition: border .25s, color .25s;
      display: flex;
      align-items: center;

      @media (hover: hover) {
        &:not(&--active):hover {
          color: var(--color-gin);
        }
      }

      &--active {
        border-color: var(--color-main-dynamic);
        color: var(--color-main-dynamic);
      }
    }
  }

  &__dynamic {
    display: flex;
    gap: var(--half-padding);
    bottom: 0;

    &__tab {
      position: relative;
      border-bottom: 2px solid transparent;
      color: var(--color-gray-200);
      font: 400 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .0333em;
      transition: border .25s, color .25s;
      display: flex;
      align-items: center;
      padding: var(--left-padding);

      @media (hover: hover) {
        &:not(&--active):hover {
          color: var(--color-gin);
        }
      }
    }
  }

  &__left-icon {
    position: absolute;
    left: -1.5rem;
  }
}
