.search-albums-carousel {
  margin: 0 0 25px;

  &__loading {
    display: flex;
    margin-top: 16px;
    overflow: hidden;

    &-item {
      height: 204px;
      min-width: 136px;
      width: 136px;

      @media (--from-tablet-landscape) {
        height: 268px;
        min-width: 192px;
        width: 192px;
      }
    }
  }

  &__no-results-section {
    display: grid;
    grid-template-columns: 1fr;
    margin: 16px;
    row-gap: 16px;

    @media (--from-tablet-landscape) {
      margin: 32px 16px 32px 0;
    }
  }
}
