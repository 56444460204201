.button {
  display: inline-block;
  font: 500 1.6rem/2.4rem var(--font-gerstner);
  letter-spacing: .0035em;

  &--solid {
    background-color: var(--color-gin);
    color: var(--color-cod-gray);
    padding: 8px 12px;
    text-align: center;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    transition: background-color .2s, opacity .2s;

    &[disabled] {
      opacity: .5;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-pumice);
      }
    }

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-dodger-blue);
    }
  }

  &--plain-link {
    color: var(--color-main-dynamic);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    &[data-focus-visible-added] {
      outline: none;
      text-decoration: underline;
    }
  }

  &--outline {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    background-color: transparent;
    border: 1px solid var(--color-gin);
    color: var(--color-gin);
    outline: none;
    padding: 8px 12px;
    text-align: center;
    transition: color .2s, border .2s, opacity .2s;

    &[disabled] {
      opacity: .5;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        border-color: var(--color-pumice);
        color: var(--color-pumice);
      }
    }

    &[data-focus-visible-added] {
      border-color: var(--color-dodger-blue);
    }
  }

  /* TODO: add other buttons presets - outline, ghost, text link potentially */
}
