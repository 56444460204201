.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  height: 100vh;
  width: 100%;
  background-color: #000000a3;
  transition: opacity .35s ease-out;
  overflow: hidden;
  opacity: 0;

  &--enter {
    opacity: 0;
  }

  &--enter-active,
  &--enter-done {
    opacity: 1;
  }

  &--exit {
    opacity: 1;
  }

  &--exit-active,
  &--exit-done {
    opacity: 0;
  }
}
