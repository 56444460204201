.text-switch {
  align-items: center;
  background: var(--color-cod-gray-400);
  border-radius: 13px;
  display: inline-flex;
  height: 24px;
  overflow: hidden;
  position: relative;

  &__label {
    color: var(--color-gray-200);
    font: 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    padding: 3px 8px 5px;
    position: relative;
    transition: color .25s;
    z-index: 2;

    &--active {
      color: var(--color-gin);
    }
  }

  &__background {
    background: var(--color-tundora);
    border-radius: 18px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform .25s ease, width .25s;
    width: 0;
    z-index: 1;
  }
}
