.loading-spinner {
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;

  &__container {
    opacity: 0;
    transition: opacity linear .2s;
    transition-delay: .375s;

    &--visible {
      opacity: 1;
    }
  }
}

.loading-spinner div {
  width: 4.5%;
  height: 13%;
  background: var(--color-main-dynamic);
  position: absolute;
  left: 24.5%;
  top: 21.5%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(var(--color-black-russian) / 20%);
  animation: fade 1s linear infinite;
}

@keyframes fade {
  from { opacity: 1; }
  to { opacity: .25; }
}

.bar-1 {
  transform: rotate(0deg) translate(0, -130% !important);
  animation-delay: 0s !important;
  top: 3.5% !important;
}

.bar-2 {
  transform: rotate(30deg) translate(0, -130%) !important;
  animation-delay: -.9167s !important;
}

.bar-3 {
  transform: rotate(60deg) translate(0, -130%) !important;
  animation-delay: -.833s !important;
}

.bar-4 {
  transform: rotate(90deg) translate(0, -130%) !important;
  animation-delay: -.7497s !important;
}

.bar-5 {
  transform: rotate(120deg) translate(0, -130%) !important;
  animation-delay: -.667s !important;
}

.bar-6 {
  transform: rotate(150deg) translate(0, -130%) !important;
  animation-delay: -.5837s !important;
}

.bar-7 {
  transform: rotate(180deg) translate(0, -130%) !important;
  animation-delay: -.5s !important;
}

.bar-8 {
  transform: rotate(210deg) translate(0, -130%) !important;
  animation-delay: -.4167s !important;
}

.bar-9 {
  transform: rotate(240deg) translate(0, -130%) !important;
  animation-delay: -.333s !important;
}

.bar-10 {
  transform: rotate(270deg) translate(0, -130%) !important;
  animation-delay: -.2497s !important;
}

.bar-11 {
  transform: rotate(300deg) translate(0, -130%) !important;
  animation-delay: -.167s !important;
}

.bar-12 {
  transform: rotate(330deg) translate(0, -130%) !important;
  animation-delay: -.0833s !important;
}
