.top-menu {
  position: relative;
  align-items: center;
  display: flex;
  height: 56px;
  width: 100%;
  justify-content: flex-end;

  @media (--from-tablet) {
    height: 56px;
  }

  @media (--from-desktop) {
    height: 40px;
  }

  &__three-dots-button {
    position: relative;
    right: 16px;
    top: 18px;
    display: block;
    z-index: 10;

    @media (--from-tablet) {
      display: none;
    }

    path {
      transition: stroke .2s ease-out;
    }

    &:hover {
      path {
        stroke: var(--color-gin);
      }
    }
  }
}
