.account-page-layout {
  &__container {
    @media (--tablet) {
      background-color: var(--color-cod-gray);
      display: block;
      height: 100%;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 12px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 101;
    }

    @media (--from-tablet-landscape) {
      display: grid;
      grid-template-columns: 272px 1fr;
    }
  }

  &__nav-desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: grid;
      grid-row: 1;
      grid-row-gap: 16px;
      grid-template-columns: 1fr;
      padding: 40px 32px;
    }
  }

  &__nav-mobile {
    align-items: center;
    box-shadow: inset 0 -1px 0 #2a2a2a;
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
    padding: 16px;

    @media (--from-tablet-landscape) {
      display: none;
    }

    >h3 {
      color: var(--color-gin);
      text-align: center;
    }
  }

  &__nav-RNmobile {
    display: none;
  }

  &__content-container {
    padding: var(--top-padding) 0;
    margin-bottom: var(--mobile-header-height);

    @media (--from-tablet-landscape) {
      grid-row: 1 / 3;
      padding: var(--top-padding) calc(2 * var(--top-padding)) var(--top-padding) 0;
      margin-bottom: 0;
    }
  }

  &__desktop-section-title {
    color: var(--color-gin);

    @media (--tablet) {
      display: none;
    }
  }

  &__link {
    @media (--from-tablet-landscape) {
      align-items: center;
      color: var(--color-gray-200);
      display: grid;
      font: 500 1.4rem/2.4rem var(--font-gerstner);
      letter-spacing: .1px;
      padding-left: 32px;
      text-decoration: none;
      transition: color .2s, fill .2s;

      &--active {
        color: var(--color-gin);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);
        }
      }

      &--chevron {
        grid-column-gap: 8px;
        grid-template-columns: 24px 1fr;
        padding-left: 0;
      }
    }
  }

  &__back-to-home-link {
    color: var(--color-gray-200);

    >svg {
      height: 32px;
      width: 32px;
    }
  }

  &__right-button-container {
    height: 32px;
    width: 32px;
  }
}
