.artist-detail-music {
  &--track-tabs {
    column-gap: 24px;
    margin-bottom: -1px;
    grid-auto-flow: column;

    &__tab {
      border-bottom: 1px solid transparent;
      color: var(--color-gray-200);
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .0333em;
      padding: 15px 0 17px;
      transition: border .25s, color .25s;
      margin-right: 16px;

      @media (hover: hover) {
        &:not(&--active):not(&--disabled):hover {
          color: var(--color-gin);
        }
      }

      &--active {
        border-color: var(--color-main-dynamic);
        color: var(--color-main-dynamic);
      }

      &--disabled {
        border-color: transparent;
        color: var(--color-tundora);

        @media (hover: hover) {
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &--desktop {
    &--article-heading {
      @media (--from-tablet-landscape) {
        padding-block-end: 16px;
      }
    }
  }

  &--art-credit {
    color: var(--color-gray-200);
    font-size: 12px;
  }
}

.detail-mobile {
  &--title {
    margin: 16px;
  }

  &--no-result {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    color: var(--color-gin);
    display: flex;
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: -.0035em;
  }

  &--art-credit {
    color: var(--color-gray-200);
    font-size: 12px;
    padding-left: 14px;
  }

  &--tabs-container {
    column-gap: 24px;
    display: flex;
    margin-bottom: -1px;
    padding-left: var(--left-padding);
  }

  &--tab {
    border-bottom: 1px solid transparent;
    color: var(--color-gin);
    font: 500 18px var(--font-gerstner);
    line-height: 24px;
    letter-spacing: .0333em;
    padding: 15px 0 5px;
    transition: border .25s, color .25s;
    display: flex;

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }

    &--disabled {
      border-color: transparent;
      color: var(--color-tundora);

      @media (hover: hover) {
        &:hover {
          cursor: not-allowed;
          color: var(--color-tundora) !important;
        }
      }
    }

    > span {
      font: 500 .8rem/1rem var(--font-gerstner);
    }
  }

  &--list-container {
    padding: 12px;
    position: relative;
    bottom: 45px;
  }

  &--context-menu-btn {
    line-height: 0;

    > svg > path {
      color: var(--color-gin);
    }
  }
}
