.track-title {
  &__title,
  &__title-mobile {
    font-family: var(--font-gerstner);
    color: var(--color-gin);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: .0208em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title {
    display: none;

    @media (--from-tablet) {
      display: block;
    }
  }

  &__title-mobile {
    display: block;

    @media (--from-tablet) {
      display: none;
    }
  }
}
