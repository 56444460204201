.devices {
  &__header {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 40px;
  }

  &__header-text {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);

    @media (--from-tablet) {
      max-width: 48rem;
    }
  }

  &__remove-text {
    color: var(--color-burnt-sienna);
    font: 500 1.4rem var(--font-gerstner);
  }

  &__heading--desktop-only {
    display: none;

    @media (--from-tablet) {
      display: table-cell;
    }
  }

  &__text {
    color: var(--color-main-dynamic);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__title-container {
    display: flex;
    color: var(--color-gin);
    font: 500 1.8rem var(--font-gerstner);
  }

  &__title-counter {
    color: var(--color-gray-200);
    margin-left: var(--half-padding);
  }

  &__table {
    margin-top: 16px;
    width: 100%;

    &-row {
      --pencil: none;

      @media (hover: hover) {
        &:hover {
          --pencil: block;
        }
      }

      &-edit {
        display: var(--pencil);
      }
    }

    tr {
      text-align: center;
      display: flex;

      @media (--from-tablet) {
        border-bottom: 1px solid var(--color-cod-gray-400);
        text-align: left;
      }
    }

    th {
      color: var(--color-gray-200);
      font: 500 1rem/1.6rem var(--font-gerstner);
      letter-spacing: .75px;
      padding: 4px 0;
      text-transform: uppercase;
    }

    td:last-child,
    th:last-child {
      @media (--from-tablet) {
        text-align: right;
      }
    }

    td:first-child,
    th:first-child {
      text-align: left;
      width: 45%;

      @media (--from-tablet) {
        text-align: unset;
        width: 34%;
      }
    }

    td {
      color: var(--color-gin);
      font: 300 1.4rem/2rem var(--font-gerstner);
      letter-spacing: .15px;
      max-height: 66px;
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 66px;
      justify-content: flex-start;

      span {
        display: flex;
      }
    }

    td:last-child {
      justify-content: flex-end;
      flex: 1;
    }

    td:nth-child(2) {
      justify-content: flex-start;
      align-self: flex-start;
    }

    td:first-child {
      font: 500 1.4rem/2rem var(--font-gerstner);
      flex-direction: row;
    }
  }
}
