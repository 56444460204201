.switch {
  align-items: center;
  display: flex;
  flex: 1;

  &__label {
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    margin-right: 8px;
  }

  &__input {
    display: none;
  }

  &__slider {
    border: 1px solid var(--color-gray-200);
    border-radius: 20px;
    height: 20px;
    padding: 3px;
    position: relative;
    width: 32px;
    margin-left: auto;

    &::after {
      background: var(--color-gray-200);
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: transform .2s, color .2s;
      width: 14px;
    }

    @media (hover: hover) {
      &:hover {
        background: var(--color-gray-200);

        &::after {
          background: var(--color-gin);
        }
      }
    }
  }

  &__input:checked + &__slider {
    border-color: var(--color-gin);

    &::after {
      transform: translateX(11px);
      background: var(--color-gin);
    }
  }
}
