.payment-methods {
  &__form-wrapper {
    padding-top: 24px;
    padding-bottom: 32px;

    @media (--from-tablet-landscape) {
      padding-top: 64px;
    }
  }

  &__cancel-button-mobile {
    display: block;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__cancel-button-desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__no-subscription {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }

  &__no-subscription-text {
    color: var(--color-giy);
    font: 500 1.6rem / 2.4rem var(--font-gerstner);
  }
}
