.account-add-music {
  > h2 {
    @media (--tablet) {
      display: none;
    }
    color: var(--color-gin);
  }

  &__container {
    background: var(--color-cod-gray-300);
    box-shadow: inset 0 1px 0 var(--color-main-dynamic);
    margin: 32px 0;
  }

  &__container-header {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 16px;

    > svg {
      animation: var(--spin-animation) 2s infinite linear;
    }
  }

  &__container-title {
    color: var(--color-gin);
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .5px;
  }

  &__container-text {
    color: var(--color-gin);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    padding: 0 0 24px 32px;

    > a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__container-button {
    margin: 0 0 32px 32px;
  }

  &__container-resume {
    color: var(--color-gin);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    padding: 0 0 32px 32px;

    svg {
      margin-right: 8px;
    }

    strong {
      font-weight: 500;
    }
  }

  &__modal-content {
    background: var(--color-gin);
    max-width: 500px;
  }

  &__modal-form {
    margin: 24px;
  }

  &__modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__modal-title {
    color: var(--color-cod-gray);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__modal-close-icon {
    display: flex;

    > svg {
      height: 16px;
      width: 16px;

      > path {
        stroke: var(--color-cod-gray);
      }
    }
  }

  &__modal-body-title {
    color: var(--color-cod-gray);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 16px;
  }

  &__modal-body-text {
    color: var(--color-cod-gray);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }
}
