.quality-control {
  background-color: var(--color-cod-gray-400);
  height: 24px;
  width: 153px;
  border-radius: 13px;
  justify-content: space-between;
  display: flex;
  margin-inline-end: 8px;
  align-items: center;

  &__slider-container {
    height: 24px;
    width: 153px;
    position: absolute;
  }

  &__slider {
    border-radius: 13px;
    height: 100%;
    background-color: var(--color-main-dynamic);
    width: 74px;
    margin-left: 50%;
    transition: margin .2s linear;
    z-index: 2;

    &--active {
      margin-left: 0;
    }
  }

  &__text {
    color: var(--color-gray-200);
    font: 400 1.1rem var(--font-gerstner);
    z-index: 3;
    padding: 0 1rem;
    transition: color .2s linear;

    &--active {
      color: var(--color-black);
    }
  }
}

.quality-control-input {
  border: 1px dashed var(--color-dodger-blue);
  background-color: transparent;
  transition: border linear .2s;
  color: var(--color-gin);
  appearance: none;
  margin: .5rem 0;

  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(var(--top-padding) * 2);
    margin-left: -35px;
  }

  &__icon-wrapper {
    background-color: var(--color-cod-gray-500);
    height: 24px;
    width: 30px;
    border: 1px dashed var(--color-dodger-blue);
    display: flex;
    border-radius: 12.5px;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

  &--tags-groups-container {
    overflow-y: scroll;
    max-height: 450px;
  }

  &--tags-container {
    height: unset !important;
  }

  &__wrapper {
    display: flex;
  }

  &__icon {
    margin-left: -2.8rem;
    display: flex;
    transform: rotate(45deg);
  }

  &:focus {
    appearance: none;
    outline: none !important;
    border: 1px solid var(--color-dodger-blue);
  }

  &--changed {
    border: 1px solid var(--color-create);
  }

  &__title {
    font: 500 3.2rem/4rem var(--font-gerstner);
    letter-spacing: -.0035em;
  }

  &__artist {
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: -.0035em;
  }

  &__genre {
    font: 500 2rem var(--font-gerstner);
    letter-spacing: -.0035em;
  }

  &__bpm {
    font: 500 1.1rem var(--font-gerstner);
    color: var(--color-gray-200);
    letter-spacing: -.0035em;
    width: 60px;
  }

  &__key {
    font: 500 1.1rem var(--font-gerstner);
    color: var(--color-gray-200);
    letter-spacing: -.0035em;
    width: 60px;
  }

  &__tag {
    background-color: var(--color-cod-gray-500);
    border-radius: 12px;
    color: var(--color-gray-200);
    display: flex;
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    height: 24px;
    padding-left: 8px;
    padding-bottom: 1.5px;
    padding-right: 25px;
    white-space: nowrap;
    border: 1px dashed var(--color-dodger-blue);
    justify-content: center;
    align-self: center;
    align-items: center;

    @media (hover: hover) {
      &:not([disabled]):hover {
        background-color: var(--color-cod-gray-400);
        color: var(--color-gin);
      }

      &:not([disabled]):hover &__text {
        display: none;
      }
    }
  }

  &__description {
    max-width: 592px;
    display: flex;
    flex: 1;
    width: 100%;
    height: 110px;
    max-height: 200px;
    font: 500 1.1rem var(--font-gerstner);
    color: var(--color-gray-200);
    letter-spacing: -.0035em;
  }
}

.quality-control-form {
  overflow-y: scroll;
  max-height: 450px;
}
