/* duplicate style from our switch component, just small adjustments to match the designs */
.settings-switch {
  display: flex;

  &__input {
    opacity: 0;
    width: 0;
  }

  &__slider {
    border: 1px solid var(--color-gray-200);
    border-radius: 20px;
    height: 20px;
    padding: 3px;
    position: relative;
    width: 32px;

    &::after {
      background: var(--color-gray-200);
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: transform .2s, color .2s;
      width: 14px;
    }

    @media (hover: hover) {
      &:hover {
        background: var(--color-gray-200);

        &::after {
          background: var(--color-gin);
        }
      }
    }
  }

  &__input:checked + &__slider {
    border-color: var(--color-gin);

    &::after {
      background: var(--color-gin);
      transform: translateX(11px);
    }
  }
}
