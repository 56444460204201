.media-detail-banner {
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    'image actions'
    'text text';
  grid-template-columns: 136px 1fr;
  margin-bottom: 32px;

  @media (--from-tablet-landscape) {
    grid-gap: 32px;
    grid-template-areas:
      'image text'
      'image actions';
    grid-template-columns: 296px 1fr;
  }

  &__img-container {
    grid-area: image;
  }

  &__actions-container--start {
    align-items: center;
    align-self: end;
    display: flex;
    gap: var(--half-padding);
    grid-area: actions;
  }

  &__actions-container--end {
    align-items: center;
    align-self: flex-start;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    grid-area: actions;
  }

  &__text-container {
    grid-area: text;
  }
}
