.favorite-albums {
  margin: 16px 16px 32px;

  > h3 {
    color: var(--color-gin);
    margin-bottom: 16px;
  }

  &__artist {
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }
}
