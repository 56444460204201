.nav-my-playlist-list-item {
  align-items: center;
  color: var(--color-boulder);
  display: flex;
  font: 1.4rem var(--font-gerstner);
  letter-spacing: .0094em;
  padding: 12px 12px 12px 20px;
  text-decoration: none;
  transition: color .25s;
  width: 100%;
  height: 44px;
  pointer-events: all;
  z-index: 100;
  position: relative;

  &--container {
    background-color: var(--color-cod-gray-500);
  }

  &--active-hover {
    @media (hover: hover) {
      &:hover {
        color: var(--color-gin) !important;
      }
    }
  }

  &--no-overflow {
    overflow: hidden;
  }

  &__playlist-folder {
    padding-left: 36px;

    @media (--from-tablet-landscape) {
      padding-left: 80px;
    }
  }

  &--is-dragging--over {
    background-color: transparent !important;
    color: var(--color-gin) !important;

    svg {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }
  }

  svg {
    * {
      transition: fill .25s;
    }
  }

  @media (hover: hover) {
    &:hover {
      &:not(.nav-my-playlist-list-item--active):not(.nav-my-playlist-list-item--is-dragging):not(:active) {
        background-color: var(--color-cod-gray-500);
        color: var(--color-boulder);
      }

      .nav-my-playlist-list-item__add-collaborators-button {
        opacity: 1 !important;
      }
    }
  }

  &:active:not(&--active) {
    background-color: var(--color-cod-gray-300);
    color: var(--color-gin);

    svg {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }
  }

  &--indented {
    padding-left: 40px !important;

    @media (--from-tablet-landscape) {
      padding-left: 20px !important;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin) !important;
      }
    }
  }

  @media (--from-tablet-landscape) {
    height: var(--menu-item-height);
    padding: 12px 18px 12px 0;
  }

  &__add-collaborators-button {
    opacity: 1;
    transition: opacity .1s linear;

    @media (--from-tablet-landscape) {
      opacity: 0;
    }
  }

  &--active {
    background: var(--color-cod-gray-500);
    color: var(--color-gin);
  }

  > span {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__button {
    display: block;
    height: 24px;
    margin-inline-end: 12px;
    text-align: center;
    transition: background .25s;
    padding-left: calc(60px - 8px);

    @media (--from-tablet-landscape) {
      margin: 0;
    }
  }

  &__icon {
    flex-shrink: 0;
  }
}
