.lists {
  border-radius: 4px;
  background: #e6efe90a;
  height: 100%;

  @media (--tablet) {
    backdrop-filter: blur(20px);
  }

  &__header {
    width: 100%;
    position: relative;
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid #ffffff1a;
    overflow: hidden;

    /* add when bg fixed */

    /* &::after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      right: 0;
      top: 8px;
      background: linear-gradient(269deg, #242424 1.22%, #24242400 98.84%);

      @media (min-width: 700px) {
        display: none;
      }
    } */

    @media (--from-tablet) {
      padding: 12px 24px;
    }

    @media (--from-tablet-landscape) {
      padding: 12px 32px;
    }
  }

  &__header-scroll-container {
    --scroll-x: 0;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: flex-start;
    scrollbar-width: none;
    transform: translateX(var(--scroll-x));
    transition: transform .2s ease-out;
    padding-right: 200px;
    touch-action: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (--from-tablet-landscape) {
      gap: 40px;
    }
  }
}
