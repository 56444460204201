.track-list-filters {
  &__container {
    display: flex;
    justify-content: flex-start;
    gap: var(--half-padding);
    align-items: center;
  }

  &__max-width {
    max-width: 273px;
  }

  &__dropdown-container {
    opacity: 0;
    transition: all .2s ease-in-out;
    padding: 0;
    min-height: 0;
    max-height: 0;
    visibility: hidden;

    &--open {
      opacity: 1;
      padding: var(--top-padding) 0;
      min-height: 50px;
      max-height: unset;
      visibility: visible;
    }
  }

  &__chevron-icon {
    transform: rotate(-90deg);
  }

  &__tabs {
    display: flex;
    gap: 24px;
    height: 34px;
  }

  &__tab {
    --tab-text-color: var(--color-gray-200);
    padding: var(--half-padding);
    transition: border-bottom .2s;
    display: flex;
    border-bottom: solid 1px transparent;

    &--text,
    &--number {
      color: var(--tab-text-color);
      transition: color .2s;
    }

    &--text {
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      display: flex;
      align-items: center;
    }

    &--number {
      font: 500 1rem/1.2rem var(--font-gerstner);
      margin-left: .4rem;
      padding-bottom: .3rem;
    }

    &--active {
      --tab-text-color: var(--color-gin);
      border-bottom: solid 1px var(--color-gin) !important;

      &--dynamic {
        --tab-text-color: var(--color-main-dynamic) !important;
        border-bottom: solid 1px var(--color-main-dynamic) !important;

        @media (hover: hover) {
          &:hover {
            --tab-text-color: var(--color-gin) !important;
            border-bottom: solid 1px var(--color-gin) !important;
          }
        }
      }
    }

    &[disabled] {
      cursor: not-allowed !important;
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        --tab-text-color: var(--color-gin);
        border-bottom: solid 1px var(--color-gray-200);
      }
    }
  }
}
