@import '~react-datepicker/dist/react-datepicker.css';

/* There are some layout issues with react-datepicker when using rem units in your app (= adjusting
the default font-size of <html> tag (here: from 16px to 10px).
This is a mix of styles that improves the looks of a datepicker.
Taken from here and adjusted a bit:
https://github.com/Hacker0x01/react-datepicker/issues/624#issuecomment-261696513 */
.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  line-height: 21px;
  padding-top: .8em;
  background-color: var(--color-cod-gray);
}

.react-datepicker__month {
  margin: .4em 1em;
  background-color: var(--color-cod-gray);
}

.react-datepicker__month-container {
  background-color: var(--color-cod-gray);
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background-color: var(--color-cod-gray-500);
  appearance: none;
  border: 1px solid var(--color-gin);
  padding: .5rem;
  border-radius: 0;
  font: 1.4rem var(--font-gerstner);
}

.react-datepicker__day--selected {
  background-color: var(--color-main-dynamic);
}

.react-datepicker__day-name,
.react-datepicker__day {
  line-height: 1.9em;
  margin: .166em;
  width: 1.9em;
  color: var(--color-text-dynamic);
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__navigation {
  border: .45em solid transparent;
  line-height: 1.7em;
  top: 1em;
}

.react-datepicker,
.react-datepicker *,
.react-datepicker *::before,
.react-datepicker *::after {
  box-sizing: content-box;
  color: var(--color-gin);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--color-hover-dynamic);
}
