.option-card {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 24px;
  padding: 24px;
  width: 100%;

  @media (--from-tablet-landscape) {
    padding: 48px 32px;
  }

  &--light {
    background-color: var(--color-cod-gray-300);
  }

  &__heading-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__heading {
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    line-height: 24px;
    letter-spacing: .15px;
    text-align: left;
    color: var(--color-gin);
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__button-holder {
    width: 100%;
    align-self: end;
  }

  &__counter {
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    color: var(--color-gin);
    border-radius: 100%;
    background-color: var(--color-tundora);
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
  }

  &__list-item {
    display: flex;
    gap: 8px;

    &:not(:last-of-type) {
      padding-bottom: 12px;

      & > p::after {
        position: absolute;
        width: 100%;
        content: '';
        bottom: 0;
        right: 0;
        height: 1px;
        background-color: var(--color-cod-gray-400);
      }
    }
  }

  &__label {
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    line-height: 24px;
    color: var(--color-gray-200);
  }

  &__list-item &__label {
    position: relative;
    padding-bottom: 12px;
  }

  &__list-item-icon {
    &--negative {
      svg {
        path {
          stroke: var(--color-tundora);
        }
      }
    }
  }
}
