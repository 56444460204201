.genres {
  &__header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;
    gap: calc(var(--left-padding) * 2);
  }

  &__search {
    margin-inline-start: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--window-padding);
    width: 155px;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    text-decoration: none;
    transition: color .2s, text-decoration .2s;
    justify-self: flex-start;
    -webkit-column-break-inside: avoid;
    float: left;

    @media (--from-tablet-landscape) {
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      min-width: 236px;
    }

    &--genre {
      color: var(--color-gin);
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
          color: var(--color-gin);
        }
      }
    }

    &--genre-no-underline {
      color: var(--color-gin);
    }

    &--subgenre {
      color: var(--color-gray-200);
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
          color: var(--color-gin);
        }
      }
    }
  }

  &__grid {
    column-count: 2;
    column-gap: 10px;

    @media (--from-tablet-landscape) {
      column-count: 4;
      column-gap: 10px;
    }

    @media (--from-large-desktop) {
      column-count: 6;
      column-gap: 10px;
    }
  }
}
