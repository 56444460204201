.collaboration-modal {
  padding-top: var(--top-padding);

  &__row-title {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__row-subtitle {
    font: 300 1.2rem var(--font-gerstner);
    color: var(--color-gray-200);
  }

  &__section-title {
    padding-top: calc(var(--top-padding) * 2);
    padding-bottom: var(--top-padding);
    font: 500 1.8rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__remove-padding {
    padding-top: calc(var(--top-padding) * -2);
  }

  &__bottom-container {
    padding-top: calc(var(--top-padding) * 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__see-more-btn {
    padding-top: var(--top-padding);
    text-align: end;
  }

  &__collaborators-list {
    display: block;
    overflow-y: auto;
    max-height: 450px;
  }

  &__collaborators-list::-webkit-scrollbar {
    display: none;
  }
}

.collaboration-user-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  transition: background-color .2s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-cod-gray-500);
    }
  }

  &__left {
    display: flex;
    align-items: center;
    margin-left: var(--left-padding);
  }

  &__right {
    display: flex;
    align-items: center;
    margin-right: var(--left-padding);
    gap: var(--half-padding);
  }

  &__text-container {
    margin-left: var(--half-padding);
  }

  &__state-text {
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gray-200);
  }
}

.collaboration-users {
  --container-background-color: #121212;
  background-color: var(--container-background-color);
  display: flex;
  align-items: center;
  gap: var(--half-padding);
  transition: background-color .2s ease-in-out;
  padding: 2px;
  border-radius: 4px;
  padding-right: var(--left-padding);
  margin-right: 4px;

  @media (hover: hover) {
    &:hover {
      --container-background-color: var(--color-cod-gray-500);
    }
  }

  &__image-0 {
    z-index: 3;
  }

  &__image-1 {
    margin-left: calc(-1 * (var(--left-padding) / 1.5));
    z-index: 2;
  }

  &__image-2 {
    margin-left: calc(-1 * (var(--left-padding) / 1.5));
    z-index: 1;
  }

  &__images {
    display: flex;
  }
}

.collaboration-user-image {
  &__size {
    &--regular {
      height: 32px;
      width: 32px;
      border-radius: 100%;
    }

    &--small {
      height: 24px;
      width: 24px;
      border-radius: 100%;
    }
  }

  &__container {
    --hover-opacity: 0;
    --hover-visibility: hidden;
    display: flex;
    background-color: var(--color-tundora);
    transition: border .2s ease-in-out;
    border: 2px solid var(--container-background-color);
    align-items: center;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;

    >img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &--hover-container {
      transition: opacity .2s ease-in-out;
      opacity: var(--hover-opacity);
      visibility: var(--hover-visibility);
      background-color: var(--color-cod-gray-400);
      position: absolute;
      padding: 4px var(--half-padding);
      box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
      max-width: 140px;
      right: 120px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      z-index: 3;

      &--arrow {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -7.52px;
        right: -14.4px;
        border: solid 7.52px transparent;
        border-left-color: var(--color-cod-gray-400);
        z-index: 1;
      }
    }

    @media (hover: hover) {
      &:hover {
        --hover-opacity: 1;
        --hover-visibility: visible;
      }
    }
  }
}

.collaboration-invite {
  &__header {
    padding-bottom: var(--top-padding);
    font: 500 24px var(--font-gerstner);
    line-height: 32px;
  }

  &__subtitle {
    padding-bottom: var(--top-padding);
    font: 500 14px var(--font-gerstner);
    line-height: 20px;
    letter-spacing: .25px;
  }

  &__btn-container {
    position: relative;
    display: flex;
  }

  &__accept-btn {
    margin-right: 16px;

    > button {
      background-color: var(--color-cod-gray);
      font: 500 16px var(--font-gerstner);
      color: var(--color-gin);
      text-align: center;
      padding: 8px 12px;
      gap: 8px;

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-boulder);
        }
      }
    }
  }

  &__decline-btn {
    > button {
      font: 500 16px var(--font-gerstner);
      background-color: var(--color-gin);
      color: var(--color-cod-gray);
      text-align: center;
      padding: 8px 12px;
      gap: 8px;
      border: 1px solid var(--color-cod-gray);

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-boulder);
        }
      }
    }
  }
}
