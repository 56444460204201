.page-not-found {
  &__body {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - var(--header-height));
    justify-content: center;
  }

  &__title {
    color: var(--color-gin);
    font: 500 3.2rem/4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 16px;
  }

  &__description {
    color: var(--color-gin);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 32px;
    max-width: 390px;
  }
}
