.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 48px 0;
  width: 100%;
  background-color: var(--color-cod-gray-500);

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: var(--font-gerstner);
    text-align: center;
    color: var(--color-gray-200);
  }
}
