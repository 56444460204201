.card-description-create {
  &__description {
    &--label {
      color: var(--color-gray-200);
      font: 1.2rem/1.6rem var(--font-gerstner);
    }

    &--link {
      color: var(--color-gray-200) !important;
      font: 1.2rem/1.6rem var(--font-gerstner);
      transition: color .2s;

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin) !important;
        }
      }
    }
  }

  &__details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;
    display: flex;
    align-items: center;
    gap: 4px;

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__count-container {
    display: flex;
    align-items: center;
    gap: 6px;

    &--count-label {
      color: var(--color-gray-200);
      transition: color .2s;
    }

    &--hover {
      color: var(--color-gin);
    }
  }

  &__genre-container {
    overflow-x: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    height: 16px;

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
      margin-right: 2px;
    }
  }

  &__genre-label {
    color: var(--color-gray-200) !important;
    font: 1rem/1.6rem var(--font-gerstner);
    transition: color .2s;
    display: inline-block;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin) !important;
      }
    }
  }
}
