.tags-filter {
  &__tags-title {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &__search {
    margin-bottom: 16px;

    &-results {
      >div:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &__switch {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
