.remixes-media-detail {
  &__title {
    color: var(--color-gin);
    font: 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 8px;
  }

  &__loading {
    margin-top: 16px;

    &-item {
      height: 59px;
      min-width: 100%;
      padding: 0;
      width: 100%;

      @media (--from-tablet-landscape) {
        height: 59px;
        min-width: 100%;
        width: 100%;
      }
    }
  }
}
