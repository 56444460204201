.trending {
  &__loading {
    margin-top: 16px;

    &-item {
      height: 56px;
      min-width: 100%;
      padding: 0;
      width: 100%;

      @media (--from-tablet-landscape) {
        height: 56px;
        min-width: 100%;
        width: 100%;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 16px;
  }

  &__title {
    color: var(--color-alabaster);
    display: flex;
    margin-right: auto;

    > svg > path {
      stroke: var(--color-main-dynamic);
    }

    @media (--from-tablet-landscape) {
      > svg {
        display: none;
      }
    }
  }

  &__genre {
    align-items: center;
    color: var(--color-main-dynamic);
    display: flex;
    font: 1.2rem/2.4rem var(--font-gerstner);
    letter-spacing: .0333em;
    margin-left: auto;
    position: relative;
    text-align: center;
    text-transform: capitalize;

    @media (--from-tablet-landscape) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &::after {
      background-image: url("data:image/svg+xml;utf8,<svg width='9' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M.738.488h7.524L4.5 4.25.738.488Z' fill='%23F73E00'/></svg>");
      background-size: 9px 5px;
      content: '';
      height: 5px;
      margin-left: 8px;
      width: 9px;
    }
  }

  &__list {
    > *:last-child > div {
      margin-bottom: 0;
    }

    @media (--from-tablet-landscape) {
      column-gap: 64px;
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      > *:nth-child(-n+6) {
        grid-column: 1;
      }

      > *:nth-child(n+6) {
        grid-column: 2;
      }
    }

    /* show only first 5 elements on mobile */
    @media (--mobile) {
      > *:nth-child(n+6) {
        display: none;
      }
    }
  }

  &__list-item {
    background: var(--color-cod-gray-100);
    cursor: pointer;
    display: block;
    margin-bottom: 16px;
    text-decoration: none;

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-dodger-blue);
    }
  }

  @media (hover: hover) {
    &__list-item:hover &__list-item-wrapper {
      background: var(--color-cod-gray-400);
    }
  }

  &__list-item-wrapper {
    align-items: center;
    background: var(--color-cod-gray-200);
    display: flex;
    height: 56px;
    overflow: hidden;
    transition: background-color .2s;

    .trending__list-item--rising & {
      border-right: 1px solid var(--color-fruit-salad);
    }

    .trending__list-item--dropping & {
      border-right: 1px solid var(--color-burnt-sienna);
    }
  }

  &__cover {
    flex: 0 0 56px;
    height: 56px;
    margin-right: 8px;
    width: 56px;
    position: relative;
  }

  &__cover-play-overlay {
    align-items: center;
    background-color: rgba(var(--color-black-russian-rgb) / 50%);
    display: flex;
    position: absolute;
    inset: 0;
    justify-content: center;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;
    opacity: 0;

    > svg {
      width: 50%;
      background-color: var(--color-black);
      height: 50%;
      border-radius: 50px;

      > path {
        fill: var(--color-gin);
      }
    }
  }

  @media (hover: hover) {
    &__cover:hover > &__cover-play-overlay {
      visibility: visible;
      opacity: 1;
    }

    &__cover:hover > &__cover-animation {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__details {
    flex: 1 auto;
    margin-right: auto;
    max-width: calc(100% - 96px);
  }

  &__details-album,
  &__details-media {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--active {
      color: var(--color-main-dynamic);
    }
  }

  &__underline-link {
    color: var(--color-gin);

    &--active {
      color: var(----color-main-dynamic) !important;
    }
  }

  &__details-album {
    font-weight: 500;
  }

  &__rank {
    align-items: center;
    color: var(--color-fruit-salad);
    display: flex;
    flex: 0 0 32px;
    flex-direction: column;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    height: 56px;
    justify-content: center;
    letter-spacing: .0071em;
    margin-left: auto;
    text-align: center;
    width: 32px;

    .trending__list-item--rising & {
      color: var(--color-fruit-salad);
    }

    .trending__list-item--dropping & {
      color: var(--color-burnt-sienna);
    }

    span {
      margin-bottom: 2px;
    }

    i {
      background-size: 8px 4px;
      height: 4px;
      width: 8px;

      .trending__list-item--rising & {
        background-image: url("data:image/svg+xml;utf8,<svg width='8' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 4h8L4 0 0 4Z' fill='%2349A147'/></svg>");
      }

      .trending__list-item--dropping & {
        background-image: url("data:image/svg+xml;utf8,<svg width='8' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 0H0l4 4 4-4Z' fill='%23EB5757'/></svg>");
      }
    }
  }
}
