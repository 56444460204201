.app-toast {
  /* overwrite style from react-toastify */
  --toastify-color-light: var(--color-cod-gray-500);
  --toastify-color-dark: var(--color-cod-gray-500);
  --toastify-color-info: var(--color-gray-200);
  --toastify-color-success: var(--color-fruit-salad);
  --toastify-color-warning: var(--color-yellow-warning);
  --toastify-color-error: var(--color-burnt-sienna);
  --toastify-color-transparent: transparent;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-background: var(--color-cod-gray-500);
  --toastify-font-family: var(--font-gerstner);
  --toastify-z-index: 9999;
  --toastify-toast-width: 100%;
  border-radius: 0 !important;
  bottom: 7rem !important;
  pointer-events: none;

  @media (--from-tablet) {
    --toastify-toast-width: 54rem;
    --toastify-toast-min-height: 6.4rem;
    --toastify-toast-max-height: 6.4rem;
    bottom: 0 !important;
  }

  :global(.player-is-open:not(.maxi-player-is-open)) & {
    padding-bottom: 7rem !important;

    @media (--from-tablet) {
      padding-bottom: 6.5rem !important;
    }

    @media (--from-desktop) {
      padding-bottom: 7.5rem !important;
    }

    @media (--from-large-desktop) {
      padding-bottom: 8rem !important;
    }
  }

  :global(.maxi-player-is-open) & {
    bottom: 0 !important;
    padding-bottom: .75rem !important;

    @media (--from-tablet) {
      padding-bottom: 1rem !important;
    }
  }

  :global(.lift-mobile-toast) & {
    bottom: 0;
    padding-bottom: 4rem !important;

    @media (--from-tablet) {
      padding-bottom: 6rem !important;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(100px);
    }
  }

  &--enter {
    animation: fade-in 200ms ease-out both;
  }

  &--exit {
    animation: fade-out 100ms ease-in-out both;
  }

  &__container {
    padding-left: 1rem;
    justify-self: center;
    box-shadow: 0 8px 24px rgba(18 18 18 75%) !important;
    border: 1px solid var(--color-cod-gray-400);
    background-color: var(--color-cod-gray-400);
    margin: .2rem 0;
    pointer-events: all;
  }

  &__body {
    color: var(--color-gin);
    font: 500 1.4rem var(--font-gerstner);
    justify-content: flex-start;
    text-align: start;
    margin: 0;
    white-space: pre-line;
  }
}
