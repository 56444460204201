.search-buttons {
  background-color: var(--color-cod-gray-100);
  margin: 0 calc(-1 * var(--left-padding)) calc(-1 * var(--left-padding));
  border-top: 1px solid var(--color-cod-gray-400);
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: var(--left-padding);
  padding: calc(var(--top-padding) / 2);
  position: sticky;
  bottom: calc(-1 * var(--left-padding));

  &__search {
    color: var(--color-cod-gray);
    font: 500 1.6rem var(--font-gerstner);
  }

  &__icon {
    >path {
      stroke: var(--color-cod-gray);
    }
  }

  &__search-container {
    display: flex;
    height: 4rem;
    width: 11.2rem;
    background-color: var(--color-gin);
    align-items: center;
    justify-content: center;
  }
}
