.ghost-element {
  background-color: var(--color-cod-gray-500);
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;

  @media (--tablet) {
    background-color: transparent;
  }

  &--padding {
    padding: 16px;

    @media (--tablet) {
      padding: 0;
      padding-top: 16px;
    }
  }

  &--row {
    flex-direction: row;
  }

  &__container {
    background-color: var(--color-cod-gray-400);
    height: 100%;
    width: 100%;
  }

  &__row-container {
    background-color: var(--color-cod-gray-400);
    height: 100%;
  }

  &__square-container {
    background-color: var(--color-mine-shaft) !important;
    display: flex;
    margin-right: 8px;

    &--round {
      border-radius: 50% !important;
    }
  }

  &__round-container {
    border-radius: 50% !important;
  }

  &__square {
    flex-shrink: 0;
    margin-right: 8px;
    width: 100%;
    height: 100%;
  }

  &__round {
    border-radius: 50%;
  }

  &__line-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--color-mine-shaft);
  }

  &__lines-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    gap: 4px;

    &__item {
      background-color: var(--color-cod-gray-400);
      width: 100%;
      height: 100%;
    }
  }

  &--maxi-player {
    background-color: transparent;
  }

  &--maxi-player &__container {
    background-color: #fff1 !important;
  }

  &--maxi-player &__row-container,
  &--maxi-player &__square-container {
    background-color: #fff1 !important;
  }

  &--maxi-player &__line-container {
    background-color: #fff1;
  }

  &--maxi-player &__lines-container__item {
    background-color: #fff1;
  }
}
